import React from 'react'
import {NavLink, withRouter} from 'react-router-dom'
import {injectIntl} from 'react-intl'
import parseQuery from '../utils/queryParser'

const Component = props => {
  const lang = props.lang || props.intl.locale
  let propsTo = props.to.split('/')
  if (propsTo[1] === lang) {
    propsTo = [...propsTo.slice(0, 1), ...propsTo.slice(2)]
  }
  let to = '/' + lang + propsTo.join('/').replace(/\/\//, '/')
  // Auth by URL GET Param (apiKey) (Hiventive - January 2020)
  const locationQuery = parseQuery(props.location.search);
  if ('apiKey' in locationQuery) {
    to += (to.split('?')[1] ? '&' : '?') + 'apiKey=' + locationQuery['apiKey'];
  }
  // --------------------------------------------------------

  return <NavLink
    className={props.className}
    onClick={props.onClick}
    to={to}
    activeClassName={props.activeClassName || "active"}
    exact={props.exact}
  >
    {props.children}
  </NavLink>
}

export default injectIntl(withRouter(Component))
