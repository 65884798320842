import React, { Component } from "react";
import { Switch, Route, withRouter } from "react-router-dom";

import { RequestsProvider } from "./Requests";
import Footer from "components/footer";
import Header from "components/Header";
import Home from "Home/index.js";
import Forms from "Forms/index.js";
import metaTag from "./utils/metaTag";
import { injectIntl } from "react-intl";

import ReactGA from "react-ga";
import privacyPolicy from "./Privacy/privacyPolicy";
import Advertise from "./Advertise/advertise";

// ReactGA.initialize('UA-166250426-1');
ReactGA.initialize("UA-166972834-1");

if (typeof window !== "undefined") {
  ReactGA.pageview(window.location.pathname + window.location.search);
}

class App extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { props } = this;

    metaTag.setTitle({
      primary: true,
      secondary: true,
    });
    return (
      <RequestsProvider>
        <Header />
        <Switch>
          <Route
            path={props.match.path + "/formulaire"}
            component={Forms}
          ></Route>
          <Route exact path={props.match.path} component={Home}></Route>
          <Route
            path={props.match.path + "/privacy"}
            component={privacyPolicy}
          ></Route>
          <Route
            path={props.match.path + "/advertise-with-us"}
            component={Advertise}
          ></Route>
        </Switch>
        <Footer />
      </RequestsProvider>
    );
  }
}

export default injectIntl(withRouter(App));
