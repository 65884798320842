import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Nav, NavItem, NavLink } from "shards-react";
import { Link } from "Elements";
import LanguageSelector from "components/LanguageSelector";
import { FormattedMessage, injectIntl } from "react-intl";

class Header extends Component {
  state = {
    showLogo: false,
    onHomePage: true,
    formsActive: false,
  };

  exitHomePage() {
    this.setState({ onHomePage: !this.state.onHomePage });
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    const { props } = this;

    const pathname = props.location.pathname.split("/").slice(2);

    return (
      <Nav pills className="nav col-12 text-center col-md row m-0 p-0">
        <NavItem className="navItem col-3 text-center col-md-auto p-0 ml-0 ml-md-1">
          <NavLink
            className={
              pathname.length === 0 ? "navLink p-0 navActive" : "navLink p-0"
            }
            //active={pathname.length === 0 ? true : false}
          >
            <Link className="linkTo" to="/">
              <p className="py-md-0 py-2">PCB Market</p>
            </Link>
          </NavLink>
        </NavItem>
        <NavItem className="navItem col-4 text-center col-md-auto p-0 ml-0 ml-md-1">
          <NavLink
            className={
              pathname.indexOf("formulaire") > -1
                ? "navLink p-0 navActive"
                : "navLink p-0"
            }
          >
            <Link className="linkTo" to="/formulaire">
              <p className="">
                <FormattedMessage
                  id="marketing.header.linktoform"
                  defaultMessage="Espace entreprise"
                />
              </p>
            </Link>
          </NavLink>
        </NavItem>
        <NavItem className="navItem col-5 text-center col-md-auto p-0 ml-0 ml-md-1">
          <NavLink
            className={
              pathname.indexOf("advertise-with-us") > -1
                ? "navLink p-0 navActive"
                : "navLink p-0"
            }
          >
            <Link className="linkTo" to="/advertise-with-us">
              <p className="">
                <FormattedMessage
                  id="marketing.header.linktoAdvertise"
                  defaultMessage="Votre pub sur ce site"
                />
              </p>
            </Link>
          </NavLink>
        </NavItem>
        <NavItem className="langSelect col-1 col-md-auto">
          <LanguageSelector />
        </NavItem>
      </Nav>
    );
  }
}

const mapStatToProps = (state) => {
  return {};
};

export default injectIntl(withRouter(connect(mapStatToProps)(Header)));

// import React, { Component } from "react";
// import { connect } from "react-redux";
// import { withRouter } from "react-router";

// import { Link } from "Elements";
// import LanguageSelector from "components/LanguageSelector";
// import { FormattedMessage, injectIntl } from "react-intl";

// class Header extends Component {
//   state = {
//     showLogo: false,
//     onHomePage: true,
//   };

//   exitHomePage() {
//     this.setState({ onHomePage: !this.state.onHomePage });
//   }
//   componentDidMount() {
//     window.addEventListener("scroll", this.handleScroll);
//   }
//   componentWillUnmount() {
//     window.removeEventListener("scroll", this.handleScroll);
//   }

//   render() {
//     return (
//       <div className="header col-12 row m-0 p-0">
//         <div className="header-item homeButton offset-md-1">
//           <Link to="/">
//             <div>.</div>
//           </Link>
//         </div>
//         <div className="formButton col-lg-2 col-sm-4 col-4 p-0 px-md-2">
//           <Link to="/formulaire">
//             <p>
//               <FormattedMessage
//                 id="marketing.header.linktoform"
//                 defaultMessage="Espace entreprise"
//               />
//             </p>
//           </Link>
//         </div>
//         <div className="formButton col-lg-2 col-sm-4 col-5 p-0 px-md-2">
//           <Link to="/advertise-with-us">
//             <p className="px-xs-3">
//               <FormattedMessage
//                 id="marketing.header.linktoAdvertise"
//                 defaultMessage="Votre pub sur ce site"
//               />
//             </p>
//           </Link>
//         </div>
//         <div className=" col-1 col-md-1 ml-auto p-0">
//           <LanguageSelector />
//         </div>
//       </div>
//     );
//   }
// }

// const mapStatToProps = (state) => {
//   return {};
// };

// export default injectIntl(withRouter(connect(mapStatToProps)(Header)));
