import React from "react";
import { Link, withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import languages from "languages";
import parseQuery from "../utils/queryParser";

const Component = props => {
  const to = props.to || "";
  let query = to.split("?")[1];

  // Pass API Key from actual URL to all links (Hiventive - January 2020)
  const locationQuery = parseQuery(props.location.search);

  // Append apiKey var if apiKey is available on current URL
  if ("apiKey" in locationQuery) {
    query = (query ? "&" : "") + "apiKey=" + locationQuery["apiKey"];
  }
  // -------------------------------------------------------------------

  const hash = to.split("#")[1];

  let pathname = to
    .split("#")[0]
    .split("?")[0]
    .split("/")
    .filter(s => s !== "");
  pathname = languages.indexOf(pathname[0]) >= 0 ? pathname.slice(1) : pathname;
  pathname = pathname.length ? pathname.join("/") : "";
  const nTo = (
    "/" +
    props.intl.locale +
    (pathname.length ? "/" + pathname : "") +
    (hash ? "#" + hash : "") +
    (query ? "?" + query : "")
  ).replace(/\/\//, "/");
  return (
    <Link {...props} to={nTo}>
      {props.children}
    </Link>
  );
};

export default injectIntl(withRouter(Component));
