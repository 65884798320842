
var pageTitle = {
  primary: '',
  secondary: '',
}
var description =''
var keyWords=''
var element=''
//title

const get = () => {
  return [pageTitle, description, keyWords]
}

const stringify = () => {
  return [[pageTitle.primary, pageTitle.secondary].filter(v => v).join(' | '), pageTitle.brand].filter(v => v).join(' | ')
}

const setTitle = (t) => {
  pageTitle = Object.assign({}, pageTitle, t)
  if (typeof window !== 'undefined') {
    window.document.title = stringify()
  }
}


const getDescription = () => {
  return description
}
 const setDescription = (t) => {
    description= t 
    //console.log('description: ', description)
    // if (typeof window !== 'undefined') {
    //   var element = window.document.querySelector("[name='description']");
    //   //element.setAttribute('content', description);
    //   
    // }
 }


 const getKeyWords = () => {
  return keyWords
}
 const setKeywords = (t) => {
   keyWords = t
  // console.log('keyWords: ',keyWords)
  // if (typeof window !== 'undefined') {
  //   var element = window.document.querySelector("[name='keywords']");
  //   //element.setAttribute('content', keyWords);
  //   //console.log('keywords: ', keyWords)
  // }
 }

export default {
  setTitle,
  get,
  stringify,
  setDescription,
  getDescription,
  description,
  element,
  setKeywords,
  keyWords,
  getKeyWords,
  // getDescription,
  // stringifyDescription,
  //metaDescription
}


// var pageTitle = typeof window !== 'undefined'
//   ? window.document.title
//   : ''
//
// const set = title => {
//   title = title.join
//     ? title.join(', ')
//     : title
//   if (typeof window !== 'undefined') {
//     window.document.title = title
//   }else{
//     pageTitle = title
//   }
// }
//
// const get = () => {
//   if (typeof window !== 'undefined') {
//     return window.document.title
//   }else{
//     return pageTitle
//   }
// }
//
// export const replace = title => {
//   set(title)
// }
//
// export const append = title => {
//   const actual = get()
//   set([actual, title])
// }
//
// export const prepend = title => {
//   const actual = get()
//   set([title, actual])
// }
//
// export const replaceFirst = title => {
//   const actual = get().split(', ')
//   set([title, actual.slice(1)])
// }
//
// export default {
//   replace,
//   append,
//   prepend,
//   replaceFirst,
//   set,
//   get
// }
