import React from "react";
import "styles/index.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import metaTag from "../utils/metaTag";
import { FormattedMessage, defineMessages, injectIntl } from "react-intl";

class PrivacyPolicy extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  state = {};

  render() {
    const { props } = this;
    const message = defineMessages({
      title: {
        id: "marketing.privacy.titre",
        defaultMessage: "Politique de condifentitalité",
      },
      description: {
        id: "marketing.privacy.description",
        defaultMessage:
          "PCB-Market compare des fabricants européens de circuit imprimé. Chiffrez l'assemblage des cartes électroniques, PCBA, montage CMS et traversant",
      },
      keyWords: {
        id: "marketing.privacy.keywords",
        defaultMessage:
          "pcb, circuit imprimé, fabrication pcb, espace entreprise, formulaire, pcb électronique, pcb design",
      },
    });

    metaTag.setTitle({
      primary: "PCB-Market",
      secondary: props.intl.formatMessage(message.title),
    });

    metaTag.setDescription(props.intl.formatMessage(message.description));

    metaTag.setKeywords(props.intl.formatMessage(message.keyWords));

    return (
      <div className="privacyPage col-12 m-0 p-0 pb-5">
        <div className="px-4 px-md-0 col-12 offset-0 col-md-6 offset-md-3">
          <h1 className="text-center mb-5 py-5">
            <FormattedMessage
              id="marketing.policy.h1"
              defaultMessage="Politique de confidentialité"
            />
          </h1>

          <h2 className="privacyTitle">
            <FormattedMessage
              id="marketing.policy.t1"
              defaultMessage="1. Parties et Objet"
            />
          </h2>
          <p>
            <FormattedMessage
              id="marketing.policy.p"
              defaultMessage="PCB-Market établit la présente Politique de confidentialité dont l'objet est d'informer en toute transparence les Utilisateurs du site internet hébergé à l'adresse suivante: pcb-market.com,(ci-après le 'Site'), de la manière dont les données personnelles sont récoltées et traitées par PCB-Market"
            />
          </p>

          <p>
            <FormattedMessage
              id="marketing.policy.p2"
              defaultMessage="Le terme 'Utilisateur' fait référence à tout utilisateur, soit toute personne physique ou morale, qui visite ou interagit d'une quelconque manière avec le Site."
            />
          </p>
          <p>
            <FormattedMessage
              id="marketing.policy.p3"
              defaultMessage="A ce titre, PCB-Market détermine tous les moyens et finalités techniques, juridiques et organisationnelles du traitement des données personnelles des Utilisateurs. PCB-Market s'engage à cet effet à prendre toutes les mesures nécessaires pour garantir un traitement des données personnelles conforme à la loi du 30 juillet 2018, relative à la protection des personnes physiques à l'égard des traitements des données à caractère personnel (ci-après, 'la Loi') et au Règlement européen du 26 avril 2016 relatif à la protection des données des personnes physiques à l'égard du traitement des données à caractère personnel et à la libre circulation de ces données (ci-après le 'Règlement')."
            />
          </p>
          <p>
            <FormattedMessage
              id="marketing.policy.p4"
              defaultMessage="PCB-Market est libre de choisir toute personne physique ou morale qui traite les données personnelles des utilisateurs à sa demande et pour son compte (ci-après le 'Sous-traitant'). Le cas échéant, PCB-Market s'engage à sélectionner un Sous-traitant offrant des garanties suffisantes quant aux mesures de sécurité techniques et organisationnelles du traitement des données personnelles, au regard de la Loi et du Règlement."
            />
          </p>

          <h2 className="privacyTitle">
            <FormattedMessage
              id="marketing.policy.t2"
              defaultMessage="2. Traitement des données personnelles"
            />
          </h2>
          <p>
            <FormattedMessage
              id="marketing.policy.p5"
              defaultMessage="L'utilisation du Site par les Utilisateurs pourra entraîner la communication de données personnelles. Le traitement de ces données par PCB-Market, en sa qualité de Responsable du traitement, ou par les prestataires agissant au nom et pour le compte de PCB-Market, sera conforme à la Loi et au Règlement."
            />
          </p>
          <p>
            <FormattedMessage
              id="marketing.policy.p6"
              defaultMessage="Les données à caractère personnel seront traitées par PCB-Market, conformément aux finalités citées plus bas, via:"
            />
          </p>
          <p>
            <FormattedMessage
              id="marketing.policy.p7"
              defaultMessage="Formulaire d'inscription au référencement"
            />
          </p>

          <h2 className="privacyTitle">
            <FormattedMessage
              id="marketing.policy.t3"
              defaultMessage="3. Consentement"
            />
          </h2>
          <p>
            <FormattedMessage
              id="marketing.policy.p8"
              defaultMessage="En accédant au Site et en l'utilisant, l'Utilisateur déclare avoir pris connaissance et marqué son accord de façon libre, spécifique, éclairée et univoque au traitement des données à caractère personnel la concernant. Cet accord porte sur le contenu de la présente Politique de confidentialité."
            />
          </p>
          <p>
            <FormattedMessage
              id="marketing.policy.p9"
              defaultMessage="Le consentement est donné par l'acte positif par lequel l'Utilisateur a coché la case proposant la Politique de confidentialité en lien hypertexte. Ce consentement est une condition indispensable pour effectuer certaines opérations sur le Site ou pour permettre à l'Utilisateur d'entrer en relation contractuelle avec PCB-Market. Tout contrat liant PCB-Market et un Utilisateur portant sur les services et biens proposés sur le Site est subordonné à l'acceptation de la Politique de confidentialité par l'Utilisateur."
            />
          </p>
          <p>
            <FormattedMessage
              id="marketing.policy.p10"
              defaultMessage="L'Utilisateur consent à ce que le Responsable du Traitement traite et recueille, conformément aux modalités et principes compris dans la présente Politique de confidentialité, ses données à caractère personnel qu'il communique sur le Site ou à l'occasion des services proposés par PCB-Market, pour les finalités indiquées plus haut."
            />
          </p>
          <p>
            <FormattedMessage
              id="marketing.policy.p11"
              defaultMessage="L'Utilisateur a le droit de retirer son consentement à tout moment. Le retrait du consentement ne compromet pas la licéité du traitement fondé sur le consentement préalablement donné."
            />
          </p>

          <h2 className="privacyTitle">
            <FormattedMessage
              id="marketing.policy.t4"
              defaultMessage="4. Durée de conservation des données personnelles des Utilisateurs"
            />
          </h2>
          <p>
            <FormattedMessage
              id="marketing.policy.p12"
              defaultMessage="Conformément à l'article 13 §2 du Règlement et de la Loi, le Responsable du Traitement ne conserve les données à caractère personnel que pendant le temps raisonnablement nécessaire pour permettre l'accomplissement des finalités pour lesquelles elles sont traitées."
            />
          </p>
          <p>
            <FormattedMessage
              id="marketing.policy.p13"
              defaultMessage="Cette durée est dans tous les cas inférieure à: 7jours"
            />
          </p>

          <h2 className="privacyTitle">
            <FormattedMessage
              id="marketing.policy.t5"
              defaultMessage="5. Destinataires des données et divulgation à des tiers"
            />
          </h2>
          <p>
            <FormattedMessage
              id="marketing.policy.p14"
              defaultMessage="Les données personnelles peuvent être transmises aux préposés, collaborateurs, sous-traitants ou fournisseurs de PCB-Market qui offrent des garanties de sécurité des données adéquates, et qui collaborent avec le PCB-Market dans le cadre de la commercialisation de produits ou de la fourniture de services. Ils agissent sous l'autorité directe de PCB-Market, et sont notamment responsables de recueillir, de traiter ou de sous-traiter ces données."
            />
          </p>
          <p>
            <FormattedMessage
              id="marketing.policy.p15"
              defaultMessage="Dans tous les cas, les destinataires des données et ceux à qui ces données ont été divulguées respectent le contenu de la présente Politique de confidentialité. PCB-Market assure qu'ils traiteront ces données aux seules finalités prévues, de façon discrète et sécurisée."
            />
          </p>
          <p>
            <FormattedMessage
              id="marketing.policy.p16"
              defaultMessage="Dans l'hypothèse où les données seraient divulguées à des tiers à des fins de marketing direct ou de prospection, l'Utilisateur en sera préalablement informé pour qu'il exprime son consentement à l'utilisation de ces données personnels."
            />
          </p>

          <h2 className="privacyTitle">
            <FormattedMessage
              id="marketing.policy.t6"
              defaultMessage="6. Droits des Utilisateurs"
            />
          </h2>
          <p>
            <FormattedMessage
              id="marketing.policy.p17"
              defaultMessage="A tout moment, l'Utilisateur peut exercer ses droits en envoyant un message par courrier électronique à l'adresse suivante: pcbmarket.contact@gmail.com, ou une lettre par voie postale adressée, en joignant une copie de sa carte d'identité à l'adresse suivante: Bromsgrove, Royaume-Uni"
            />
          </p>
          <p>
            <FormattedMessage
              id="marketing.policy.p18"
              defaultMessage="Droit d'accès"
            />
          </p>
          <p>
            <FormattedMessage
              id="marketing.policy.p19"
              defaultMessage="Conformément à l'article 15 du Règlement, PCB-Market garantit le droit d'accès à l'Utilisateur à ses données personnelles. L'Utilisateur a le droit d'obtenir l'accès auxdites données à caractère personnel ainsi que les informations suivantes:
          "
            />
          </p>
          <ul>
            <li>
              <FormattedMessage
                id="marketing.policy.p20"
                defaultMessage="les finalités du traitement."
              />
            </li>
            <li>
              <FormattedMessage
                id="marketing.policy.p21"
                defaultMessage="les catégories de données à caractère personnel concernées."
              />
            </li>
            <li>
              <FormattedMessage
                id="marketing.policy.p22"
                defaultMessage="les destinataires ou catégories de destinataires auxquels les données à caractère personnel ont été ou seront communiquées, en particulier les destinataires qui sont établis dans des pays tiers ou les organisations internationales."
              />
            </li>
            <li>
              <FormattedMessage
                id="marketing.policy.p23"
                defaultMessage="lorsque cela est possible, la durée de conservation des données à caractère personnel envisagée ou, lorsque ce n'est pas possible, les critères utilisés pour déterminer cette durée."
              />
            </li>
            <li>
              <FormattedMessage
                id="marketing.policy.p24"
                defaultMessage="l'existence d'une prise de décision automatisée, y compris un profilage, visée à l'article 22, paragraphes 1 et 4 du Règlement, et, au moins en pareils cas, des informations utiles concernant la logique sous-jacente, ainsi que l'importance et les conséquences prévues de ce traitement pour la personne concernée"
              />
            </li>
          </ul>
          <p>
            <FormattedMessage
              id="marketing.policy.p25"
              defaultMessage="Le Responsable du Traitement peut exiger le paiement de frais raisonnables basés sur les coûts administratifs pour toute copie supplémentaire demandée par l'Utilisateur."
            />
          </p>
          <p>
            <FormattedMessage
              id="marketing.policy.p26"
              defaultMessage="Lorsque l'Utilisateur introduit cette demande par voie électronique (par le biais de l'adresse électronique par exemple), les informations sont fournies sous une forme électronique d'usage courant, à moins que l'Utilisateur ne demande qu'il en soit autrement."
            />
          </p>
          <p>
            <FormattedMessage
              id="marketing.policy.p27"
              defaultMessage="La copie de ses données sera communiquée à l'Utilisateur au plus tard dans le mois après la réception de la demande."
            />
          </p>
          <p>
            <FormattedMessage
              id="marketing.policy.p28"
              defaultMessage="Droit de rectification"
            />
          </p>
          <p>
            <FormattedMessage
              id="marketing.policy.p29"
              defaultMessage="PCB-Market garantit le droit de rectification et d'effacement des données personnelles à l'utilisateur."
            />
          </p>
          <p>
            <FormattedMessage
              id="marketing.policy.p30"
              defaultMessage="Conformément à l'article 16 du Règlement, les données incorrectes, inexactes ou non-pertinentes p être corrigées ou effacées à tout moment. L'Utilisateur procède d'abord lui-même aux modific nécessaires depuis son compte utilisateur/autre, sauf si celles-ci ne peuvent être effectuées de m autonome, dans quel cas la demande peut en être faite auprès de PCB-Market."
            />
          </p>
          <p>
            <FormattedMessage
              id="marketing.policy.p31"
              defaultMessage="Conformément à l'article 19 du Règlement, le Responsable du traitement notifie à chaque destin auquel les données à caractère personnel ont été communiquées toute rectification des données à car personnel, à moins qu'une telle communication se révèle impossible ou exige des efforts disproporti Le responsable du traitement fournit à la personne concernée des informations sur ces destinatai celle-ci en fait la demande."
            />
          </p>
          <p>
            <FormattedMessage
              id="marketing.policy.p32"
              defaultMessage="Droit à l'effacement"
            />
          </p>
          <p>
            <FormattedMessage
              id="marketing.policy.p33"
              defaultMessage="L'Utilisateur a le droit d'obtenir l'effacement de ses données personnelles dans les meilleurs dans les hypothèses énumérées à l'article 17 du Règlement."
            />
          </p>
          <p>
            <FormattedMessage
              id="marketing.policy.p34"
              defaultMessage="Lorsque le Responsable de Traitement a rendu publiques les données à caractère personnel et qu'il est tenu de les effacer en vertu du paragraphe précédent, le Responsable du traitement, compte tenu des technologies disponibles et des coûts de mise en œuvre, prend des mesures raisonnables, y compris d'ordre technique, pour informer les autres responsables du traitement qui traitent ces données à caractère personnel que la personne concernée a demandé l'effacement par ces responsables du traitement de tout lien vers ces données à caractère personnel, ou de toute copie ou reproduction de celles-ci."
            />
          </p>
          <p>
            <FormattedMessage
              id="marketing.policy.p35"
              defaultMessage="Les deux paragraphes précédents ne s'appliquent pas dans la mesure où ce traitement est nécessaire:"
            />
          </p>
          <ul>
            <li>
              <FormattedMessage
                id="marketing.policy.p36"
                defaultMessage="à l'exercice du droit à la liberté d'expression et d'information."
              />
            </li>
            <li>
              <FormattedMessage
                id="marketing.policy.p37"
                defaultMessage="pour respecter une obligation légale qui requiert le traitement prévue par le droit de l'Union ou par le droit de l'État membre auquel le responsable du traitement est soumis, ou pour exécuter une mission d'intérêt public ou relevant de l'exercice de l'autorité publique dont est investi le responsable du traitement."
              />
            </li>
            <li>
              <FormattedMessage
                id="marketing.policy.p38"
                defaultMessage="à la constatation, à l'exercice ou à la défense de droits en justice."
              />
            </li>
          </ul>
          <p>
            <FormattedMessage
              id="marketing.policy.p39"
              defaultMessage="Conformément à l'article 19 du Règlement, le Responsable du traitement notifie à chaque destinataire auquel les données à caractère personnel ont été communiquées tout effacement de données à caractère personnel ou toute limitation du traitement effectué, à moins qu'une telle communication se révèle impossible ou exige des efforts disproportionnés. Le responsable du traitement fournit à la personne concernée des informations sur ces destinataires si celle-ci en fait la demande."
            />
          </p>
          <p>
            <FormattedMessage
              id="marketing.policy.p40"
              defaultMessage="Droit de limitation du traitement"
            />
          </p>
          <p>
            <FormattedMessage
              id="marketing.policy.p41"
              defaultMessage="L'Utilisateur a le droit d'obtenir la limitation du traitement de ses données personnelles dans les hypothèses énumérées à l'article 19 du Règlement."
            />
          </p>
          <p>
            <FormattedMessage
              id="marketing.policy.p42"
              defaultMessage="Conformément à l'article 19 du Règlement, le Responsable du traitement notifie à chaque destinataire auquel les données à caractère personnel ont été communiquées toute limitation du traitement effectué, à moins qu'une telle communication se révèle impossible ou exige des efforts disproportionnés. Le responsable du traitement fournit à la personne concernée des informations sur ces destinataires si celle-ci en fait la demande."
            />
          </p>
          <p>
            <FormattedMessage
              id="marketing.policy.p43"
              defaultMessage="Droit de portabilité des données"
            />
          </p>
          <p>
            <FormattedMessage
              id="marketing.policy.p44"
              defaultMessage="Conformément à l'article 20 du Règlement, les Utilisateurs ont le droit de recevoir de PCB-Market les données à caractère personnel les concernant dans un format structuré, couramment utilisé et lisible par machine. Les Utilisateurs ont le droit de transmettre ces données à un autre responsable du traitement sans que PCB-Market y fasse obstacle dans les cas prévus par le Règlement."
            />
          </p>
          <p>
            <FormattedMessage
              id="marketing.policy.p45"
              defaultMessage="Lorsque l'Utilisateur exerce son droit à la portabilité des données en application du paragraphe précédent, elle a le droit d'obtenir que les données à caractère personnel soient transmises directement d'un responsable du traitement à un autre, lorsque cela est techniquement possible."
            />
          </p>
          <p>
            <FormattedMessage
              id="marketing.policy.p46"
              defaultMessage="L'exercice du droit de portabilité des données s'entend sans préjudice du droit à l'effacement. Ce droit ne s'applique pas au traitement nécessaire à l'exécution d'une mission d'intérêt public ou relevant de l'exercice de l'autorité publique dont est investi le responsable du traitement."
            />
          </p>
          <p>
            <FormattedMessage
              id="marketing.policy.p47"
              defaultMessage="Le droit de portabilité des données ne porte pas atteinte aux droits et libertés de tiers."
            />
          </p>
          <p>
            <FormattedMessage
              id="marketing.policy.p48"
              defaultMessage="Droit d'opposition et prise de décision individuelle automatisée"
            />
          </p>
          <p>
            <FormattedMessage
              id="marketing.policy.p49"
              defaultMessage="L'Utilisateur dispose à tout moment du droit de s'opposer au traitement de ses données personnelles en raison de sa situation particulière, y compris l'automatisation des données effectuée par PCB-Market. Conformément à l'article 21 du Règlement, PCB-Market ne traitera plus les données à caractère personnel, à moins qu'il existe des motifs légitimes et impérieux pour le traitement qui prévalent sur les intérêts et les droits et libertés de l'Utilisateur, ou pour la constatation, l'exercice ou la défense de droits en justice."
            />
          </p>
          <p>
            <FormattedMessage
              id="marketing.policy.p50"
              defaultMessage="Lorsque les données à caractère personnel sont traitées à des fins de prospection, l'Utilisateur a le droit de s'opposer à tout moment au traitement des données à caractère personnel la concernant à de telles fins de prospection, y compris au profilage dans la mesure où il est lié à une telle prospection."
            />
          </p>
          <p>
            <FormattedMessage
              id="marketing.policy.p51"
              defaultMessage="Lorsque la personne concernée s'oppose au traitement à des fins de prospection, les données à caractère personnel ne sont plus traitées à ces fins."
            />
          </p>

          <p>
            <FormattedMessage
              id="marketing.policy.p52"
              defaultMessage="L'Utilisateur dispose du droit d'introduire une plainte concernant le traitement de ses données personnelles par PCB-Market auprès de l'Autorité de Protection des Données, compétente pour le territoire belge. De plus amples informations peuvent être trouvées sur le site internet: https://www.autoriteprotectiondonnees.be."
            />
          </p>
          <p>
            <FormattedMessage
              id="marketing.policy.p53"
              defaultMessage="L'introduction d'une plainte se fait aux adresses suivantes:"
            />
          </p>
          <p>
            <FormattedMessage
              id="marketing.policy.p54"
              defaultMessage="Autorité de Protection des Données,"
            />
          </p>
          <p>
            <FormattedMessage
              id="marketing.policy.p55"
              defaultMessage="Rue de la Presse 35, 1000 Bruxelles,"
            />
          </p>
          <p>Phone. + 32 2 274 48 00</p>
          <p>Fax. + 32 2 274 48 35</p>
          <p>E-mail: contact@apd-gba.be</p>
          <p>
            <FormattedMessage
              id="marketing.policy.p56"
              defaultMessage="L'Utilisateur peut également introduire une plainte auprès du tribunal de première instance de son domicile."
            />
          </p>

          <h2 className="privacyTitle">
            <FormattedMessage
              id="marketing.policy.t7"
              defaultMessage="7. Limitation de responsabilité du Responsable de traitement"
            />
          </h2>
          <p>
            <FormattedMessage
              id="marketing.policy.p57"
              defaultMessage="Le site Internet peut contenir des liens vers d'autres sites internet détenus par des tiers non liés à PCB-Market. Le contenu de ces sites et le respect de ceux-ci au regard de la Loi et du Règlement ne relèvent pas de la responsabilité de PCB-Market."
            />
          </p>
          <p>
            <FormattedMessage
              id="marketing.policy.p58"
              defaultMessage="Le titulaire de l'autorité parentale doit donner son consentement exprès pour que le mineur de moins de 16 ans puisse divulguer des informations ou données personnelles sur le site Internet. PCB-Market conseille vivement aux personnes exerçant l'autorité parentale sur des mineurs de promouvoir une utilisation responsable et sécurisée d'Internet. Le Responsable de traitement ne peut être tenu responsable pour avoir collecté et traité des informations et données personnelles de mineurs de moins de 16 ans dont le consentement n'est pas effectivement couvert par celui de leurs parents légaux ou pour des données incorrectes -notamment concernant l'âge- introduites par des mineurs. En aucun cas, des données personnelles ne seront traitées par le Responsable de traitement si l'Utilisateur précise qu'il est âgé de moins de 16 ans."
            />
          </p>
          <p>
            <FormattedMessage
              id="marketing.policy.p59"
              defaultMessage="PCB-Market n'est pas responsable de la perte, de la corruption ou du vol de données personnelles causés notamment par la présence de virus ou suite à des attaques informatiques."
            />
          </p>

          <h2 className="privacyTitle">
            <FormattedMessage
              id="marketing.policy.t8"
              defaultMessage="8. Droit applicable et juridiction compétente"
            />
          </h2>
          <p>
            <FormattedMessage
              id="marketing.policy.p60"
              defaultMessage="La présente Politique de confidentialité est exclusivement régie par le droit belge. Tout litige sera porté devant les tribunaux de l'arrondissement judiciaire du siège social de PCB-Market."
            />
          </p>

          <h2 className="privacyTitle">
            <FormattedMessage
              id="marketing.policy.t9"
              defaultMessage="9. Contact"
            />
          </h2>
          <p>
            <FormattedMessage
              id="marketing.policy.p61"
              defaultMessage="Pour toute question ou réclamation relative à la présente Politique de confidentialité, l'Utilisateur peut contacter le Responsable du traitement via l'adresse suivante: contact@pcb-market.com"
            />
          </p>
        </div>
      </div>
    );
  }
}

export default injectIntl(PrivacyPolicy);
