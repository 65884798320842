import React from "react";
import { Link } from "Elements";
import { FormattedMessage } from "react-intl";

const Footer = (props) => {
  return (
    <div className="col-12 row footer p-0 m-0">
      <div className="col-12 py-4 col-sm-12 col-md-3 col-lg-3">
        <h3 className="col-12 p-0">
          PCB-Market
          <div className="bordure"></div>
        </h3>
        <a href="/">
          <img
            className="logo"
            src="../../images/img/logo.png"
            alt="logo of pcb-market"
          ></img>
        </a>
      </div>
      <div className="col-12 py-4 col-sm-6 col-md-4 col-lg-3">
        <h4>
          <FormattedMessage
            id="marketing.footer.aPropos"
            defaultMessage="À propos"
          />
          <div className="bordure"></div>
        </h4>
        <li>
          <Link to="/privacy">
            <FormattedMessage
              id="marketing.footer.privacy"
              defaultMessage="Politique de confidentialité"
            />
          </Link>
        </li>
        <li>
          <a href="https://policies.google.com/technologies/partner-sites">
            <FormattedMessage
              id="marketing.footer.utilisation"
              defaultMessage="Conditions d'utilisation"
            />
          </a>
        </li>
      </div>
      <div className="col-12 py-4 col-sm-6 col-md-5 col-lg-3">
        <h4>
          <FormattedMessage
            id="marketing.footer.entreprise"
            defaultMessage="Pour les entreprises"
          />
          <div className="bordure"></div>
        </h4>

        <li>
          <Link to="/formulaire">
            <FormattedMessage
              id="marketing.footer.form"
              defaultMessage="Espace entreprise"
            />
          </Link>
        </li>
        <li>
          <Link to="/advertise-with-us">
            <FormattedMessage
              id="marketing.footer.advertise"
              defaultMessage="Votre pub sur ce site"
            />
          </Link>
        </li>
      </div>
    </div>
  );
};

export default Footer;
