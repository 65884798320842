import React from "react";
import { connect } from "react-redux";

import { Switch, Route } from "react-router-dom";
import { IntlProvider, addLocaleData } from "react-intl";
import fr from "react-intl/locale-data/fr";
import en from "react-intl/locale-data/en";
import it from "react-intl/locale-data/it";
import de from "react-intl/locale-data/de";
import es from "react-intl/locale-data/es";
import nl from "react-intl/locale-data/nl";

import { InitIntl } from "utils/intl";
// messages
// import messages from 'locales/messages.json'

import App from "App";
// import authActions from 'Auth/actions'
import actions from "./actions";
import languages from "languages";

// console.error = e => {}

addLocaleData([...fr, ...en, ...it, ...de, ...es, ...nl]);

// TODO: nginx redirect /fr to /
const IntlRouter = () => {
  return (
    <Switch>
      <Route path="/:lang" component={Intl} />
      {/* <Redirect exact from="/" to="/fr" /> */}
      <Route path="/" component={Intl} />
    </Switch>
  );
};

class IntlComponent extends React.Component {
  state = {};
  getLang(lang = this.props.match.params.lang) {
    if (languages.indexOf(lang) >= 0) {
      return lang;
    }
    return false;
  }
  componentDidMount() {
    this.update();
  }
  componentDidUpdate(oProps) {
    this.update(oProps);
    // const lang = props.language
    // const oLang = oProps.language
    // console.log('Intl didUpdate', lang, oLang)
    // if (lang && lang !== oLang) {
    //   props.setFrontLang(lang)
    // }
  }
  update(oProps = {}) {
    const { props } = this;
    if (props.locale !== props.langParam) {
      if (!props.langParam) {
        props.history.replace(
          "/" + props.locale + props.location.pathname + props.location.search
        );
      } else {
        props.history.replace(
          "/" +
            props.locale +
            props.location.pathname.slice(3) +
            props.location.search
        );
      }
      // if (!props.language) {
      //   props.getMessages(props.locale)
      // }
    }

    if ( 
      props.locale !== oProps.locale ||
      props.domains.join() !== oProps.domains.join()
    ) {
      if (props.locale !== oProps.locale && oProps.locale) {
        // console.log('intl update', props.locale, oProps.locale)
        props.emptyLoadedDomains();
      }
      props.getMessages(props.locale);
    }



    // if (props.language !== props.langParam) {
    //   console.log('intl update', props.language, props.langParam)
    //   // TODO async load of messages depending on frontLang
    //   return this.props.setLanguage(props.language || props.langParam)
    //   // if (!props.langParam) {
    //   //   props.history.replace('/'+props.language+props.location.pathname + props.location.search)
    //   // }else{
    //   //   props.history.replace('/'+props.language+props.location.pathname.slice(3) + props.location.search)
    //   // }
    // }
    // if (!Object.keys(props.messages).length && ['pending', 'error'].indexOf(props.getMessagesRequest.status) < 0) {
    //   props.getMessages(props.language)
    // }
  }
  render() {
    const { props } = this;
     //console.log('Intl render', props, props.locale, props.language)
    return (
      <IntlProvider
        locale={props.locale}
        defaultLocale={languages[0]}
        messages={props.messages}
        onError={e => {}}
      >
        <InitIntl>
          <App />
        </InitIntl>
      </IntlProvider>
    );
  }
}

const mapStateToProps = (state, props) => {
  // Router lang
  const langParam = !props.match.params.lang
    ? languages[0]
    : languages.indexOf(props.match.params.lang) >= 0
    ? props.match.params.lang.toLowerCase()
    : false;
  // LocaleStorage Lang
  const language = state.intl.language  
   ? state.intl.language.toLowerCase() 
    : false;  
  // User profile lang
  const userLanguage = false;
  // Browser lang
  let navLang = false;
  if (typeof window !== "undefined") {
    navLang = (
      window.navigator.language || window.navigator.userLanguage
    ).slice(0, 2);
  }
  // Output Lang
  let locale = (
    language || 
    userLanguage ||
    langParam ||
    navLang ||
    languages[0]
  ).toLowerCase();
  if (languages.indexOf(locale) < 0) {
    locale = langParam || languages[0];
  }
  return {
    langParam: langParam,
    language, 
    locale: locale.toLowerCase(),
    messages: state.intl.messages || {},  
    domains: state.intl.domains 
  };
};

const mapDispatchToProps = dispatch => {
  return actions;
};

const Intl = connect(
  mapStateToProps,
  mapDispatchToProps
)(IntlComponent);

export default IntlRouter;
