import React from "react";
import { FormattedMessage, injectIntl, defineMessages } from "react-intl";
import "styles/index.scss";
import "../Serveur/serveur.js";
import metaTag from "../utils/metaTag";
import Company from "../Company/company";

class Home extends React.Component {
  state = {
    filter: true,
    filterMobile: false,
    showPcba: false,
    pageScrolled: false,
    epaisseurCouche: 0,
    filtreType: ["pcb"],
    filtreNombreCouche: 1,
    filtreNombreCouche2: 1,
    filtrePrix: 0,
    filtreDelay: 30,
    filtreLangue: "All",
    removeFiltreLangue: false,
    removeNombreCouche: false,
    removeMateriaux: false,
    removeFinitions: false,
    removeRigide: false,
    removeVia: false,
    removeCouleurs: false,
    filtreMateriaux: "All",
    filtreFinitions: "All",
    selectedMateriaux: "All",
    selectedFinitions: "All",
    filtreRigide: "All",
    filtreVia: "All",
    filtreCouleurs: "Toute",
    Emsproto: true,
    Safepcb: true,
    Wurth: true,
  };

  showFilter() {
    if (window.scrollY < 949) {
      if (this.state.filter === false) {
        this.setState({ pageScrolled: true, filter: !this.state.filter });
      } else {
        this.setState({ pageScrolled: false, filter: !this.state.filter });
      }
    } else {
      this.setState({ filter: !this.state.filter });
    }
  }
  showPcba(value) {
    this.setState({ showPcba: value });
  }

  nbQuantityMore() {
    var { filtreNombreCouche } = this.state;
    this.setState({ filtreNombreCouche: filtreNombreCouche + 1 });
  }
  nbQuantityLess() {
    var { filtreNombreCouche } = this.state;
    if (filtreNombreCouche > 1) {
      this.setState({ filtreNombreCouche: filtreNombreCouche - 1 });
    }
  }

  nbPriceMore() {
    var { filtrePrix } = this.state;
    this.setState({ filtrePrix: filtrePrix + 1 });
  }
  nbPriceLess() {
    var { filtrePrix } = this.state;
    if (filtrePrix >= 1) {
      this.setState({ filtrePrix: filtrePrix - 1 });
    }
  }
  componentDidMount() {
    if (window.innerWidth < 576) {
      this.setState({ filterMobile: true, filter: false });
    } else {
      this.setState({ filterMobile: false, filter: true });
    }
    window.addEventListener("scroll", (event) => {
      let filter = this.state.filter;
      if (
        (window.innerWidth < 576) & (window.scrollY > 950) ||
        filter === true
      ) {
        this.setState({ pageScrolled: true });
      } else {
        this.setState({ pageScrolled: false });
      }
    });

    // console.log(window.innerWidth, 'filter: ', this.state.filter, 'filterMobile: ', this.state.filterMobile)
    // console.log('test')
    // var textes = document.querySelectorAll(".homePage h1 .background");
    // for (var i =0; i< textes.length; i++){
    //     console.log('texte: ', textes[i].innerHTML);
    // }
  }

  changeFiltreLangue(e) {
    this.setState({ filtreLangue: e });
  }

  render() {
    const props = this.props;
    const { state } = this;
    const {
      pageScrolled,
      filter,
      filterMobile,
      selectedMateriaux,
      selectedFinitions,
      selectedRigide,
      selectedVia,
      selectedCouleurs,
      filtreNombreCouche2,
      showPcba,
      filtreDelay,
      filtreLangue,
      filtreNombreCouche,
      filtreMateriaux,
      filtreFinitions,
      filtreCouleurs,
      filtreVia,
      filtreRigide,
      removeFiltreLangue,
      removeMateriaux,
      removeFinitions,
      removeCouleurs,
      removeVia,
      removeRigide,
    } = state;

    const message = defineMessages({
      description: {
        id: "marketing.home.description",
        defaultMessage:
          "PCB-Market compare des fabricants européens de circuit imprimé. Différentes technologie de PCB :  multicouches, FR4, impédances contrôlées, Via laser, Via fill, Polyimide, Roger, HAL, ENIG , Flex-Rigid. Chiffrez l'assemblage des cartes électroniques, PCBA, montage CMS et traversant",
      },
      keyWords: {
        id: "marketing.home.keywords",
        defaultMessage:
          "pcb, circuit imprimé, fabrication pcb, pcb électronique, pcb design, pcb prototype, online pcb design, fabricant pcb",
      },
      title: {
        id: "marketing.home.title",
        defaultMessage:
          "Comparateur de prix pour votre PCB et assemblage de PCB",
      },
    });

    metaTag.setTitle({
      primary: "PCB-Market",
      secondary: props.intl.formatMessage(message.title),
    });

    metaTag.setDescription(props.intl.formatMessage(message.description));

    metaTag.setKeywords(props.intl.formatMessage(message.keyWords));

    return (
      <div className="allContent">
        <div className="homePage col-12 p-0 m-0">
          <h1>
            PCB-Market<br></br>
            <FormattedMessage
              id="marketing.subtitle"
              defaultMessage="Comparateur "
            />
            <span className="background"> PCB/PCBA</span>
          </h1>
          <p>
            <FormattedMessage
              id="marketing.hp.description1"
              defaultMessage="Comparez les meilleurs fournisseurs de"
            />
            <span className="souligne">PCB/PCBA</span>
            <FormattedMessage
              id="marketing.hp.description2"
              defaultMessage="d’Europe en quelques clics et faite des économies sur tous vos devis"
            />
          </p>
          <div className="mt-4 col-12 p-0 m-0 row">
            <a
              href="#pageListing"
              className="p-2 p-sm-0 col-6 offset-sm-1 col-sm-4 col-xl-2 offset-xl-3"
            >
              <button
                className="col-12 p-0 m-0"
                onClick={() => this.showPcba(false)}
              >
                <img
                  className="col-12 p-0 m-0"
                  src="../images/img/pcb.png"
                  alt="pcb"
                ></img>
                <p className="col-12 p-0 m-0 text-center texte">PCB</p>
              </button>
            </a>
            <a
              href="#pageListing"
              className="p-2 p-sm-0 col-6 offset-sm-2 col-sm-4 col-3 col-xl-2 offset-xl-2"
            >
              <button
                className="col-12 p-0 m-0"
                onClick={() => this.showPcba(true)}
              >
                <img
                  className="col-12 p-0 m-0"
                  src="../images/img/pcba.png"
                  alt="pcba"
                ></img>
                <p className="col-12 p-0 m-0 text-center texte">PCBA</p>
              </button>
            </a>
          </div>

          <div className="arrow1">
            <img src="../images/img/arrow/fleche1.png" alt="fleche"></img>
          </div>
        </div>

        <div className="pageListing" id="pageListing">
          <div className="filter col-lg p-lg-0 ">
            {filterMobile === true ? (
              <button
                className="buttonFilter"
                onClick={() => this.showFilter()}
              >
                {filter === false ? (
                  <div
                    className={
                      pageScrolled === true
                        ? " burgerMenu pageScrolled"
                        : "burgerMenu"
                    }
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                ) : (
                  <div
                    className={
                      pageScrolled === true
                        ? "position-fixed closeMenu"
                        : "closeMenu"
                    }
                  >
                    <span></span>
                    <span></span>
                  </div>
                )}
              </button>
            ) : null}

            {filter === true ? (
              <div className="filterContener d-mobile-block ">
                {" "}
                {/*+(filter? 'd-none' : '')*/}
                <ul className="list-group list-group-horizontal">
                  <li
                    className={"list-group-item" + (showPcba ? "" : " active")}
                    onClick={() => this.showPcba(false)}
                  >
                    PCB
                  </li>
                  <li
                    className={"list-group-item" + (showPcba ? " active" : "")}
                    onClick={() => this.showPcba(true)}
                  >
                    PCBA
                  </li>
                </ul>
                <div className="filterContent col-12 col-md-6 col-lg-12 p-0 px-2 px-mobile-0 pr-md-2 pr-lg-0 row">
                  <p className="col-6 col-lg-12 col-xl-6 p-0 m-0">
                    <FormattedMessage
                      id="marketing.filter.lang"
                      defaultMessage="Langue"
                    />
                  </p>
                  <select
                    className="col-6 col-lg-12 col-xl-6 p-0 m-0"
                    name="langue"
                    onChange={(e) =>
                      this.changeFiltreLangue(e.target.value) &
                      this.setState({ removeFiltreLangue: false })
                    }
                  >
                    <FormattedMessage
                      id="marketing.filter.lang.all"
                      defaultMessage="Toute"
                    >
                      {(t) => (
                        <option
                          value="All"
                          selected={removeFiltreLangue === true ? true : null}
                        >
                          {t}
                        </option>
                      )}
                    </FormattedMessage>
                    <FormattedMessage
                      id="marketing.filter.lang.fr"
                      defaultMessage="Français"
                    >
                      {(t) => <option value="FR">{t}</option>}
                    </FormattedMessage>
                    <FormattedMessage
                      id="marketing.filter.lang.en"
                      defaultMessage="Anglais"
                    >
                      {(t) => <option value="EN">{t}</option>}
                    </FormattedMessage>
                    <FormattedMessage
                      id="marketing.filter.lang.es"
                      defaultMessage="Espagnol"
                    >
                      {(t) => <option value="ES">{t}</option>}
                    </FormattedMessage>
                    <FormattedMessage
                      id="marketing.filter.lang.pt"
                      defaultMessage="Portugais"
                    >
                      {(t) => <option value="PT">{t}</option>}
                    </FormattedMessage>
                    <FormattedMessage
                      id="marketing.filter.lang.du"
                      defaultMessage="Allemand"
                    >
                      {(t) => <option value="DE">{t}</option>}
                    </FormattedMessage>
                    <FormattedMessage
                      id="marketing.filter.lang.nl"
                      defaultMessage="Néerlandais"
                    >
                      {(t) => <option value="NL">{t}</option>}
                    </FormattedMessage>
                    <FormattedMessage
                      id="marketing.filter.lang.it"
                      defaultMessage="Italien"
                    >
                      {(t) => <option value="IT">{t}</option>}
                    </FormattedMessage>
                    <FormattedMessage
                      id="marketing.filter.lang.cz"
                      defaultMessage="Rép. Tchèque"
                    >
                      {(t) => <option value="CZ">{t}</option>}
                    </FormattedMessage>
                    <FormattedMessage
                      id="marketing.filter.lang.za"
                      defaultMessage="Afrique du sud"
                    >
                      {(t) => <option value="ZA">{t}</option>}
                    </FormattedMessage>
                  </select>
                </div>
                {showPcba ? (
                  <div className="">
                    <div className="filterContent col-12 m-0 p-0 px-2 px-mobile-0 pt-3 pt-mobile-1 row">
                      <div className="cursorBloc col-12 p-0 m-0 row">
                        <p className="m-0 p-0 col-12 col-md-6 col-lg-12">
                          <FormattedMessage
                            id="marketing.filter.quantity1"
                            defaultMessage="Quantité de 1 à "
                          />
                          {filtreNombreCouche2}
                          <FormattedMessage
                            id="marketing.filter.quantity2"
                            defaultMessage=" cartes"
                          />
                        </p>
                        <input
                          className="m-0 p-0 col-12 col-md-6 col-lg-12"
                          type="range"
                          value={filtreNombreCouche2}
                          onChange={(e) =>
                            this.setState({
                              filtreNombreCouche2: e.target.value,
                            })
                          }
                          max="60"
                          min="1"
                          step="1"
                        ></input>
                      </div>
                    </div>

                    <div className="filterContent col-12 m-0 p-0 px-2 px-mobile-0 pt-3 pt-mobile-1 row">
                      <div className="cursorBloc col-12 p-0 m-0 row">
                        <p className="m-0 p-0 col-12 col-md-6 col-lg-12">
                          <FormattedMessage
                            id="marketing.filter.delais1"
                            defaultMessage="Delais de 1 à "
                          />
                          {filtreDelay}
                          <FormattedMessage
                            id="marketing.filter.delais2"
                            defaultMessage=" jours"
                          />
                        </p>
                        <input
                          className="m-0 p-0 col-12 col-md-6 col-lg-12"
                          type="range"
                          value={filtreDelay}
                          onChange={(e) =>
                            this.setState({ filtreDelay: e.target.value })
                          }
                          max="30"
                          min="1"
                          step="1"
                        ></input>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="filterPcb col-12 row m-0 p-0">
                    <div className="col-12 col-md-6 col-lg-12 m-0 p-0 pr-md-2 pr-lg-0 px-2 px-mobile-0">
                      <div className=" col-12 m-0 p-0">
                        <div className="filterContent col-12 m-0 p-0 pt-3 pt-mobile-1 row">
                          <p className="col-lg-12 col-xl-6 m-0 p-0">
                            <FormattedMessage
                              id="marketing.filter.nbCOuche"
                              defaultMessage="Nombre de couche"
                            />
                          </p>
                          <div className="col-6 col-lg-12 col-xl-6 p-0 m-0 row">
                            <button
                              className=" text-center quantityButton"
                              onClick={() => this.nbQuantityMore()}
                            >
                              +
                            </button>
                            <p className="col-1 text-center mb-0 mx-2 mt-2 p-0">
                              {this.state.filtreNombreCouche}
                            </p>
                            <button
                              className=" text-center quantityButton"
                              onClick={() => this.nbQuantityLess()}
                            >
                              -
                            </button>
                          </div>
                        </div>
                        <div className="filterContent col-12 m-0 p-0 pt-3 pt-mobile-1 row">
                          <p className="col-6 m-0 p-0 col-lg-12 col-xl-6">
                            <FormattedMessage
                              id="marketing.filter.Materiaux"
                              defaultMessage="Materiaux"
                            />
                          </p>
                          <select
                            className="col-6 col-lg-12 col-xl-6 m-0 p-0"
                            name="Materiaux"
                            onChange={(e) =>
                              this.setState({
                                filtreMateriaux: e.target.value,
                                selectedMateriaux: e.target.value,
                                removeMateriaux: false,
                              })
                            }
                          >
                            <FormattedMessage
                              id="marketing.filter.Materiaux1"
                              defaultMessage="Toute"
                            >
                              {(t) => (
                                <option
                                  selected={
                                    selectedMateriaux === "All" ||
                                    removeMateriaux === true
                                      ? true
                                      : null
                                  }
                                  value="All"
                                >
                                  {t}
                                </option>
                              )}
                            </FormattedMessage>

                            <option
                              selected={
                                selectedMateriaux === "FR-1"
                                  ? selectedMateriaux
                                  : ""
                              }
                              value="FR-1"
                            >
                              FR-1
                            </option>
                            <option
                              selected={
                                selectedMateriaux === "FR-2"
                                  ? selectedMateriaux
                                  : ""
                              }
                              value="FR-2"
                            >
                              FR-2
                            </option>
                            <option
                              selected={
                                selectedMateriaux === "FR-3"
                                  ? selectedMateriaux
                                  : ""
                              }
                              value="FR-3"
                            >
                              FR-3
                            </option>
                            <option
                              selected={
                                selectedMateriaux === "FR-4"
                                  ? selectedMateriaux
                                  : ""
                              }
                              value="FR-4"
                            >
                              FR-4
                            </option>
                            <option
                              selected={
                                selectedMateriaux === "FR-5"
                                  ? selectedMateriaux
                                  : ""
                              }
                              value="FR-5"
                            >
                              FR-5
                            </option>
                            <option
                              selected={
                                selectedMateriaux === "CEM-1"
                                  ? selectedMateriaux
                                  : ""
                              }
                              value="CEM-1"
                            >
                              CEM-1
                            </option>
                            <option
                              selected={
                                selectedMateriaux === "CEM-2"
                                  ? selectedMateriaux
                                  : ""
                              }
                              value="CEM-2"
                            >
                              CEM-2
                            </option>
                            <option
                              selected={
                                selectedMateriaux === "CEM-3"
                                  ? selectedMateriaux
                                  : ""
                              }
                              value="CEM-3"
                            >
                              CEM-3
                            </option>

                            <FormattedMessage
                              id="marketing.filter.Materiaux.Duroid"
                              defaultMessage="Duroid"
                            >
                              {(t) => (
                                <option
                                  selected={
                                    selectedMateriaux === "Duroid"
                                      ? selectedMateriaux
                                      : ""
                                  }
                                  value="Duroid"
                                >
                                  {t}
                                </option>
                              )}
                            </FormattedMessage>
                            <FormattedMessage
                              id="marketing.filter.Materiaux.Polyester"
                              defaultMessage="Polyester"
                            >
                              {(t) => (
                                <option
                                  selected={
                                    selectedMateriaux === "Polyester"
                                      ? selectedMateriaux
                                      : ""
                                  }
                                  value="Polyester"
                                >
                                  {t}
                                </option>
                              )}
                            </FormattedMessage>
                            <FormattedMessage
                              id="marketing.filter.Materiaux.Polyimide"
                              defaultMessage="Polyimide"
                            >
                              {(t) => (
                                <option
                                  selected={
                                    selectedMateriaux === "Polyimide"
                                      ? selectedMateriaux
                                      : ""
                                  }
                                  value="Polyimide"
                                >
                                  {t}
                                </option>
                              )}
                            </FormattedMessage>
                            <FormattedMessage
                              id="marketing.filter.Materiaux.Kapton"
                              defaultMessage="Kapton"
                            >
                              {(t) => (
                                <option
                                  selected={
                                    selectedMateriaux === "Kapton"
                                      ? selectedMateriaux
                                      : ""
                                  }
                                  value="Kapton"
                                >
                                  {t}
                                </option>
                              )}
                            </FormattedMessage>
                            <FormattedMessage
                              id="marketing.filter.Materiaux.Polyethylene"
                              defaultMessage="Polyethylene"
                            >
                              {(t) => (
                                <option
                                  selected={
                                    selectedMateriaux === "Polyethylene"
                                      ? selectedMateriaux
                                      : ""
                                  }
                                  value="Polyethylene"
                                >
                                  {t}
                                </option>
                              )}
                            </FormattedMessage>
                            <FormattedMessage
                              id="marketing.filter.Materiaux.Polyflon"
                              defaultMessage="Polyflon"
                            >
                              {(t) => (
                                <option
                                  selected={
                                    selectedMateriaux === "Polyflon"
                                      ? selectedMateriaux
                                      : ""
                                  }
                                  value="Polyflon"
                                >
                                  {t}
                                </option>
                              )}
                            </FormattedMessage>
                            <option
                              selected={
                                selectedMateriaux === "XPC"
                                  ? selectedMateriaux
                                  : ""
                              }
                              value="XPC"
                            >
                              XPC
                            </option>
                            <option
                              selected={
                                selectedMateriaux === "PTFE"
                                  ? selectedMateriaux
                                  : ""
                              }
                              value="PTFE"
                            >
                              PTFE
                            </option>
                            <FormattedMessage
                              id="marketing.filter.Materiaux.Pyralux"
                              defaultMessage="Pyralux"
                            >
                              {(t) => (
                                <option
                                  selected={
                                    selectedMateriaux === "Pyralux"
                                      ? selectedMateriaux
                                      : ""
                                  }
                                  value="Pyralux"
                                >
                                  {t}
                                </option>
                              )}
                            </FormattedMessage>
                            <option
                              selected={
                                selectedMateriaux === "Rogers"
                                  ? selectedMateriaux
                                  : ""
                              }
                              value="Rogers"
                            >
                              Rogers
                            </option>
                          </select>
                        </div>
                        <div className="filterContent col-12 m-0 p-0 pt-3 pt-mobile-1 row">
                          <p className="col-6 m-0 p-0 col-lg-12 col-xl-6">
                            <FormattedMessage
                              id="marketing.filter.Finitions"
                              defaultMessage="Finitions"
                            />
                          </p>
                          <select
                            className="col-6 col-lg-12 col-xl-6 m-0 p-0"
                            name="Finitions"
                            onChange={(e) =>
                              this.setState({
                                filtreFinitions: e.target.value,
                                selectedFinitions: e.target.value,
                                removeFinitions: false,
                              })
                            }
                          >
                            <FormattedMessage
                              id="marketing.filter.Finitions1"
                              defaultMessage="Toute"
                            >
                              {(t) => (
                                <option
                                  selected={
                                    selectedFinitions === "All" ||
                                    removeFinitions === true
                                      ? true
                                      : null
                                  }
                                  value="All"
                                >
                                  {t}
                                </option>
                              )}
                            </FormattedMessage>

                            <FormattedMessage
                              id="marketing.filter.Finitions2"
                              defaultMessage="HAL standard"
                            >
                              {(t) => (
                                <option
                                  selected={
                                    selectedFinitions === "HAL standard"
                                      ? selectedFinitions
                                      : ""
                                  }
                                  value="HAL standard"
                                >
                                  {t}
                                </option>
                              )}
                            </FormattedMessage>
                            <FormattedMessage
                              id="marketing.filter.Finitions3"
                              defaultMessage="Or chimique"
                            >
                              {(t) => (
                                <option
                                  selected={
                                    selectedFinitions === "Chemical gold"
                                      ? selectedFinitions
                                      : ""
                                  }
                                  value="Chemical gold"
                                >
                                  {t}
                                </option>
                              )}
                            </FormattedMessage>
                            <FormattedMessage
                              id="marketing.filter.Finitions4"
                              defaultMessage="Or electro"
                            >
                              {(t) => (
                                <option
                                  selected={
                                    selectedFinitions === "Gold electro"
                                      ? selectedFinitions
                                      : ""
                                  }
                                  value="Gold electro"
                                >
                                  {t}
                                </option>
                              )}
                            </FormattedMessage>
                            <FormattedMessage
                              id="marketing.filter.Finitions5"
                              defaultMessage="Argent chimique"
                            >
                              {(t) => (
                                <option
                                  selected={
                                    selectedFinitions === "Chemical silver"
                                      ? selectedFinitions
                                      : ""
                                  }
                                  value="Chemical silver"
                                >
                                  {t}
                                </option>
                              )}
                            </FormattedMessage>
                            <FormattedMessage
                              id="marketing.filter.Finitions6"
                              defaultMessage="Etain chimique"
                            >
                              {(t) => (
                                <option
                                  selected={
                                    selectedFinitions === "Chemical tin"
                                      ? selectedFinitions
                                      : ""
                                  }
                                  value="Chemical tin"
                                >
                                  {t}
                                </option>
                              )}
                            </FormattedMessage>
                            <FormattedMessage
                              id="marketing.filter.Finitions7"
                              defaultMessage="OSP"
                            />
                            {(t) => (
                              <option
                                selected={
                                  selectedFinitions === "OSP"
                                    ? selectedFinitions
                                    : ""
                                }
                                value="OSP"
                              >
                                {t}
                              </option>
                            )}
                            <FormattedMessage
                              id="marketing.filter.Finitions8"
                              defaultMessage="HAL-SN100C"
                            >
                              {(t) => (
                                <option
                                  selected={
                                    selectedFinitions === "HAL-SN100C"
                                      ? selectedFinitions
                                      : ""
                                  }
                                  value="HAL-SN100C"
                                >
                                  {t}
                                </option>
                              )}
                            </FormattedMessage>
                            <FormattedMessage
                              id="marketing.filter.Finitions9"
                              defaultMessage="HAL lead free"
                            >
                              {(t) => (
                                <option
                                  selected={
                                    selectedFinitions === "HAL lead free"
                                      ? selectedFinitions
                                      : ""
                                  }
                                  value="HAL lead free"
                                >
                                  {t}
                                </option>
                              )}
                            </FormattedMessage>
                            <FormattedMessage
                              id="marketing.filter.Finitions10"
                              defaultMessage="ENEPIG"
                            >
                              {(t) => (
                                <option
                                  selected={
                                    selectedFinitions === "ENEPIG"
                                      ? selectedFinitions
                                      : ""
                                  }
                                  value="ENEPIG"
                                >
                                  {t}
                                </option>
                              )}
                            </FormattedMessage>
                            <FormattedMessage
                              id="marketing.filter.Finitions11"
                              defaultMessage="Sans Finitions"
                            >
                              {(t) => (
                                <option
                                  selected={
                                    selectedFinitions === "Without finish"
                                      ? selectedFinitions
                                      : ""
                                  }
                                  value="Without finish"
                                >
                                  {t}
                                </option>
                              )}
                            </FormattedMessage>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-md-6 col-lg-12 m-0 p-0  px-2 px-mobile-0">
                      <div className=" col-12 m-0 p-0 pt-3 pt-mobile-1">
                        <div className="filterContent col-12 m-0 p-0 row">
                          <p className="col-6 col-lg-12 col-xl-6 m-0 p-0">
                            <FormattedMessage
                              id="marketing.filter.rigidité"
                              defaultMessage="Rigidité"
                            />
                          </p>
                          <select
                            className="col-6 col-lg-12 col-xl-6 m-0 p-0"
                            name="Rigidité"
                            onChange={(e) =>
                              this.setState({
                                filtreRigide: e.target.value,
                                selectedRigide: e.target.value,
                                removeRigide: false,
                              })
                            }
                          >
                            <FormattedMessage
                              id="marketing.filter.rigidité1"
                              defaultMessage="Toute"
                            >
                              {(t) => (
                                <option
                                  selected={
                                    selectedRigide === "All" ||
                                    removeRigide === true
                                      ? true
                                      : null
                                  }
                                  value="All"
                                >
                                  {t}
                                </option>
                              )}
                            </FormattedMessage>
                            <FormattedMessage
                              id="marketing.filter.rigidité2"
                              defaultMessage="Flexible"
                            >
                              {(t) => (
                                <option
                                  selected={
                                    selectedRigide === "Flexible"
                                      ? selectedRigide
                                      : ""
                                  }
                                  value="Flexible"
                                >
                                  {t}
                                </option>
                              )}
                            </FormattedMessage>
                            <FormattedMessage
                              id="marketing.filter.rigidité3"
                              defaultMessage="Semi flexible"
                            >
                              {(t) => (
                                <option
                                  selected={
                                    selectedRigide === "Semi flexible"
                                      ? selectedRigide
                                      : ""
                                  }
                                  value="Semi flexible"
                                >
                                  {t}
                                </option>
                              )}
                            </FormattedMessage>
                            <FormattedMessage
                              id="marketing.filter.rigidité4"
                              defaultMessage="Rigide"
                            >
                              {(t) => (
                                <option
                                  selected={
                                    selectedRigide === "Rigide"
                                      ? selectedRigide
                                      : ""
                                  }
                                  value="Rigide"
                                >
                                  {t}
                                </option>
                              )}
                            </FormattedMessage>
                          </select>
                        </div>
                      </div>
                      <div className=" col-12 m-0 p-0 pt-3 pt-mobile-1">
                        <div className="filterContent col-12 m-0 p-0 row">
                          <p className="col-6 col-lg-12 col-xl-6 m-0 p-0">
                            <FormattedMessage
                              id="marketing.filter.via"
                              defaultMessage="Via"
                            />
                          </p>
                          <select
                            className="col-6 col-lg-12 col-xl-6 m-0 p-0"
                            name="via"
                            onChange={(e) =>
                              this.setState({
                                filtreVia: e.target.value,
                                selectedVia: e.target.value,
                              }) & this.setState({ removeVia: false })
                            }
                          >
                            <FormattedMessage
                              id="marketing.filter.via1"
                              defaultMessage="Toute"
                            >
                              {(t) => (
                                <option
                                  selected={
                                    selectedVia === "All" || removeVia === true
                                      ? true
                                      : null
                                  }
                                  value="All"
                                >
                                  {t}
                                </option>
                              )}
                            </FormattedMessage>
                            <FormattedMessage
                              id="marketing.filter.via2"
                              defaultMessage="Micro via (laser)"
                            >
                              {(t) => (
                                <option
                                  selected={
                                    selectedVia === "Laser" ? selectedVia : ""
                                  }
                                  value="Laser"
                                >
                                  {t}
                                </option>
                              )}
                            </FormattedMessage>
                            <FormattedMessage
                              id="marketing.filter.via3"
                              defaultMessage="Entérré"
                            >
                              {(t) => (
                                <option
                                  selected={
                                    selectedVia === "Buried" ? selectedVia : ""
                                  }
                                  value="Buried"
                                >
                                  {t}
                                </option>
                              )}
                            </FormattedMessage>
                          </select>
                        </div>
                      </div>
                      <div className=" col-12 m-0 p-0 pt-3 pt-mobile-1">
                        <div className="filterContent col-12 m-0 p-0 row">
                          <p className="col-6 col-lg-12 col-xl-6 m-0 p-0">
                            <FormattedMessage
                              id="marketing.filter.couleurs"
                              defaultMessage="Couleurs du vernis"
                            />
                          </p>
                          <select
                            className="col-6 col-lg-12 col-xl-6 m-0 p-0"
                            name="Couleurs de la sérigraphie"
                            onChange={(e) =>
                              this.setState({
                                filtreCouleurs: e.target.value,
                                selectedCouleurs: e.target.value,
                              }) & this.setState({ removeCouleurs: false })
                            }
                          >
                            <FormattedMessage
                              id="marketing.filter.couleurs1"
                              defaultMessage="Toute"
                            >
                              {(t) => (
                                <option
                                  selected={
                                    selectedCouleurs === "Toute" ||
                                    removeCouleurs === true
                                      ? true
                                      : null
                                  }
                                  value="Toute"
                                >
                                  {t}
                                </option>
                              )}
                            </FormattedMessage>
                            <FormattedMessage
                              id="marketing.filter.couleurs2"
                              defaultMessage="Noir"
                            >
                              {(t) => (
                                <option
                                  selected={
                                    selectedCouleurs === "Black"
                                      ? selectedCouleurs
                                      : ""
                                  }
                                  value="Black"
                                >
                                  {t}
                                </option>
                              )}
                            </FormattedMessage>
                            <FormattedMessage
                              id="marketing.filter.couleurs3"
                              defaultMessage="Vert"
                            >
                              {(t) => (
                                <option
                                  selected={
                                    selectedCouleurs === "Green"
                                      ? selectedCouleurs
                                      : ""
                                  }
                                  value="Green"
                                >
                                  {t}
                                </option>
                              )}
                            </FormattedMessage>
                            <FormattedMessage
                              id="marketing.filter.couleurs4"
                              defaultMessage="Blanc"
                            >
                              {(t) => (
                                <option
                                  selected={
                                    selectedCouleurs === "White"
                                      ? selectedCouleurs
                                      : ""
                                  }
                                  value="White"
                                >
                                  {t}
                                </option>
                              )}
                            </FormattedMessage>
                            <FormattedMessage
                              id="marketing.filter.couleurs5"
                              defaultMessage="Rouge"
                            >
                              {(t) => (
                                <option
                                  selected={
                                    selectedCouleurs === "Red"
                                      ? selectedCouleurs
                                      : ""
                                  }
                                  value="Red"
                                >
                                  {t}
                                </option>
                              )}
                            </FormattedMessage>
                            <FormattedMessage
                              id="marketing.filter.couleurs6"
                              defaultMessage="Bleu"
                            >
                              {(t) => (
                                <option
                                  selected={
                                    selectedCouleurs === "Blue"
                                      ? selectedCouleurs
                                      : ""
                                  }
                                  value="Blue"
                                >
                                  {t}
                                </option>
                              )}
                            </FormattedMessage>
                            <FormattedMessage
                              id="marketing.filter.couleurs7"
                              defaultMessage="Violet"
                            >
                              {(t) => (
                                <option
                                  selected={
                                    selectedCouleurs === "Purple"
                                      ? selectedCouleurs
                                      : ""
                                  }
                                  value="Purple"
                                >
                                  {t}
                                </option>
                              )}
                            </FormattedMessage>
                            <FormattedMessage
                              id="marketing.filter.couleurs8"
                              defaultMessage="Jaune"
                            >
                              {(t) => (
                                <option
                                  selected={
                                    selectedCouleurs === "Yellow"
                                      ? selectedCouleurs
                                      : ""
                                  }
                                  value="Yellow"
                                >
                                  {t}
                                </option>
                              )}
                            </FormattedMessage>
                            <FormattedMessage
                              id="marketing.filter.couleurs9"
                              defaultMessage="Orange"
                            >
                              {(t) => (
                                <option
                                  selected={
                                    selectedCouleurs === "Orange"
                                      ? selectedCouleurs
                                      : ""
                                  }
                                  value="Orange"
                                >
                                  {t}
                                </option>
                              )}
                            </FormattedMessage>
                            <FormattedMessage
                              id="marketing.filter.couleurs10"
                              defaultMessage="Vert mat"
                            >
                              {(t) => (
                                <option
                                  selected={
                                    selectedCouleurs === "Matt green"
                                      ? selectedCouleurs
                                      : ""
                                  }
                                  value="Matt green"
                                >
                                  {t}
                                </option>
                              )}
                            </FormattedMessage>
                            <FormattedMessage
                              id="marketing.filter.couleurs11"
                              defaultMessage="Noir mat"
                            >
                              {(t) => (
                                <option
                                  selected={
                                    selectedCouleurs === "Matt black"
                                      ? selectedCouleurs
                                      : ""
                                  }
                                  value="Matt black"
                                >
                                  {t}
                                </option>
                              )}
                            </FormattedMessage>
                            <FormattedMessage
                              id="marketing.filter.couleurs12"
                              defaultMessage="Transparent"
                            >
                              {(t) => (
                                <option
                                  selected={
                                    selectedCouleurs === "Transparent"
                                      ? selectedCouleurs
                                      : ""
                                  }
                                  value="Transparent"
                                >
                                  {t}
                                </option>
                              )}
                            </FormattedMessage>
                            <FormattedMessage
                              id="marketing.filter.couleurs13"
                              defaultMessage="Sans vernis"
                            >
                              {(t) => (
                                <option
                                  selected={
                                    selectedCouleurs === "Without varnish"
                                      ? selectedCouleurs
                                      : ""
                                  }
                                  value="Without varnish"
                                >
                                  {t}
                                </option>
                              )}
                            </FormattedMessage>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : null}
          </div>

          <div className="listing blocStyle col-lg-8 mx-lg-3 col-sm-9 col-12">
            <div className="companyContener col-12 row m-0 ">
              <h2 className="col-11 text-center ">
                <FormattedMessage
                  id="marketing.list.resultat"
                  defaultMessage="Résultats"
                />
              </h2>
              {/* <div className="col-12 row resultSorter m-0 p-0 d-lg-flex d-none"> 
                        <div className="col-1">
                            <p className="text-center "><FormattedMessage id="marketing.list.place" defaultMessage="Place"/></p>
                        </div>
                        <div className="col-2">
                            <p className="text-center ">Logo</p>
                        </div>
                    <div className="col-9 p-0">
                        <p className="text-center"><FormattedMessage id="marketing.list.information" defaultMessage="Information"/></p>
                    </div>
                </div> */}
            </div>

            <div className="col-12 row m-0 p-0 selectedFiltreList">
              {filtreLangue === "All" ? null : (
                <div className="selectedFiltre px-1">
                  <button
                    className=" row p-0 m-0"
                    onClick={(e) =>
                      this.setState({ removeFiltreLangue: true }) &
                      this.changeFiltreLangue("All")
                    }
                  >
                    <p className="px-1 m-0 selectedFiltreCroix">x</p>
                    <p className="px-1 m-0">{filtreLangue}</p>
                  </button>
                </div>
              )}
              {filtreNombreCouche === 1 || showPcba === true ? null : (
                <div className="selectedFiltre px-1">
                  <button
                    className=" row p-0 m-0"
                    onClick={(e) => this.setState({ filtreNombreCouche: 1 })}
                  >
                    <p className="px-1 m-0 selectedFiltreCroix">x</p>
                    <p className="px-1 m-0">
                      Nombre de couche: {filtreNombreCouche}
                    </p>
                  </button>
                </div>
              )}
              {filtreMateriaux === "All" || showPcba === true ? null : (
                <div className="selectedFiltre px-1">
                  <button
                    className=" row p-0 m-0"
                    onClick={(e) =>
                      this.setState({
                        removeMateriaux: true,
                        selectedMateriaux: "",
                      }) & this.setState({ filtreMateriaux: "All" })
                    }
                  >
                    <p className="px-1 m-0 selectedFiltreCroix">x</p>
                    <p className="px-1 m-0">{filtreMateriaux}</p>
                  </button>
                </div>
              )}
              {filtreFinitions === "All" || showPcba === true ? null : (
                <div className="selectedFiltre px-1">
                  <button
                    className=" row p-0 m-0"
                    onClick={(e) =>
                      this.setState({
                        removeFinitions: true,
                        selectedFinitions: "",
                      }) & this.setState({ filtreFinitions: "All" })
                    }
                  >
                    <p className="px-1 m-0 selectedFiltreCroix">x</p>
                    <p className="px-1 m-0">{filtreFinitions}</p>
                  </button>
                </div>
              )}
              {filtreRigide === "All" || showPcba === true ? null : (
                <div className="selectedFiltre px-1">
                  <button
                    className=" row p-0 m-0"
                    onClick={(e) =>
                      this.setState({
                        removeRigide: true,
                        selectedRigide: "",
                        filtreRigide: "All",
                      })
                    }
                  >
                    <p className="px-1 m-0 selectedFiltreCroix">x</p>
                    <p className="px-1 m-0">{filtreRigide}</p>
                  </button>
                </div>
              )}
              {filtreVia === "All" || showPcba === true ? null : (
                <div className="selectedFiltre px-1">
                  <button
                    className=" row p-0 m-0"
                    onClick={(e) =>
                      this.setState({ removeVia: true, selectedVia: "" }) &
                      this.setState({ filtreVia: "All" })
                    }
                  >
                    <p className="px-1 m-0 selectedFiltreCroix">x</p>
                    <p className="px-1 m-0">{filtreVia}</p>
                  </button>
                </div>
              )}
              {filtreCouleurs === "Toute" || showPcba === true ? null : (
                <div className="selectedFiltre px-1">
                  <button
                    className=" row p-0 m-0"
                    onClick={(e) =>
                      this.setState({
                        removeCouleurs: true,
                        selectedCouleurs: "",
                        filtreCouleurs: "Toute",
                      })
                    }
                  >
                    <p className="px-1 m-0 selectedFiltreCroix">x</p>
                    <p className="px-1 m-0">{filtreCouleurs}</p>
                  </button>
                </div>
              )}
              {filtreNombreCouche2 < 2 || showPcba === false ? null : (
                <div className="selectedFiltre px-1">
                  <button
                    className=" row p-0 m-0"
                    onClick={(e) => this.setState({ filtreNombreCouche2: 1 })}
                  >
                    <p className="px-1 m-0 selectedFiltreCroix">x</p>
                    <p className="px-1 m-0">
                      Quantité max: {filtreNombreCouche2}
                    </p>
                  </button>
                </div>
              )}
              {filtreDelay > 29 || showPcba === false ? null : (
                <div className="selectedFiltre px-1">
                  <button
                    className=" row p-0 m-0"
                    onClick={(e) => this.setState({ filtreDelay: 30 })}
                  >
                    <p className="px-1 m-0 selectedFiltreCroix">x</p>
                    <p className="px-1 m-0">Délais max: {filtreDelay}</p>
                  </button>
                </div>
              )}
            </div>

            <Company
              filtreLangue={state.filtreLangue}
              filtreNombreCouche={state.filtreNombreCouche}
              filtreMateriaux={state.filtreMateriaux}
              filtreFinitions={state.filtreFinitions}
              filtreRigide={state.filtreRigide}
              showPcba={state.showPcba}
              filtreVia={state.filtreVia}
              filtreCouleurs={state.filtreCouleurs}
              filtreDelay={state.filtreDelay}
              filtreNombreCouche2={state.filtreNombreCouche2}
            />
          </div>

          <div className=" col-lg p-lg-0 col-sm-3 col-12"></div>
        </div>
      </div>
    );
  }
}

export default injectIntl(Home);
