import React from "react";
import { connect } from "react-redux";

import requestsActions from "./actions";

const RequestsContext = React.createContext();
const Provider = RequestsContext.Provider;
const Consumer = RequestsContext.Consumer;

const ProviderComponent = props => (
  <Provider value={props}>{props.children}</Provider>
);

const mapStateToProps = state => {
  return {
    requests: state.requests
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addRequest: requestsActions.add,
    removeRequest: requestsActions.remove
  };
};

export const RequestsProvider = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProviderComponent);

export const withRequests = Component => props => (
  <Consumer>
    {requestsProps => (
      <Component {...Object.assign({}, props, requestsProps)}>
        {props.children}
      </Component>
    )}
  </Consumer>
);
