const languages = ["fr", "en", "it", "de", "es", "nl"];

export default languages;

export const intlLanguages = {
  fr: "Français",
  en: "English",
  it: "Italiano",
  de: "Deutsh",
  es: "Español",
  nl: "Nederlands",
};
