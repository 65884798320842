import React from "react";
import "styles/index.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "Elements";
import axios from "axios";
import { FormattedMessage, defineMessages, injectIntl } from "react-intl";
import metaTag from "../utils/metaTag";
import Recaptcha from "react-recaptcha";
import { FormCheckbox, FormSelect, FormInput, Alert } from "shards-react";
const API_PATH = "https://pcb-market.com/form.php";

class Forms extends React.Component {
  constructor(props) {
    super(props);
    this.handleChangeColor = this.handleChangeColor.bind(this);
    this.showAlertSuccess = this.showAlertSuccess.bind(this);
    this.showAlertEchec = this.showAlertEchec.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.clearInterval = this.clearInterval.bind(this);

    this.state = {
      alertEchec: false,
      alertSuccess: false,
      conditionCheckBox: false,
      alertDescription: false,
      alertTypes: false,
      alertLangues: false,
      alertMateriaux: false,
      alertFinitions: false,
      alertRigidités: false,
      alertVia: false,
      alertCouleurs: false,
      countdown: 0,
      timeUntilDismissed: 10,
      couleurs: [],
      noir: false,
      orange: false,
      vert: false,
      bleu: false,
      rouge: false,
      blanc: false,
      violet: false,
      jaune: false,
      vertMat: false,
      noirMat: false,
      transparent: false,
      sansVernis: false,
      langues: [],
      francais: false,
      anglais: false,
      espagnol: false,
      italien: false,
      allemand: false,
      portugais: false,
      néerlandais: false,
      repTcheque: false,
      afriqueDuSud: false,
      materiaux: [],
      allMateriaux: false,
      finitions: [],
      halStandard: false,
      orChimique: false,
      orElectro: false,
      argentChimique: false,
      etainChimique: false,
      halSN100C: false,
      halLeadFree: false,
      ENEPIG: false,
      OSP: false,
      CEM: false,
      FR: false,
      Duroid: false,
      Kapton: false,
      Polyester: false,
      Polyethylene: false,
      Polyflon: false,
      Polyimide: false,
      PTFE: false,
      Pyralux: false,
      Teflon: false,
      XPC: false,
      Rogers: false,
      sansmateriaux: false,
      sansFinitions: false,
      rigidités: [],
      rigide: false,
      semiFlexible: false,
      flexible: false,
      vias: [],
      laser: false,
      entérré: false,
      types: [],
      pcb: false,
      pcba: false,
      nameIsValid: false,
      nameIsInvalid: false,
      emailIsValid: false,
      emailIsInvalid: false,
      descriptionIsValid: false,
      descriptionIsInvalid: false,
      lienIsValid: false,
      lienIsInvalid: false,
      quantitéIsValid: false,
      quantitéIsInvalid: false,
      delaisIsValid: false,
      delaisIsInvalid: false,
      nbCoucheIsValid: false,
      nbCoucheIsInvalid: false,
    };
  }

  handleChangeType(e, type) {
    const newState = {};
    newState[type] = !this.state[type];
    this.setState({ ...this.state, ...newState });
    if (newState[type] === true) {
      this.state.types.push(type);
      if (this.state.types.length > 0) {
        this.setState({ alertTypes: false });
      }
    } else {
      var index = this.state.types.indexOf(type);
      if (index > -1) {
        this.state.types.splice(index, 1);
      }
    }
  }

  handleChangeColor(e, color) {
    const newState = {};
    newState[color] = !this.state[color];
    this.setState({ ...this.state, ...newState });
    if (newState[color] === true) {
      this.state.couleurs.push(color);
      if (this.state.couleurs.length > 0) {
        this.setState({ alertCouleurs: false });
      }
    } else {
      var index = this.state.couleurs.indexOf(color);
      if (index > -1) {
        this.state.couleurs.splice(index, 1);
      }
    }
  }

  handleChangeLangue(e, langue) {
    const newState = {};
    newState[langue] = !this.state[langue];
    this.setState({ ...this.state, ...newState });
    if (newState[langue] === true) {
      this.state.langues.push(langue);
      if (this.state.langues.length > 0) {
        this.setState({ alertLangues: false });
      }
    } else {
      var index = this.state.langues.indexOf(langue);
      if (index > -1) {
        this.state.langues.splice(index, 1);
      }
    }
  }

  handleChangeMateriaux(materielle) {
    const newState = {};
    newState[materielle] = !this.state[materielle];
    this.setState({ ...this.state, ...newState });
    if (newState[materielle] === true) {
      this.state.materiaux.push(materielle);
      if (this.state.materiaux.length > 0) {
        this.setState({ alertMateriaux: false });
      }
    } else {
      var index = this.state.materiaux.indexOf(materielle);
      if (index > -1) {
        this.state.materiaux.splice(index, 1);
      }
    }
  }
  // handleChangeAllMateriaux(table) {
  //   //this.setState({ allMateriaux: !this.state.allMateriaux });
  //   //this.handleChangeMateriaux(table[0]);
  //   table.forEach(
  //     (e, i) => this.handleChangeMateriaux(table[i]),
  //     console.log("Materiaux: ", this.state.materiaux)
  //   );

  //   console.log("Materiaux: ", this.state.materiaux);
  // }

  handleChangeFinitions(e, finition) {
    const newState = {};
    newState[finition] = !this.state[finition];
    this.setState({ ...this.state, ...newState });
    if (newState[finition] === true) {
      this.state.finitions.push(finition);
      if (this.state.finitions.length > 0) {
        this.setState({ alertFinitions: false });
      }
    } else {
      var index = this.state.finitions.indexOf(finition);
      if (index > -1) {
        this.state.finitions.splice(index, 1);
      }
    }
  }

  handleChangeRigidité(e, rigidité) {
    const newState = {};
    newState[rigidité] = !this.state[rigidité];
    this.setState({ ...this.state, ...newState });
    if (newState[rigidité] === true) {
      this.state.rigidités.push(rigidité);
      if (this.state.rigidités.length > 0) {
        this.setState({ alertRigidités: false });
      }
    } else {
      var index = this.state.rigidités.indexOf(rigidité);
      if (index > -1) {
        this.state.rigidités.splice(index, 1);
      }
    }
  }

  handleChangeVia(e, via) {
    const newState = {};
    newState[via] = !this.state[via];
    this.setState({ ...this.state, ...newState });
    if (newState[via] === true) {
      this.state.vias.push(via);
      if (this.state.vias.length > 0) {
        this.setState({ alertVia: false });
      }
    } else {
      var index = this.state.vias.indexOf(via);
      if (index > -1) {
        this.state.vias.splice(index, 1);
      }
    }
  }

  handleChangeNameValid(validité) {
    if (validité === "valid") {
      this.setState({ nameIsValid: true, nameIsInvalid: false });
    }
    if (validité === "invalid") {
      this.setState({ nameIsValid: false, nameIsInvalid: true });
    }
  }
  handleChangeEmailValid(validité) {
    if (validité === "valid") {
      this.setState({ emailIsValid: true, emailIsInvalid: false });
    }
    if (validité === "invalid") {
      this.setState({ emailIsValid: false, emailIsInvalid: true });
    }
  }
  handleChangeDescriptionValid(validité) {
    if (validité === "valid") {
      this.setState({ descriptionIsValid: true, descriptionIsInvalid: false });
    }
    if (validité === "invalid") {
      this.setState({ descriptionIsValid: false, descriptionIsInvalid: true });
    }
  }
  handleChangeLienValid(validité) {
    if (validité === "valid") {
      this.setState({ lienIsValid: true, lienIsInvalid: false });
    }
    if (validité === "invalid") {
      this.setState({ lienIsValid: false, lienIsInvalid: true });
    }
  }
  handleChangeNbCoucheValid(validité) {
    if (validité === "valid") {
      this.setState({ nbCoucheIsValid: true, nbCoucheIsInvalid: false });
    }
    if (validité === "invalid") {
      this.setState({ nbCoucheIsValid: false, nbCoucheIsInvalid: true });
    }
  }
  handleChangeQuantitéValid(validité) {
    if (validité === "valid") {
      this.setState({ quantitéIsValid: true, quantitéIsInvalid: false });
    }
    if (validité === "invalid") {
      this.setState({ quantitéIsValid: false, quantitéIsInvalid: true });
    }
  }
  handleChangeDelaisValid(validité) {
    if (validité === "valid") {
      this.setState({ delaisIsValid: true, delaisIsInvalid: false });
    }
    if (validité === "invalid") {
      this.setState({ delaisIsValid: false, delaisIsInvalid: true });
    }
  }

  //alerte
  showAlertSuccess() {
    this.clearInterval();
    this.setState({ alertSuccess: true, countdown: 0, timeUntilDismissed: 10 });
    this.interval = setInterval(this.handleTimeChange, 1000);
  }
  showAlertEchec() {
    this.clearInterval();
    this.setState({ alertEchec: true, countdown: 0, timeUntilDismissed: 10 });
    this.interval = setInterval(this.handleTimeChange, 1000);
  }

  handleTimeChange() {
    if (this.state.countdown < this.state.timeUntilDismissed - 1) {
      this.setState({
        ...this.state,
        ...{ countdown: this.state.countdown + 1 },
      });
      return;
    }

    this.setState({
      ...this.state,
      ...{ alertEchec: false, alertSuccess: false },
    });
    this.clearInterval();
  }

  clearInterval() {
    clearInterval(this.interval);
    this.interval = null;
  }
  handleChangeCondition() {
    this.setState({ conditionCheckBox: !this.state.conditionCheckBox });
  }

  state = {
    name: "",
    email: "",
    subject: "",
    langues: "",
    nombreCouche: "",
    materiaux: "",
    finitions: "",
    rigidités: "",
    vias: "",
    quantité: "",
    delais: "",
    description: "",
    lienSite: "",
    mailSent: false,
    error: null,
    captchaChanged: false,
    captchaLoaded: false,
  };

  handleFormSubmit = (e) => {
    e.preventDefault();
    if (
      this.state.name &&
      this.state.name !== "" &&
      this.state.types &&
      this.state.email &&
      this.state.email !== "" &&
      this.state.langues &&
      this.state.nombreCouche !== undefined &&
      this.state.nombreCouche !== "--" &&
      this.state.materiaux &&
      this.state.finitions &&
      this.state.rigidités &&
      this.state.vias &&
      this.state.couleurs &&
      this.state.quantité !== undefined &&
      this.state.quantité !== "--" &&
      this.state.delais !== undefined &&
      this.state.delais !== "--" &&
      this.state.description.length > 0 &&
      this.state.description.length < 300 &&
      this.state.lienSite &&
      this.state.lienSite !== ""
    ) {
      if (this.state.conditionCheckBox === true) {
        if (
          (this.state.captchaChanged && this.state.captchaLoaded) ||
          !this.state.captchaLoaded
        ) {
          axios({
            method: "post",
            url: `${API_PATH}`,
            headers: { "content-type": "application/json" },
            data: this.state,
          })
            .then((result) => {
              if (result.data.sent === true) {
                this.showAlertSuccess();
              } else {
                this.showAlertEchec();
              }
              this.setState({
                mailSent: result.data.sent,
                name: "",
                email: "",
                nombreCouche: "--",
                quantité: "--",
                delais: "--",
                description: "",
                lienSite: "",
                conditionCheckBox: false,
                captchaChanged: false,
                captchaLoaded: false,
                couleurs: [],
                noir: false,
                orange: false,
                vert: false,
                bleu: false,
                rouge: false,
                blanc: false,
                violet: false,
                jaune: false,
                vertMat: false,
                noirMat: false,
                transparent: false,
                sansVernis: false,
                langues: [],
                francais: false,
                anglais: false,
                espagnol: false,
                italien: false,
                allemand: false,
                portugais: false,
                néerlandais: false,
                repTcheque: false,
                afriqueDuSud: false,
                materiaux: [],
                finitions: [],
                halStandard: false,
                orChimique: false,
                orElectro: false,
                argentChimique: false,
                etainChimique: false,
                halSN100C: false,
                halLeadFree: false,
                ENEPIG: false,
                OSP: false,
                CEM: false,
                FR: false,
                Duroid: false,
                Kapton: false,
                Polyester: false,
                Polyethylene: false,
                Polyflon: false,
                Polyimide: false,
                PTFE: false,
                Pyralux: false,
                Teflon: false,
                XPC: false,
                Rogers: false,
                sansmateriaux: false,
                sansFinitions: false,
                rigidités: [],
                rigide: false,
                semiFlexible: false,
                flexible: false,
                vias: [],
                laser: false,
                entérré: false,
                types: [],
                pcb: false,
                pcba: false,
                nameIsValid: false,
                nameIsInvalid: false,
                emailIsValid: false,
                emailIsInvalid: false,
                descriptionIsValid: false,
                descriptionIsInvalid: false,
                lienIsValid: false,
                lienIsInvalid: false,
                quantitéIsValid: false,
                quantitéIsInvalid: false,
                delaisIsValid: false,
                delaisIsInvalid: false,
                nbCoucheIsValid: false,
                nbCoucheIsInvalid: false,
              });
            })
            .catch((error) => this.setState({ error: error.message }));
        } else {
          alert("Please prove that you are not a robot");
        }
      } else {
        alert("Please read the terms and check the box");
      }
    } else {
      if (
        this.state.nombreCouche
          ? this.state.nombreCouche === "--"
          : this.state.nombreCouche === undefined
      ) {
        this.handleChangeNbCoucheValid("invalid");
      }
      if (this.state.quantité === undefined || this.state.quantité === "--") {
        this.handleChangeQuantitéValid("invalid");
      }
      if (this.state.delais === undefined || this.state.delais === "--") {
        this.handleChangeDelaisValid("invalid");
      }
      if (
        this.state.name
          ? this.state.name.length < 1
          : this.state.name === undefined
      ) {
        this.handleChangeNameValid("invalid");
      }
      if (
        this.state.email
          ? this.state.email.length < 1
          : this.state.email === undefined
      ) {
        this.handleChangeEmailValid("invalid");
      }
      if (
        this.state.description
          ? this.state.description.length < 1
          : this.state.description === undefined
      ) {
        this.handleChangeDescriptionValid("invalid");
      }
      if (
        this.state.lienSite
          ? this.state.lienSite.length < 1
          : this.state.lienSite === undefined
      ) {
        this.handleChangeLienValid("invalid");
      }
      if (this.state.types.length < 1) {
        this.setState({ alertTypes: true });
      }
      if (this.state.langues.length < 1) {
        this.setState({ alertLangues: true });
      }
      if (this.state.materiaux.length < 1) {
        this.setState({ alertMateriaux: true });
      }
      if (this.state.finitions.length < 1) {
        this.setState({ alertFinitions: true });
      }
      if (this.state.rigidités.length < 1) {
        this.setState({ alertRigidités: true });
      }
      if (this.state.vias.length < 1) {
        this.setState({ alertVia: true });
      }
      if (this.state.couleurs.length < 1) {
        this.setState({ alertCouleurs: true });
      }
      alert("Fill in all the fields, please.");
    }
  };

  captchaChanged() {
    this.setState({ captchaChanged: true });
  }

  recaptchaLoaded() {
    this.setState({ captchaLoaded: true });
  }

  render() {
    // console.clear();
    // console.log("###########################################");
    // console.log("- name: ", this.state.name);
    // console.log("- email: ", this.state.email);
    // console.log("- types: ", this.state.types);
    // console.log("- langues: ", this.state.langues);
    // console.log("- nombreCouche: ", this.state.nombreCouche);
    // console.log("- materiaux: ", this.state.materiaux);
    // console.log("- all materiaux: ", this.state.allMateriaux);
    // console.log("- finitions: ", this.state.finitions);
    // console.log("- rigidités: ", this.state.rigidités);
    // console.log("- vias: ", this.state.vias);
    // console.log("- couleurs: ", this.state.couleurs);
    // console.log("- Quantité max par commande: ", this.state.quantité);
    // console.log("- Delais: ", this.state.delais);
    // console.log("- description: ", this.state.description);
    // console.log("- lien site: ", this.state.lienSite);
    // console.log("- condition checkbox: ", this.state.conditionCheckBox);
    // console.log("##########################################");

    const { props } = this;
    const message = defineMessages({
      title: {
        id: "marketing.formulaire.titre",
        defaultMessage: "Référencez votre entreprise de fabrication de PCB",
      },
      description: {
        id: "marketing.formulaire.description",
        defaultMessage:
          "Fabricants de circuit imprimé PCB, assembleur de carte électronique (EMS), référencer vos usines et savoir faire sur PCB-Market.com",
      },
      keyWords: {
        id: "marketing.keywordsFormulaire",
        defaultMessage:
          "pcb, circuit imprimé, fabrication pcb, espace entreprise, formulaire, pcb électronique, pcb design",
      },
      buttons: {
        id: "marketing.forms.buttons",
        defaultMessage: "Envoyer",
      },
      alertTitle: {
        id: "marketing.forms.alert.title",
        defaultMessage: "Alerte",
      },
      alertDescription: {
        id: "marketing.forms.alert.description",
        defaultMessage:
          "Veuillez cocher une ou plusieurs réponses s'il vous plaît.",
      },
      alertComplete: {
        id: "marketing.forms.alert.complete",
        defaultMessage: "Remplissez tous les champs, s'il vous plaît.",
      },
    });

    metaTag.setTitle({
      primary: "PCB-Market",
      secondary: props.intl.formatMessage(message.title),
    });

    metaTag.setDescription(props.intl.formatMessage(message.description));

    metaTag.setKeywords(props.intl.formatMessage(message.keyWords));

    //console.log('titre: ',metaTag.pageTitle,'description: ', metaTag.description, 'keywords: ', metaTag.keyWords)

    return (
      <div>
        <div className="formsPage col-12">
          <div className="formsText col-12 col-md-10 offset-md-1">
            <h1>
              <FormattedMessage
                id="marketing.forms.title"
                defaultMessage="Espace entreprise"
              />
            </h1>

            <p className="p-0 m-2 mt-5 texte font-weight-bold">
              <FormattedMessage
                id="marketing.forms1"
                defaultMessage="Vous n’etes pas encore référencé par ce comparateur et vous souhaitez l’etre?"
              />
            </p>
            <p className="p-0 m-0 mt-5 font-weight-bold texte">
              <FormattedMessage
                id="marketing.forms2"
                defaultMessage="Rien de plus simple, remplissez ce formulaire en concordance avec ce que votre entreprise permet de faire."
              />
            </p>
            <p className="p-0 m-0 soustexte">
              <FormattedMessage
                id="marketing.forms.soustext1"
                defaultMessage="Cela veut dire que les données que vous indiquez dans ce formulaire permettrons aux utilisateurs d'afficher ou non votre entreprise selon les filtres sélectionnés."
              />
            </p>
            <p className="p-0 m-0 mt-5 font-weight-bold texte">
              <FormattedMessage
                id="marketing.forms3"
                defaultMessage="PCB-Market se chargera ensuite de valider votre demande et vous serez enfin référencé sur ce comparateur."
              />
            </p>
            <p className="p-0 m-0 soustexte">
              <FormattedMessage
                id="marketing.forms.soustext2"
                defaultMessage="La validation consiste à comparer les données renseignées avec ceux présente ou non sur votre site, ensuite en fonction de la vraisemblance des données, elles sont insérées sur un serveur puis affichées dans le comparateur."
              />
            </p>
            <img src="/images/img/arrow/fleche1.png" alt="fleche"></img>
          </div>

          <div className="formsForm col-12 p-0">
            <form
              action="#"
              className="p-0 col-12 col-md-8 col-lg-6 offset-0 offset-md-2 offset-lg-3"
            >
              <div className="col-12 p-0 pt-2 pb-2 formBorder">
                <label className="col-12 font-weight-bold">
                  <FormattedMessage
                    id="marketing.forms.name"
                    defaultMessage="Nom de l'entreprise"
                  />
                </label>
                <FormInput
                  valid={this.state.nameIsValid}
                  invalid={this.state.nameIsInvalid}
                  className="col-12"
                  type="text"
                  id="name"
                  name="firstname"
                  value={this.state.name}
                  onChange={(e) => (
                    this.setState({ name: e.target.value }),
                    e.target.value.length < 1
                      ? this.handleChangeNameValid("invalid")
                      : this.handleChangeNameValid("valid")
                  )}
                />
              </div>

              <div className="col-12 p-0 pt-2 pb-2 formBorder">
                <label className="col-12 font-weight-bold">
                  <FormattedMessage
                    id="marketing.forms.email"
                    defaultMessage="Email"
                  />
                </label>
                <FormInput
                  valid={this.state.emailIsValid}
                  invalid={this.state.emailIsInvalid}
                  className="col-12"
                  value={this.state.email}
                  onChange={(e) => (
                    this.setState({ email: e.target.value }),
                    e.target.value.length < 1
                      ? this.handleChangeEmailValid("invalid")
                      : this.handleChangeEmailValid("valid")
                  )}
                />
              </div>

              <div className="col-12 p-0 pt-2 pb-2 formBorder">
                <label className="col-12 p-0 font-weight-bold">
                  <FormattedMessage
                    id="marketing.forms.type"
                    defaultMessage="PCB, PCBA ou les deux ?"
                  />
                </label>
                <FormCheckbox
                  className=" pl-4"
                  checked={this.state.pcb}
                  onChange={(e) => this.handleChangeType(e, "pcb")}
                >
                  PCB
                </FormCheckbox>

                <FormCheckbox
                  className=" pl-4"
                  checked={this.state.pcba}
                  onChange={(e) => this.handleChangeType(e, "pcba")}
                >
                  PCBA
                </FormCheckbox>
                <Alert
                  className="mb-3"
                  open={this.state.alertTypes}
                  theme="danger"
                >
                  <div className="row col-12 p-0 m-0">
                    <div className="row col-10 p-0 m-0">
                      <p className="col-12 p-0 m-0 pb-2 font-weight-bold">
                        {props.intl.formatMessage(message.alertTitle)}
                      </p>
                      <p className="col-12 p-0 m-0 pb-2">
                        {props.intl.formatMessage(message.alertDescription)}
                      </p>
                    </div>
                  </div>
                </Alert>
              </div>

              <div className="col-12 p-0 pt-2 pb-2 formBorder">
                <label className="col-12 p-0 font-weight-bold">
                  <FormattedMessage
                    id="marketing.forms.langue"
                    defaultMessage="Langue"
                  />
                </label>
                <div className="col-12 row">
                  <FormCheckbox
                    className="p-0 col-12 col-sm-6 pl-4"
                    checked={this.state.francais}
                    onChange={(e) => this.handleChangeLangue(e, "francais")}
                  >
                    <FormattedMessage
                      id="marketing.forms.langue.francais"
                      defaultMessage="Francais"
                    />
                  </FormCheckbox>

                  <FormCheckbox
                    className="p-0 col-12 col-sm-6 pl-4"
                    checked={this.state.anglais}
                    onChange={(e) => this.handleChangeLangue(e, "anglais")}
                  >
                    <FormattedMessage
                      id="marketing.forms.langue.anglais"
                      defaultMessage="Anglais"
                    />
                  </FormCheckbox>

                  <FormCheckbox
                    className="p-0 col-12 col-sm-6 pl-4"
                    checked={this.state.espagnol}
                    onChange={(e) => this.handleChangeLangue(e, "espagnol")}
                  >
                    <FormattedMessage
                      id="marketing.forms.langue.espagnol"
                      defaultMessage="Espagnol"
                    />
                  </FormCheckbox>

                  <FormCheckbox
                    className="p-0 col-12 col-sm-6 pl-4"
                    checked={this.state.allemand}
                    onChange={(e) => this.handleChangeLangue(e, "allemand")}
                  >
                    <FormattedMessage
                      id="marketing.forms.langue.allemand"
                      defaultMessage="Allemand"
                    />
                  </FormCheckbox>

                  <FormCheckbox
                    className="p-0 col-12 col-sm-6 pl-4"
                    checked={this.state.portugais}
                    onChange={(e) => this.handleChangeLangue(e, "portugais")}
                  >
                    <FormattedMessage
                      id="marketing.forms.langue.portugais"
                      defaultMessage="Portugais"
                    />
                  </FormCheckbox>

                  <FormCheckbox
                    className="p-0 col-12 col-sm-6 pl-4"
                    checked={this.state.italien}
                    onChange={(e) => this.handleChangeLangue(e, "italien")}
                  >
                    <FormattedMessage
                      id="marketing.forms.langue.italien"
                      defaultMessage="Italien"
                    />
                  </FormCheckbox>

                  <FormCheckbox
                    className="p-0 col-12 col-sm-6 pl-4"
                    checked={this.state.néerlandais}
                    onChange={(e) => this.handleChangeLangue(e, "néerlandais")}
                  >
                    <FormattedMessage
                      id="marketing.forms.langue.néerlandais"
                      defaultMessage="Néerlandais"
                    />
                  </FormCheckbox>

                  <FormCheckbox
                    className="p-0 col-12 col-sm-6 pl-4"
                    checked={this.state.repTcheque}
                    onChange={(e) => this.handleChangeLangue(e, "repTcheque")}
                  >
                    <FormattedMessage
                      id="marketing.forms.langue.repTcheque"
                      defaultMessage="Répuplique Tchèque"
                    />
                  </FormCheckbox>
                </div>
                <Alert
                  className="mb-3"
                  open={this.state.alertLangues}
                  theme="danger"
                >
                  <div className="row col-12 p-0 m-0">
                    <div className="row col-10 p-0 m-0">
                      <p className="col-12 p-0 m-0 pb-2 font-weight-bold">
                        {props.intl.formatMessage(message.alertTitle)}
                      </p>
                      <p className="col-12 p-0 m-0 pb-2">
                        {props.intl.formatMessage(message.alertDescription)}
                      </p>
                    </div>
                  </div>
                </Alert>
              </div>

              <div className="col-12 p-0 pt-2 pb-2 formBorder">
                <label className="col-12 font-weight-bold">
                  <FormattedMessage
                    id="marketing.forms.nbCouche"
                    defaultMessage="Nombre de couche max"
                  />
                </label>
                <FormSelect
                  valid={this.state.nbCoucheIsValid}
                  invalid={this.state.nbCoucheIsInvalid}
                  size="sm"
                  className="mb-2"
                  onChange={(e) => (
                    this.setState({ nombreCouche: e.target.value }),
                    e.target.value === "--"
                      ? this.handleChangeNbCoucheValid("invalid")
                      : this.handleChangeNbCoucheValid("valid")
                  )}
                >
                  <option
                    selected={this.state.mailSent === true ? "selected" : ""}
                    value="--"
                  >
                    --
                  </option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                  <option value="18">18</option>
                  <option value="19">19</option>
                  <option value="20">20</option>
                  <option value="21">21</option>
                  <option value="22">22</option>
                  <option value="23">23</option>
                  <option value="24">24</option>
                  <option value="25">25</option>
                  <option value="26">26</option>
                  <option value="27">27</option>
                  <option value="28">28</option>
                  <option value="29">29</option>
                  <option value="30">30</option>
                  <option value="31">31</option>
                  <option value="32">32</option>
                  <option value="33">33</option>
                  <option value="34">34</option>
                  <option value="35">35</option>
                  <option value="36">36</option>
                  <option value="37">37</option>
                  <option value="38">38</option>
                  <option value="39">39</option>
                  <option value="40">40</option>
                  <option value="41">41</option>
                  <option value="42">42</option>
                  <option value="43">43</option>
                  <option value="44">44</option>
                  <option value="45">45</option>
                  <option value="46">46</option>
                  <option value="47">47</option>
                  <option value="48">48</option>
                  <option value="49">49</option>
                  <option value="50+">50+</option>
                </FormSelect>
              </div>

              <div className="col-12 p-0 pt-2 pb-2 formBorder">
                <label className="col-12 p-0 font-weight-bold">
                  <FormattedMessage
                    id="marketing.forms.materiaux"
                    defaultMessage="Materiaux disponibles"
                  />
                </label>
                <div className="col-12 row">
                  {/* <FormCheckbox
                    className="p-0 col-12 col-sm-6 pl-4"
                    checked={this.state.allMateriaux}
                    onChange={(e) =>
                      this.handleChangeAllMateriaux(["FR", "CEM", "Kapton"])
                    }
                  >
                    ALL
                  </FormCheckbox> */}
                  <FormCheckbox
                    className="p-0 col-12 col-sm-6 pl-4"
                    checked={this.state.FR}
                    onChange={(e) => this.handleChangeMateriaux("FR")}
                  >
                    FR
                  </FormCheckbox>
                  <FormCheckbox
                    className="p-0 col-12 col-sm-6 pl-4"
                    checked={this.state.CEM}
                    onChange={(e) => this.handleChangeMateriaux("CEM")}
                  >
                    CEM
                  </FormCheckbox>

                  <FormCheckbox
                    className="p-0 col-12 col-sm-6 pl-4"
                    checked={this.state.Duroid}
                    onChange={(e) => this.handleChangeMateriaux("Duroid")}
                  >
                    <FormattedMessage
                      id="marketing.forms.materiaux.Duroid"
                      defaultMessage="Duroid"
                    />
                  </FormCheckbox>
                  <FormCheckbox
                    className="p-0 col-12 col-sm-6 pl-4"
                    checked={this.state.Kapton}
                    onChange={(e) => this.handleChangeMateriaux("Kapton")}
                  >
                    Kapton
                  </FormCheckbox>
                  <FormCheckbox
                    className="p-0 col-12 col-sm-6 pl-4"
                    checked={this.state.Polyester}
                    onChange={(e) => this.handleChangeMateriaux("Polyester")}
                  >
                    <FormattedMessage
                      id="marketing.forms.materiaux.Polyester"
                      defaultMessage="Polyester"
                    />
                  </FormCheckbox>
                  <FormCheckbox
                    className="p-0 col-12 col-sm-6 pl-4"
                    checked={this.state.Polyethylene}
                    onChange={(e) => this.handleChangeMateriaux("Polyethylene")}
                  >
                    <FormattedMessage
                      id="marketing.forms.materiaux.Polyethylene"
                      defaultMessage="Polyethylene"
                    />
                  </FormCheckbox>
                  <FormCheckbox
                    className="p-0 col-12 col-sm-6 pl-4"
                    checked={this.state.Polyflon}
                    onChange={(e) => this.handleChangeMateriaux("Polyflon")}
                  >
                    <FormattedMessage
                      id="marketing.forms.materiaux.Polyflon"
                      defaultMessage="Polyflon"
                    />
                  </FormCheckbox>
                  <FormCheckbox
                    className="p-0 col-12 col-sm-6 pl-4"
                    checked={this.state.Polyimide}
                    onChange={(e) => this.handleChangeMateriaux("Polyimide")}
                  >
                    <FormattedMessage
                      id="marketing.forms.materiaux.Polyimide"
                      defaultMessage="Polyimide"
                    />
                  </FormCheckbox>
                  <FormCheckbox
                    className="p-0 col-12 col-sm-6 pl-4"
                    checked={this.state.PTFE}
                    onChange={(e) => this.handleChangeMateriaux("PTFE")}
                  >
                    PTFE
                  </FormCheckbox>
                  <FormCheckbox
                    className="p-0 col-12 col-sm-6 pl-4"
                    checked={this.state.Pyralux}
                    onChange={(e) => this.handleChangeMateriaux("Pyralux")}
                  >
                    Pyralux
                  </FormCheckbox>
                  <FormCheckbox
                    className="p-0 col-12 col-sm-6 pl-4"
                    checked={this.state.Teflon}
                    onChange={(e) => this.handleChangeMateriaux("Teflon")}
                  >
                    Teflon
                  </FormCheckbox>

                  <FormCheckbox
                    className="p-0 col-12 col-sm-6 pl-4"
                    checked={this.state.XPC}
                    onChange={(e) => this.handleChangeMateriaux("XPC")}
                  >
                    XPC
                  </FormCheckbox>
                  <FormCheckbox
                    className="p-0 col-12 col-sm-6 pl-4"
                    checked={this.state.Rogers}
                    onChange={(e) => this.handleChangeMateriaux("Rogers")}
                  >
                    Rogers
                  </FormCheckbox>
                </div>
                <Alert
                  className="mb-3"
                  open={this.state.alertMateriaux}
                  theme="danger"
                >
                  <div className="row col-12 p-0 m-0">
                    <div className="row col-10 p-0 m-0">
                      <p className="col-12 p-0 m-0 pb-2 font-weight-bold">
                        {props.intl.formatMessage(message.alertTitle)}
                      </p>
                      <p className="col-12 p-0 m-0 pb-2">
                        {props.intl.formatMessage(message.alertDescription)}
                      </p>
                    </div>
                  </div>
                </Alert>
              </div>

              <div className="col-12 p-0 pt-2 pb-2 formBorder">
                <label className="col-12 p-0 font-weight-bold">
                  <FormattedMessage
                    id="marketing.forms.finitions"
                    defaultMessage="Finitions disponibles"
                  />
                </label>
                <div className="col-12 row">
                  <FormCheckbox
                    className="p-0 col-12 col-sm-6 pl-4"
                    checked={this.state.halStandard}
                    onChange={(e) =>
                      this.handleChangeFinitions(e, "halStandard")
                    }
                  >
                    <FormattedMessage
                      id="marketing.forms.finitions.halStandard"
                      defaultMessage="HAL Standard"
                    />
                  </FormCheckbox>

                  <FormCheckbox
                    className="p-0 col-12 col-sm-6 pl-4"
                    checked={this.state.orChimique}
                    onChange={(e) =>
                      this.handleChangeFinitions(e, "orChimique")
                    }
                  >
                    <FormattedMessage
                      id="marketing.forms.finitions.orChimique"
                      defaultMessage="Or Chimique"
                    />
                  </FormCheckbox>

                  <FormCheckbox
                    className="p-0 col-12 col-sm-6 pl-4"
                    checked={this.state.orElectro}
                    onChange={(e) => this.handleChangeFinitions(e, "orElectro")}
                  >
                    <FormattedMessage
                      id="marketing.forms.finitions.orElectro"
                      defaultMessage="Or Electro"
                    />
                  </FormCheckbox>

                  <FormCheckbox
                    className="p-0 col-12 col-sm-6 pl-4"
                    checked={this.state.argentChimique}
                    onChange={(e) =>
                      this.handleChangeFinitions(e, "argentChimique")
                    }
                  >
                    <FormattedMessage
                      id="marketing.forms.finitions.argentChimique"
                      defaultMessage="Argent Chimique"
                    />
                  </FormCheckbox>

                  <FormCheckbox
                    className="p-0 col-12 col-sm-6 pl-4"
                    checked={this.state.etainChimique}
                    onChange={(e) =>
                      this.handleChangeFinitions(e, "etainChimique")
                    }
                  >
                    <FormattedMessage
                      id="marketing.forms.finitions.etainChimique"
                      defaultMessage="Etain Chimique"
                    />
                  </FormCheckbox>

                  <FormCheckbox
                    className="p-0 col-12 col-sm-6 pl-4"
                    checked={this.state.halSN100C}
                    onChange={(e) => this.handleChangeFinitions(e, "halSN100C")}
                  >
                    <FormattedMessage
                      id="marketing.forms.finitions.halSN100C"
                      defaultMessage="HAL SN100C"
                    />
                  </FormCheckbox>

                  <FormCheckbox
                    className="p-0 col-12 col-sm-6 pl-4"
                    checked={this.state.halLeadFree}
                    onChange={(e) =>
                      this.handleChangeFinitions(e, "halLeadFree")
                    }
                  >
                    <FormattedMessage
                      id="marketing.forms.finitions.halLeadFree"
                      defaultMessage="HAL Lead free"
                    />
                  </FormCheckbox>

                  <FormCheckbox
                    className="p-0 col-12 col-sm-6 pl-4"
                    checked={this.state.ENEPIG}
                    onChange={(e) => this.handleChangeFinitions(e, "ENEPIG")}
                  >
                    <FormattedMessage
                      id="marketing.forms.finitions.ENEPIG"
                      defaultMessage="ENEPIG"
                    />
                  </FormCheckbox>

                  <FormCheckbox
                    className="p-0 col-12 col-sm-6 pl-4"
                    checked={this.state.sansFinitions}
                    onChange={(e) =>
                      this.handleChangeFinitions(e, "sansFinitions")
                    }
                  >
                    <FormattedMessage
                      id="marketing.forms.finitions.sansfinitions"
                      defaultMessage="Sans finitions"
                    />
                  </FormCheckbox>

                  <FormCheckbox
                    className="p-0 col-12 col-sm-6 pl-4"
                    checked={this.state.OSP}
                    onChange={(e) => this.handleChangeFinitions(e, "OSP")}
                  >
                    OSP
                  </FormCheckbox>
                </div>
                <Alert
                  className="mb-3"
                  open={this.state.alertFinitions}
                  theme="danger"
                >
                  <div className="row col-12 p-0 m-0">
                    <div className="row col-10 p-0 m-0">
                      <p className="col-12 p-0 m-0 pb-2 font-weight-bold">
                        {props.intl.formatMessage(message.alertTitle)}
                      </p>
                      <p className="col-12 p-0 m-0 pb-2">
                        {props.intl.formatMessage(message.alertDescription)}
                      </p>
                    </div>
                  </div>
                </Alert>
              </div>

              <div className="col-12 p-0 pt-2 pb-2 formBorder">
                <label className="col-12 p-0 font-weight-bold">
                  <FormattedMessage
                    id="marketing.forms.rigidité"
                    defaultMessage="Rigidité"
                  />
                </label>

                <FormCheckbox
                  className=" pl-4"
                  checked={this.state.rigide}
                  onChange={(e) => this.handleChangeRigidité(e, "rigide")}
                >
                  <FormattedMessage
                    id="marketing.forms.rigidité.rigide"
                    defaultMessage="Rigide"
                  />
                </FormCheckbox>

                <FormCheckbox
                  className=" pl-4"
                  checked={this.state.semiFlexible}
                  onChange={(e) => this.handleChangeRigidité(e, "semiFlexible")}
                >
                  <FormattedMessage
                    id="marketing.forms.rigidité.semiFlexible"
                    defaultMessage="Semi Flexible"
                  />
                </FormCheckbox>

                <FormCheckbox
                  className=" pl-4"
                  checked={this.state.flexible}
                  onChange={(e) => this.handleChangeRigidité(e, "flexible")}
                >
                  <FormattedMessage
                    id="marketing.forms.rigidité.flexible"
                    defaultMessage="Flexible"
                  />
                </FormCheckbox>
                <Alert
                  className="mb-3"
                  open={this.state.alertRigidités}
                  theme="danger"
                >
                  <div className="row col-12 p-0 m-0">
                    <div className="row col-10 p-0 m-0">
                      <p className="col-12 p-0 m-0 pb-2 font-weight-bold">
                        {props.intl.formatMessage(message.alertTitle)}
                      </p>
                      <p className="col-12 p-0 m-0 pb-2">
                        {props.intl.formatMessage(message.alertDescription)}
                      </p>
                    </div>
                  </div>
                </Alert>
              </div>

              <div className="col-12 p-0 pt-2 pb-2 formBorder">
                <label className="col-12 p-0 font-weight-bold">
                  <FormattedMessage
                    id="marketing.forms.via"
                    defaultMessage="Via"
                  />
                </label>

                <FormCheckbox
                  className=" pl-4"
                  checked={this.state.laser}
                  onChange={(e) => this.handleChangeVia(e, "laser")}
                >
                  <FormattedMessage
                    id="marketing.forms.via.laser"
                    defaultMessage="Mirco Via (Laser)"
                  />
                </FormCheckbox>

                <FormCheckbox
                  className=" pl-4"
                  checked={this.state.entérré}
                  onChange={(e) => this.handleChangeVia(e, "entérré")}
                >
                  <FormattedMessage
                    id="marketing.forms.via.entérré"
                    defaultMessage="Entérré"
                  />
                </FormCheckbox>
                <Alert
                  className="mb-3"
                  open={this.state.alertVia}
                  theme="danger"
                >
                  <div className="row col-12 p-0 m-0">
                    <div className="row col-10 p-0 m-0">
                      <p className="col-12 p-0 m-0 pb-2 font-weight-bold">
                        {props.intl.formatMessage(message.alertTitle)}
                      </p>
                      <p className="col-12 p-0 m-0 pb-2">
                        {props.intl.formatMessage(message.alertDescription)}
                      </p>
                    </div>
                  </div>
                </Alert>
              </div>

              <div className="col-12 p-0 pb-2 pt-2 formBorder">
                <label className="col-12 p-0 font-weight-bold">
                  <FormattedMessage
                    id="marketing.forms.couleurs"
                    defaultMessage="Couleurs du vernis"
                  />
                </label>
                {/* 'Toute','Black','Orange','Green','Blue','Red','White','Purple','Yellow','Matt green','Matt black','Transparent','Without varnish' */}
                <div className="col-12 row">
                  <FormCheckbox
                    className="p-0 col-12 col-sm-6 pl-4"
                    checked={this.state.noir}
                    onChange={(e) => this.handleChangeColor(e, "noir")}
                  >
                    <FormattedMessage
                      id="marketing.forms.color.noir"
                      defaultMessage="Noir"
                    />
                  </FormCheckbox>

                  <FormCheckbox
                    className="p-0 col-12 col-sm-6 pl-4"
                    checked={this.state.orange}
                    onChange={(e) => this.handleChangeColor(e, "orange")}
                  >
                    <FormattedMessage
                      id="marketing.forms.color.orange"
                      defaultMessage="Orange"
                    />
                  </FormCheckbox>

                  <FormCheckbox
                    className="p-0 col-12 col-sm-6 pl-4"
                    checked={this.state.vert}
                    onChange={(e) => this.handleChangeColor(e, "vert")}
                  >
                    <FormattedMessage
                      id="marketing.forms.color.vert"
                      defaultMessage="Vert"
                    />
                  </FormCheckbox>

                  <FormCheckbox
                    className="p-0 col-12 col-sm-6 pl-4"
                    checked={this.state.bleu}
                    onChange={(e) => this.handleChangeColor(e, "bleu")}
                  >
                    <FormattedMessage
                      id="marketing.forms.color.bleu"
                      defaultMessage="Bleu"
                    />
                  </FormCheckbox>

                  <FormCheckbox
                    className="p-0 col-12 col-sm-6 pl-4"
                    checked={this.state.rouge}
                    onChange={(e) => this.handleChangeColor(e, "rouge")}
                  >
                    <FormattedMessage
                      id="marketing.forms.color.rouge"
                      defaultMessage="Rouge"
                    />
                  </FormCheckbox>

                  <FormCheckbox
                    className="p-0 col-12 col-sm-6 pl-4"
                    checked={this.state.blanc}
                    onChange={(e) => this.handleChangeColor(e, "blanc")}
                  >
                    <FormattedMessage
                      id="marketing.forms.color.blanc"
                      defaultMessage="Blanc"
                    />
                  </FormCheckbox>

                  <FormCheckbox
                    className="p-0 col-12 col-sm-6 pl-4"
                    checked={this.state.violet}
                    onChange={(e) => this.handleChangeColor(e, "violet")}
                  >
                    <FormattedMessage
                      id="marketing.forms.color.violet"
                      defaultMessage="Violet"
                    />
                  </FormCheckbox>

                  <FormCheckbox
                    className="p-0 col-12 col-sm-6 pl-4"
                    checked={this.state.jaune}
                    onChange={(e) => this.handleChangeColor(e, "jaune")}
                  >
                    <FormattedMessage
                      id="marketing.forms.color.jaune"
                      defaultMessage="Jaune"
                    />
                  </FormCheckbox>

                  <FormCheckbox
                    className="p-0 col-12 col-sm-6 pl-4"
                    checked={this.state.vertMat}
                    onChange={(e) => this.handleChangeColor(e, "vertMat")}
                  >
                    <FormattedMessage
                      id="marketing.forms.color.vertMat"
                      defaultMessage="Vert Mat"
                    />
                  </FormCheckbox>

                  <FormCheckbox
                    className="p-0 col-12 col-sm-6 pl-4"
                    checked={this.state.noirMat}
                    onChange={(e) => this.handleChangeColor(e, "noirMat")}
                  >
                    <FormattedMessage
                      id="marketing.forms.color.noirMat"
                      defaultMessage="Noir Mat"
                    />
                  </FormCheckbox>

                  <FormCheckbox
                    className="p-0 col-12 col-sm-6 pl-4"
                    checked={this.state.transparent}
                    onChange={(e) => this.handleChangeColor(e, "transparent")}
                  >
                    <FormattedMessage
                      id="marketing.forms.color.transparent"
                      defaultMessage="Transparent"
                    />
                  </FormCheckbox>

                  <FormCheckbox
                    className="p-0 col-12 col-sm-6 pl-4"
                    checked={this.state.sansVernis}
                    onChange={(e) => this.handleChangeColor(e, "sansVernis")}
                  >
                    <FormattedMessage
                      id="marketing.forms.color.sansVernis"
                      defaultMessage="Sans Vernis"
                    />
                  </FormCheckbox>
                </div>
                <Alert
                  className="mb-3"
                  open={this.state.alertCouleurs}
                  theme="danger"
                >
                  <div className="row col-12 p-0 m-0">
                    <div className="row col-10 p-0 m-0">
                      <p className="col-12 p-0 m-0 pb-2 font-weight-bold">
                        {props.intl.formatMessage(message.alertTitle)}
                      </p>
                      <p className="col-12 p-0 m-0 pb-2">
                        {props.intl.formatMessage(message.alertDescription)}
                      </p>
                    </div>
                  </div>
                </Alert>
              </div>
              {/* <textarea className="col-12" id="couleursVernis" name="couleursVernis" 
                        onChange={e => this.setState({ couleursVernis: e.target.value })}
                        value={this.state.couleursVernis}
                        ></textarea> */}

              <div className="col-12 p-0 pt-2 pb-2 formBorder">
                <label className="col-12 font-weight-bold">
                  <FormattedMessage
                    id="marketing.forms.quantité"
                    defaultMessage="Quantité de carte maximum par commande"
                  />
                </label>
                <FormSelect
                  valid={this.state.quantitéIsValid}
                  invalid={this.state.quantitéIsInvalid}
                  size="sm"
                  className="mb-2"
                  onChange={(e) => (
                    this.setState({ quantité: e.target.value }),
                    e.target.value === "--"
                      ? this.handleChangeQuantitéValid("invalid")
                      : this.handleChangeQuantitéValid("valid")
                  )}
                >
                  <option
                    selected={this.state.mailSent === true ? "selected" : ""}
                    value={"--"}
                  >
                    --
                  </option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                  <option value="18">18</option>
                  <option value="19">19</option>
                  <option value="20">20</option>
                  <option value="21">21</option>
                  <option value="22">22</option>
                  <option value="23">23</option>
                  <option value="24">24</option>
                  <option value="25">25</option>
                  <option value="26">26</option>
                  <option value="27">27</option>
                  <option value="28">28</option>
                  <option value="29">29</option>
                  <option value="30">30</option>
                  <option value="31">31</option>
                  <option value="32">32</option>
                  <option value="33">33</option>
                  <option value="34">34</option>
                  <option value="35">35</option>
                  <option value="36">36</option>
                  <option value="37">37</option>
                  <option value="38">38</option>
                  <option value="39">39</option>
                  <option value="40">40</option>
                  <option value="41">41</option>
                  <option value="42">42</option>
                  <option value="43">43</option>
                  <option value="44">44</option>
                  <option value="45">45</option>
                  <option value="46">46</option>
                  <option value="47">47</option>
                  <option value="48">48</option>
                  <option value="49">49</option>
                  <option value="50">50</option>
                  <option value="51">51</option>
                  <option value="52">52</option>
                  <option value="53">53</option>
                  <option value="54">54</option>
                  <option value="55">55</option>
                  <option value="56">56</option>
                  <option value="57">57</option>
                  <option value="58">58</option>
                  <option value="59">59</option>
                  <option value="60">60</option>
                  <option value="61">61</option>
                  <option value="62">62</option>
                  <option value="63">63</option>
                  <option value="64">64</option>
                  <option value="65">65</option>
                  <option value="66">66</option>
                  <option value="67">67</option>
                  <option value="68">68</option>
                  <option value="69">69</option>
                  <option value="70">70</option>
                  <option value="71">71</option>
                  <option value="72">72</option>
                  <option value="73">73</option>
                  <option value="74">74</option>
                  <option value="75">75</option>
                  <option value="76">76</option>
                  <option value="77">77</option>
                  <option value="78">78</option>
                  <option value="79">79</option>
                  <option value="80">80</option>
                  <option value="81">81</option>
                  <option value="82">82</option>
                  <option value="83">83</option>
                  <option value="84">84</option>
                  <option value="85">85</option>
                  <option value="86">86</option>
                  <option value="87">87</option>
                  <option value="88">88</option>
                  <option value="89">89</option>
                  <option value="90">90</option>
                  <option value="91">91</option>
                  <option value="92">92</option>
                  <option value="93">93</option>
                  <option value="94">94</option>
                  <option value="95">95</option>
                  <option value="96">96</option>
                  <option value="97">97</option>
                  <option value="98">98</option>
                  <option value="99">99</option>
                  <option value="100">100</option>
                  <option value="110">110</option>
                  <option value="120">120</option>
                  <option value="130">130</option>
                  <option value="140">140</option>
                  <option value="150">150</option>
                  <option value="160">160</option>
                  <option value="170">170</option>
                  <option value="180">180</option>
                  <option value="190">190</option>
                  <option value="200">200</option>
                  <option value="210">210</option>
                  <option value="220">220</option>
                  <option value="230">230</option>
                  <option value="240">240</option>
                  <option value="250">250</option>
                  <option value="260">260</option>
                  <option value="270">270</option>
                  <option value="280">280</option>
                  <option value="290">290</option>
                  <option value="300">300</option>
                </FormSelect>
              </div>

              <div className="col-12 p-0 pt-2 pb-2 formBorder">
                <label className="col-12 font-weight-bold">
                  <FormattedMessage
                    id="marketing.forms.delais"
                    defaultMessage="Delais le plus court"
                  />
                </label>
                <FormSelect
                  valid={this.state.delaisIsValid}
                  invalid={this.state.delaisIsInvalid}
                  size="sm"
                  className="mb-2"
                  onChange={(e) => (
                    this.setState({ delais: e.target.value }),
                    e.target.value === "--"
                      ? this.handleChangeDelaisValid("invalid")
                      : this.handleChangeDelaisValid("valid")
                  )}
                >
                  <option
                    selected={this.state.mailSent === true ? "selected" : ""}
                    value="--"
                  >
                    --
                  </option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                  <option value="18">18</option>
                  <option value="19">19</option>
                  <option value="20">20</option>
                  <option value="21">21</option>
                  <option value="22">22</option>
                  <option value="23">23</option>
                  <option value="24">24</option>
                  <option value="25">25</option>
                  <option value="26">26</option>
                  <option value="27">27</option>
                  <option value="28">28</option>
                  <option value="29">29</option>
                  <option value="30">30</option>
                  <option value="31">31</option>
                </FormSelect>
              </div>

              <div className="col-12 p-0 pt-2 pb-2 formBorder">
                <label className="col-12 font-weight-bold">
                  <FormattedMessage
                    id="marketing.forms.description"
                    defaultMessage="Brève description de votre entreprise"
                  />
                </label>
                {/* <FormInput className="col-12" type="text" id="description" name="description"  placeholder="300 characters max"
                        value={this.state.description}
                        onChange={e => this.setState({ description: e.target.value })}
                    /> */}
                <FormInput
                  valid={this.state.descriptionIsValid}
                  invalid={this.state.descriptionIsInvalid}
                  className="col-12"
                  id="description"
                  name="description"
                  placeholder="300 characters max"
                  value={this.state.description}
                  onChange={(e) => (
                    this.setState({ description: e.target.value }),
                    e.target.value.length < 1 || e.target.value.length > 300
                      ? this.handleChangeDescriptionValid("invalid")
                      : this.handleChangeDescriptionValid("valid")
                  )}
                ></FormInput>
                <Alert
                  className="mb-3"
                  open={this.state.descriptionIsInvalid}
                  theme="danger"
                >
                  <div className="row col-12 p-0 m-0">
                    <div className="row col-10 p-0 m-0">
                      <p className="col-12 p-0 m-0 pb-2 font-weight-bold">
                        <FormattedMessage
                          id="marketing.forms.alert.descri"
                          defaultMessage="Alerte"
                        />
                      </p>
                      <p className="col-12 p-0 m-0 pb-2">
                        <FormattedMessage
                          className="col-12"
                          id="marketing.forms.alert.descri2"
                          defaultMessage="La description doit faire entre 50 et 300 caractères."
                        />
                      </p>
                    </div>
                  </div>
                </Alert>
              </div>

              <div className="col-12 p-0 pt-2 pb-2">
                <label className="col-12 font-weight-bold">
                  <FormattedMessage
                    id="marketing.forms.lien"
                    defaultMessage="Lien vers votre site internet"
                  />
                </label>
                <FormInput
                  valid={this.state.lienIsValid}
                  invalid={this.state.lienIsInvalid}
                  className="col-12"
                  type="url"
                  id="lienSite"
                  name="lienSite"
                  placeholder="http://exemple.com"
                  value={this.state.lienSite}
                  onChange={(e) => (
                    this.setState({ lienSite: e.target.value }),
                    e.target.value.length < 1
                      ? this.handleChangeLienValid("invalid")
                      : this.handleChangeLienValid("valid")
                  )}
                />
              </div>
              <FormCheckbox
                className="col-12 p-2 ml-3 "
                checked={this.state.conditionCheckBox}
                onChange={() => this.handleChangeCondition()}
              >
                <FormattedMessage
                  className="col-12"
                  id="marketing.forms.condition.checkbox1"
                  defaultMessage="Je confirme avoir lu et compris les"
                />
                <Link to="/privacy" className="linkToPrivacy">
                  <FormattedMessage
                    id="marketing.forms.condition.checkbox2"
                    defaultMessage="Terms et Conditions"
                  />
                </Link>
              </FormCheckbox>
              {console.log("condition: ", this.state.conditionCheckBox)}

              <Recaptcha
                sitekey="6LeqWAEVAAAAAJQqGirLFdJNXsgg4sl7Kq6snAuF"
                verifyCallback={() => this.captchaChanged()}
                onloadCallback={() => this.recaptchaLoaded()}
                className="captcha"
              />

              <Alert
                className="mb-3"
                open={this.state.alertSuccess}
                theme="success"
              >
                <div className="row col-12 p-0 m-0">
                  <div className="row col-10 p-0 m-0">
                    <p className="col-12 p-0 m-0 pb-2 font-weight-bold">
                      <FormattedMessage
                        id="marketing.forms.alert.titre"
                        defaultMessage="Email envoyé"
                      />
                    </p>
                    <p className="col-12 p-0 m-0 pb-2">
                      <FormattedMessage
                        className="col-12"
                        id="marketing.forms.alert.phrase"
                        defaultMessage="Un professionnel va se charger de prendre en compte votre demande de référencement dans peu de temps."
                      />
                    </p>
                    <p className="col-12 p-0 m-0 font-weight-bold">
                      <FormattedMessage
                        className="col-12"
                        id="marketing.forms.alert.merci"
                        defaultMessage="Merci pour votre confiance."
                      />
                    </p>
                  </div>
                  <div className="col-2 p-0 m-0 ">
                    <p className="float-right">
                      {this.state.timeUntilDismissed - this.state.countdown}
                    </p>
                  </div>
                </div>
              </Alert>
              <Alert
                className="mb-3"
                open={this.state.alertEchec}
                theme="danger"
              >
                <div className="row col-12 p-0 m-0">
                  <div className="row col-10 p-0 m-0">
                    <p className="col-12 p-0 m-0 pb-2 font-weight-bold">
                      <FormattedMessage
                        id="marketing.forms.alert.echec"
                        defaultMessage="Echec de l'envoi de l'Email"
                      />
                    </p>
                    <p className="col-12 p-0 m-0 pb-2">
                      <FormattedMessage
                        className="col-12"
                        id="marketing.forms.alert.echec2"
                        defaultMessage="Veuillez réessayer ultérieurement."
                      />
                    </p>
                  </div>
                  <div className="col-2 p-0 m-0 ">
                    <p className="float-right">
                      {this.state.timeUntilDismissed - this.state.countdown}
                    </p>
                  </div>
                </div>
              </Alert>

              <input
                className="col-6 offset-3 mb-3"
                type="submit"
                onClick={(e) => this.handleFormSubmit(e)}
                value={props.intl.formatMessage(message.buttons)}
              />
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(Forms);
