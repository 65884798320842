
import { injectIntl } from "react-intl";

let intl = {
  formatMessage: message => message.defaultMessage,
  set: false,
  messages: {}
};

export const InitIntl = injectIntl(props => {
  if (props.intl.locale !== intl.locale) {
    execInitEvents();
  }
  intl = props.intl;
  return props.children;
});

let initEvent = [];

export const execInitEvents = () => {
  initEvent.forEach(func => func(intl));
};

export const onIntlInit = func => {
  initEvent = [...initEvent, func];
  if (intl.locale) {
    func(intl);
  }
};

export const getIntl = () => intl;


export default intl;
