import React from "react";
import "styles/index.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { FormattedMessage, defineMessages, injectIntl } from "react-intl";
import axios from "axios";
import metaTag from "../utils/metaTag";
import { FormInput, Alert, Button } from "shards-react";
import Recaptcha from "react-recaptcha";

const API_PATH = "https://pcb-market.com/advertise.php";

class Advertise extends React.Component {
  constructor(props) {
    super(props);
    this.showAlertSuccess = this.showAlertSuccess.bind(this);
    this.showAlertEchec = this.showAlertEchec.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.clearInterval = this.clearInterval.bind(this);
    this.state = {
      nameIsValid: false,
      nameIsInvalid: false,
      emailIsValid: false,
      emailIsInvalid: false,
      companyNameIsValid: false,
      companyNameIsInvalid: false,
      countdown: 0,
      timeUntilDismissed: 10,
      alertEchec: false,
      alertSuccess: false,
    };
  }
  handleChangeNameValid(validité) {
    if (validité === "valid") {
      this.setState({ nameIsValid: true, nameIsInvalid: false });
    }
    if (validité === "invalid") {
      this.setState({ nameIsValid: false, nameIsInvalid: true });
    }
  }
  handleChangeEmailValid(validité) {
    if (validité === "valid") {
      this.setState({ emailIsValid: true, emailIsInvalid: false });
    }
    if (validité === "invalid") {
      this.setState({ emailIsValid: false, emailIsInvalid: true });
    }
  }
  handleChangeCompanyNameValid(validité) {
    if (validité === "valid") {
      this.setState({ companyNameIsValid: true, companyNameIsInvalid: false });
    }
    if (validité === "invalid") {
      this.setState({ companyNameIsValid: false, companyNameIsInvalid: true });
    }
  }

  captchaChanged() {
    this.setState({ captchaChanged: true });
  }

  recaptchaLoaded() {
    this.setState({ captchaLoaded: true });
  }

  //alerte
  showAlertSuccess() {
    this.clearInterval();
    this.setState({ alertSuccess: true, countdown: 0, timeUntilDismissed: 10 });
    this.interval = setInterval(this.handleTimeChange, 1000);
  }
  showAlertEchec() {
    this.clearInterval();
    this.setState({ alertEchec: true, countdown: 0, timeUntilDismissed: 10 });
    this.interval = setInterval(this.handleTimeChange, 1000);
  }

  handleTimeChange() {
    if (this.state.countdown < this.state.timeUntilDismissed - 1) {
      this.setState({
        ...this.state,
        ...{ countdown: this.state.countdown + 1 },
      });
      return;
    }

    this.setState({
      ...this.state,
      ...{ alertEchec: false, alertSuccess: false },
    });
    this.clearInterval();
  }

  clearInterval() {
    clearInterval(this.interval);
    this.interval = null;
  }

  // handleClickTest = (e) => {
  //   window.open("/pdf/mediaKit.pdf", "_self");
  // };

  handleFormSubmit = (e) => {
    e.preventDefault();
    if (
      this.state.name &&
      this.state.name !== "" &&
      this.state.email &&
      this.state.email !== "" &&
      this.state.companyName &&
      this.state.companyName !== ""
    ) {
      if (
        (this.state.captchaChanged && this.state.captchaLoaded) ||
        this.state.captchaLoaded !== (undefined || false)
      ) {
        axios({
          method: "post",
          url: `${API_PATH}`,
          headers: { "content-type": "application/json" },
          data: this.state,
        })
          .then((result) => {
            if (result.data.sent === true) {
              window.open("/pdf/mediaKit.pdf", "_self");
            } else {
              this.showAlertEchec();
            }
            this.setState({
              mailSent: result.data.sent,
              name: "",
              email: "",
              companyName: "",
              captchaChanged: false,
              captchaLoaded: false,
              nameIsValid: false,
              nameIsInvalid: false,
              emailIsValid: false,
              emailIsInvalid: false,
              companyNameIsValid: false,
              companyNameIsInvalid: false,
            });
          })
          .catch((error) => this.setState({ error: error.message }));
      } else {
        alert("Please prove that you are not a robot");
      }
    } else {
      if (
        this.state.name
          ? this.state.name.length < 1
          : this.state.name === undefined
      ) {
        this.handleChangeNameValid("invalid");
      }
      if (
        this.state.email
          ? this.state.email.length < 1
          : this.state.email === undefined
      ) {
        this.handleChangeEmailValid("invalid");
      }
      if (
        this.state.companyName
          ? this.state.companyName.length < 1
          : this.state.companyName === undefined
      ) {
        this.handleChangeCompanyNameValid("invalid");
      }

      alert("Fill in all the fields, please.");
    }
  };

  state = {
    name: "",
    email: "",
    companyName: "",
    captchaChanged: false,
    captchaLoaded: false,
  };

  render() {
    // console.clear();
    // console.log("###########################################");
    // console.log("- name: ", this.state.name);
    // console.log("- email: ", this.state.email);
    // console.log("- companyName: ", this.state.companyName);
    // console.log("- captcha changed: ", this.state.captchaChanged);
    // console.log("- captcha Loaded: ", this.state.captchaLoaded);
    // console.log("##########################################");

    const { props } = this;
    const message = defineMessages({
      title: {
        id: "marketing.advertise.titre",
        defaultMessage: "Faites votre pub sur PCB Market",
      },
      description: {
        id: "marketing.advertise.description",
        defaultMessage:
          "Faites de la pub pour votre entreprise sur ce comparateur de fabricant de circuit imprimé PCB",
      },
      keyWords: {
        id: "marketing.advertise.keywords",
        defaultMessage:
          "pcb, circuit imprimé, fabrication pcb, espace entreprise, formulaire, pcb électronique, pcb design",
      },
    });

    metaTag.setTitle({
      primary: "PCB-Market",
      secondary: props.intl.formatMessage(message.title),
    });

    metaTag.setDescription(props.intl.formatMessage(message.description));

    metaTag.setKeywords(props.intl.formatMessage(message.keyWords));

    return (
      <div className="advertisePage col-12 m-0 p-0 pb-5">
        <div className="px-4 px-md-0 col-12 offset-0 col-md-6 offset-md-3">
          <h1 className="text-center col-12 m-0 pt-5 pb-4 row">
            <span className="col-12 p-0 m-0 text-center">
              <FormattedMessage
                id="marketing.advertise.title"
                defaultMessage="Faites de la publicité pour votre entreprise sur"
              />
            </span>
            <span className="col-12 p-0 m-0 background">PCB-Market.com</span>
          </h1>
          <p className="">
            <FormattedMessage
              id="marketing.advertise.p1"
              defaultMessage="PCB Market référence plus d'une centaine de fournisseurs de PCB et PCBA d'Europe. Les leaders du marché de fabrication de carte électronique peuvent être comparés sur ce site."
            />
          </p>
          <p className="">
            <FormattedMessage
              id="marketing.advertise.p2"
              defaultMessage="Téléchargez ce kit de presse en PDF qui récapitule comment faire de la pub pour votre entreprise sur PCB Market"
            />
          </p>
          <div className="col-12 p-0 mediaKit">
            <h2 className="p-0 mb-0 py-2 text-center">
              <FormattedMessage
                id="marketing.advertise.kit.title"
                defaultMessage="Kit de presse"
              />
            </h2>

            <div className="col-12 p-3 forms">
              <label className="col-12 font-weight-bold p-0">
                <FormattedMessage
                  id="marketing.advertise.name"
                  defaultMessage="Votre nom"
                />
              </label>
              <FormInput
                valid={this.state.nameIsValid}
                invalid={this.state.nameIsInvalid}
                className="col-12"
                type="text"
                id="name"
                name="firstname"
                value={this.state.name}
                onChange={(e) => (
                  this.setState({ name: e.target.value }),
                  e.target.value.length < 1
                    ? this.handleChangeNameValid("invalid")
                    : this.handleChangeNameValid("valid")
                )}
              />
            </div>
            <div className="col-12 px-3 pb-3 forms">
              <label className="col-12 font-weight-bold p-0">
                <FormattedMessage
                  id="marketing.advertise.mail"
                  defaultMessage="Email"
                />
              </label>
              <FormInput
                valid={this.state.emailIsValid}
                invalid={this.state.emailIsInvalid}
                className="col-12"
                type="email"
                id="email"
                name="email"
                value={this.state.email}
                onChange={(e) => (
                  this.setState({ email: e.target.value }),
                  e.target.value.length < 1
                    ? this.handleChangeEmailValid("invalid")
                    : this.handleChangeEmailValid("valid")
                )}
              />
            </div>
            <div className="col-12 px-3 pb-3 forms">
              <label className="col-12 font-weight-bold p-0">
                <FormattedMessage
                  id="marketing.advertise.companyName"
                  defaultMessage="Nom de votre entreprise"
                />
              </label>
              <FormInput
                valid={this.state.companyNameIsValid}
                invalid={this.state.companyNameIsInvalid}
                className="col-12"
                type="text"
                id="companyName"
                name="companyName"
                value={this.state.companyName}
                onChange={(e) => (
                  this.setState({ companyName: e.target.value }),
                  e.target.value.length < 1
                    ? this.handleChangeCompanyNameValid("invalid")
                    : this.handleChangeCompanyNameValid("valid")
                )}
              />
            </div>
            <Recaptcha
              sitekey="6LeqWAEVAAAAAJQqGirLFdJNXsgg4sl7Kq6snAuF"
              verifyCallback={() => this.captchaChanged()}
              onloadCallback={() => this.recaptchaLoaded()}
              className="captcha"
            />
            <Alert
              className="m-3"
              open={this.state.alertSuccess}
              theme="success"
            >
              <div className="row col-12 p-0 m-0">
                <div className="row col-10 p-0 m-0">Succé</div>
                <div className="col-2 p-0 m-0 ">
                  <p className="float-right">
                    {this.state.timeUntilDismissed - this.state.countdown}
                  </p>
                </div>
              </div>
            </Alert>
            <Alert className="m-3" open={this.state.alertEchec} theme="danger">
              <div className="row col-12 p-0 m-0">
                <div className="row col-10 p-0 m-0">Echec</div>
                <div className="col-2 p-0 m-0 ">
                  <p className="float-right">
                    {this.state.timeUntilDismissed - this.state.countdown}
                  </p>
                </div>
              </div>
            </Alert>

            <div className="col-12 p-0 m-0 text-center">
              <Button
                href="/pdf/mediaKit.pdf"
                type="submit"
                onClick={(e) => this.handleFormSubmit(e)}
                className="button mx-5 mb-5 mt-2 py-3"
              >
                <FormattedMessage
                  id="marketing.advertise.button"
                  defaultMessage="Télécharger le kit de presse de PCB Market"
                />
              </Button>
              {/* <a onClick={this.handleClickTest} TARGET="_BLANK">
                test
              </a> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(Advertise);
