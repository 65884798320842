import { store } from "initStore";
import request from "superagent";
import allowedDomains from "./domains";
let activeRequest = false;

const actions = {
  // Get messages json file. Needs language and domains
  getMessages: (language, domainsAsked = []) => {
    let domains = [...domainsAsked];
    if (!domains.length) {
      domains = store.getState().intl.domains;
    }

    domains = domains.filter(d => allowedDomains.indexOf(d) >= 0);

    const loadedDomains = store.getState().intl.loadedDomains;
    const askedDomains = domains.filter(d => loadedDomains.indexOf(d) < 0);

    if (activeRequest) {
      return activeRequest;
    }
    // console.log(
    //   "Intl actions getMessages",
    //   language,
    //   domainsAsked,
    //   loadedDomains,
    //   askedDomains
    // );

    activeRequest = Promise.all(
      askedDomains.map(domain =>
        request("get", "/locales/" + domain + "/" + language + ".json").catch(
          err => {}
        )
      )
    ).then(res => {
      let nMessages = {};
      res.map(r => (nMessages = Object.assign({}, nMessages, r.body || {})));
      if (language === "fr") {
        actions.emptyMessages();
      } else {
        actions.setMessages(nMessages);
      }
      actions.setDomains(domains);
      activeRequest = false;
     // console.log("Intl acitons getMessages res", nMessages);
      return nMessages;
    });

    return activeRequest;

    // return requestsActions.add('intl/getMessages', )
  },
  setMessages: messages =>
    store.dispatch({
      type: "intl/SET_MESSAGES",
      messages
    }),
  emptyMessages: () =>
    store.dispatch({
      type: "intl/EMPTY_MESSAGES"
    }),
  askDomains: domains =>
    store.dispatch({
      type: "intl/ASK_DOMAINS",
      domains
    }),
  setDomains: domains =>
    store.dispatch({
      type: "intl/SET_DOMAINS",
      domains
    }),
  emptyLoadedDomains: () => {
   // console.log("intl actions getMessages emptyLoadedDomains");
    return store.dispatch({
      type: "intl/EMPTY_LOADED_DOMAINS"
    });
  },
  // loadMessages: (language, domains) => {
  //   actions.getMessages(language, domains)
  //     .then(messages => store.dispatch({
  //       type: 'intl/SET_LANGUAGE',
  //       messages,
  //       domains
  //     }))
  // },
  setLanguage: language =>
    store.dispatch({
      type: "intl/SET_LANGUAGE",
      language
    })
  // setLanguage: language => {
  //   console.log('intl actions setLanguage', language)
  //   actions.emptyLoadedDomains()
  //   actions.getMessages(language, store.getState().intl.domains)
  //   .then(messages => store.dispatch({
  //     type: 'intl/SET_LANGUAGE',
  //     language
  //   }))}
  //   actions.getMessages(language)
  //     .then(messages => store.dispatch({
  //         type: 'intl/SET_LANGUAGE',
  //         messages,
  //         language
  //       })
  //     )
  //
  // }
};

export default actions;
