const entreprises = [
  {
    id: "1",
    name: "Emsproto",
    logo: "../images/img/logo/logo-ems-proto.png",
    type: ["pcba"],
    langue: ["FR", "EN", "ES", "IT", "PT", "NL", "DE"],
    nombreCouche: "48",
    finitions: [
      "HAL standard",
      "HAL lead free",
      "Chemical gold",
      "Gold electro",
      "Chemical silver",
      "Chemical tin",
      "OSP",
      "HAL-SN100C",
      "Without finish",
    ],
    materiaux: [
      "CEM1",
      "CEM2",
      "CEM3",
      "Duroid",
      "FR-1",
      "FR-2",
      "FR-3",
      "FR-4",
      "FR-5",
      "Kapton",
      "Polyester",
      "Polyethylene",
      "Polyflon",
      "Polyimide",
      "PTFE",
      "Pyralux",
      "Teflon",
      "XPC",
    ],
    rigidité: ["Rigide", "Semi flexible", "Flexible"],
    via: ["Laser", "Buried"],
    couleursVernis: [
      "Black",
      "Orange",
      "Green",
      "Blue",
      "Red",
      "White",
      "Purple",
      "Yellow",
      "Matt green",
      "Matt black",
      "Transparent",
      "Without varnish",
    ],
    quantité: 60,
    delais: 3,
    description: {
      fr:
        "Commandez votre Prototype Electronique en Ligne. Assemblage composants CMS, traditionnel et Press-Fit. Prise en charge immédiate sous 2 heures. Livraison en 5 jours ouvrés.",
      en:
        "Order your Electronic Prototype Online. Assembly of CMS, traditional and Press-Fit components. Immediate pick-up within 2 hours. Delivery in 5 working days.",
      it:
        "Ordina il tuo prototipo elettronico online. Assemblaggio di componenti CMS, tradizionali e Press-Fit. Ritiro immediato entro 2 ore. Consegna in 5 giorni lavorativi.",
      de:
        "Bestellen Sie Ihren elektronischen Prototyp online. Montage von CMS-, traditionellen und Press-Fit-Komponenten. Sofortige Abholung innerhalb von 2 Stunden. Lieferung in 5 Werktagen.",
      es:
        "Solicite su prototipo electrónico en línea. Montaje de componentes CMS, tradicionales y Press-Fit. Recogida inmediata en 2 horas. Entrega en 5 días hábiles.",
      nl:
        "Bestel uw elektronische prototype online. Montage van CMS, traditionele en Press-Fit componenten. Onmiddellijk ophalen binnen 2 uur. Levering binnen 5 werkdagen.",
    },
    lienInfo: "",
    lienSite: "https://www.emsproto.com/?utm=pcb-market",
    projet: false,
    imageProjet: "..//images/img/logo/logo-ems-proto.png",
    titreProjet: "Titre du projet",
    descriptionProjet: "Une description du projet",
    prixProjet: "12.75 €",
  },

  {
    id: "2",
    name: "Safe-pcb",
    logo: "../images/img/logo/logo-safe-pcb.png",
    type: ["pcb"],
    langue: ["FR", "EN", "ES", "IT", "PT", "NL", "DE"],
    nombreCouche: "64",
    finitions: [
      "HAL standard",
      "Chemical gold",
      "Gold electro",
      "Chemical silver",
      "Chemical tin",
      "OSP",
      "HAL-SN100C",
      "Without finish",
    ],
    materiaux: ["CEM3", "FR-4", "Polyimide", "Rogers"],
    rigidité: ["Rigide", "Semi flexible", "Flexible"],
    via: ["Laser", "Buried"],
    couleursVernis: [
      "Black",
      "Green",
      "Blue",
      "Red",
      "White",
      "Purple",
      "Yellow",
      "Matt green",
      "Matt black",
      "Transparent",
      "Without varnish",
    ],
    description: {
      fr:
        "Safe-PCB, le fabricant de circuits imprimés fiable et réactif pour prototypes et séries en délais courts.",
      en:
        "Safe-PCB, manufacturer of reliable and reactive printed circuits for prototypes and series with short lead times.",
      it:
        "Safe-PCB, il produttore di circuiti stampati affidabili e reattivi per prototipi e serie in tempi brevi.",
      de:
        "Safe-PCB, der zuverlässige und reaktionsschnelle Leiterplattenhersteller für Prototypen und Serien mit kurzen Vorlaufzeiten.",
      es:
        "Safe-PCB, el fabricante de circuitos impresos confiables y sensibles para prototipos y series en cortos plazos de entrega.",
      nl:
        "Safe-PCB, de fabrikant van betrouwbare en responsieve printplaten voor prototypes en series in korte levertijden.",
    },
    lienInfo: "",
    lienSite: "https://www.safe-pcb.com/?utm=pcb-market",
    projet: false,
    imageProjet: "",
    titreProjet: "Titre du projet",
    descriptionProjet: "Une description du projet",
    prixProjet: "12.99 €",
  },
  {
    id: "3",
    name: "Wurth",
    logo: "../images/img/logo/logo-wurth.png",
    type: ["pcb"],
    langue: ["FR", "EN", "ES", "IT", "PT", "NL", "DE"],
    nombreCouche: "16",
    finitions: [
      "Chemical nickel",
      "Chemical gold",
      "HAL lead free",
      "Chemical tin",
    ],
    materiaux: ["FR-4"],
    rigidité: ["Rigide", "Flexible"],
    via: ["Laser"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "Leader mondial du marché de la distribution de matériaux de fixation, Wurth est une entreprise familiale qui tourne ses actions vers l'avenir",
      en:
        "World leader in the distribution of fastening materials, Wurth is a family business that turns its actions to the future",
      it:
        "Leader mondiale nella distribuzione di materiali di fissaggio, Wurth è un'azienda a conduzione familiare che trasforma le sue azioni verso il futuro",
      de:
        "Wurth ist ein weltweit führendes Unternehmen im Vertrieb von Befestigungsmaterialien und ein Familienunternehmen, das sein Handeln auf die Zukunft ausrichtet",
      es:
        "Líder mundial en la distribución de materiales de fijación, Wurth es una empresa familiar que dirige sus acciones hacia el futuro.",
      nl:
        "Wereldleider in de distributie van bevestigingsmaterialen, Wurth is een familiebedrijf dat haar acties op de toekomst richt",
    },
    lienInfo: "",
    lienSite: "https://www.wedirekt.com/?utm=pcb-market",
    projet: false,
    imageProjet: "",
    titreProjet: "Titre du projet",
    descriptionProjet: "Une description du projet",
    prixProjet: "13.02 €",
  },
  {
    id: "4",
    name: "Multi-cb",
    logo: "../images/img/logo/logo-multi-cb.png",
    type: ["pcb"],
    langue: ["EN", "DE"],
    nombreCouche: "28",
    finitions: [
      "HAL standard",
      "HAL lead free",
      "Chemical gold",
      " Galvanic gold",
      "Chemical silver",
      "Chemical tin",
      " Copper",
    ],
    materiaux: ["FR-4", "Rogers"],
    rigidité: ["Rigide", "Semi flexible", "Flexible"],
    via: ["Buried"],
    couleursVernis: ["Green", "Black", "Blue", "White", "Red"],
    description: {
      fr:
        "Multi Circuit Boards est l'un des principaux fournisseurs européens de cartes PCB / multicouches de haute technologie à bas prix avec jusqu'à 48 couches, livraison en 5 jours ouvrés.",
      en:
        "Multi Circuit Boards is one of the leading European suppliers of low-cost high-tech PCB / multilayer boards with up to 48 layers, delivery in 5 working days.",
      it:
        "Multi Circuit Boards è uno dei principali fornitori europei di schede PCB / multistrato high-tech a basso costo con un massimo di 48 strati, consegna in 5 giorni lavorativi.",
      de:
        "Multi Circuit Boards ist einer der führenden europäischen Anbieter von kostengünstigen High-Tech-Leiterplatten / Multilayer-Karten mit bis zu 48 Schichten und einer Lieferung innerhalb von 5 Arbeitstagen.",
      es:
        "Multi Circuit Boards es uno de los principales proveedores europeos de placas PCB / multicapa de alta tecnología y bajo costo con hasta 48 capas, entrega en 5 días hábiles.",
      nl:
        "Multi Circuit Boards is een van de toonaangevende Europese leveranciers van goedkope hightech PCB / meerlagige boards met maximaal 48 lagen, levering binnen 5 werkdagen.",
    },
    lienInfo: "",
    lienSite: "https://www.multi-circuit-boards.eu/index.html/?utm=pcb-market",
    projet: false,
    imageProjet: "",
    titreProjet: "Titre du projet",
    descriptionProjet: "Une description du projet",
    prixProjet: "14.49 €",
  },
  {
    id: "5",
    name: "Proto-electronics",
    logo: "../images/img/logo/logo-proto-electronics.png",
    type: ["pcba"],
    langue: ["EN", "IT", "DE"],
    nombreCouche: "16",
    quantité: 250,
    delais: 5,
    finitions: [
      "Chemical gold",
      "Gold electro",
      "Chemical silver",
      "Chemical tin",
      "OSP",
      "ENEPIG",
    ],
    materiaux: ["FR-4", "Rogers", "CEM-3", "Polyimide"],
    rigidité: ["Rigide"],
    via: ["Laser", "Buried"],
    couleursVernis: ["Green", "Shiny black", "Black", "Red", "Blue", "White"],
    description: {
      fr:
        "Spécialiste européen du prototypage de carte électronique câblée, Proto-Electronics a digitalisé l’ensemble du processus de commande pour vous livrer dans des délais express. Configurez votre PCB assemblé en ligne, générez un devis et passez commande.",
      en:
        "A European specialist in wired electronic card prototyping, Proto-Electronics has digitized the entire order process to deliver to you on time. Configure your assembled PCB online, generate a quote and place an order.",
      it:
        "Specialista europeo nella prototipazione di schede elettroniche cablate, Proto-Electronics ha digitalizzato l'intero processo di ordinazione per consegnarti entro un termine preciso. Configura online il tuo PCB assemblato, genera un preventivo ed effettua un ordine.",
      de:
        "Proto-Electronics, ein europäischer Spezialist für das Prototyping von kabelgebundenen elektronischen Karten, hat den gesamten Bestellvorgang digitalisiert, um Sie innerhalb einer ausdrücklichen Frist zu beliefern. Konfigurieren Sie Ihre montierte Leiterplatte online, erstellen Sie ein Angebot und geben Sie eine Bestellung auf.",
      es:
        "Un especialista europeo en prototipos de tarjetas electrónicas con cable, Proto-Electronics ha digitalizado todo el proceso de pedido para entregarlo a tiempo. Configure su PCB ensamblado en línea, genere una cotización y haga un pedido.",
      nl:
        "Proto-Electronics, een Europese specialist in prototyping van bedrade elektronische kaarten, heeft het hele bestelproces gedigitaliseerd om u op tijd te leveren. Configureer uw geassembleerde print online, genereer een offerte en plaats een bestelling.",
    },
    lienInfo: "",
    lienSite: "https://www.proto-electronics.com/?utm=pcb-market",
    projet: false,
    imageProjet: "",
    titreProjet: "Titre du projet",
    descriptionProjet: "Une description du projet",
    prixProjet: "14.51 €",
  },
  {
    id: "6",
    name: "PCB-Prototype",
    logo: "../images/img/logo/logo-pcb-prototype.png",
    type: ["pcb"],
    langue: ["FR", "EN", "DE"],
    nombreCouche: "14",
    finitions: [
      "HAL lead free",
      "Chemical gold",
      "Chemical silver",
      "Chemical tin",
      "OSP",
      "ENEPIG",
      "Without finish",
    ],
    materiaux: ["FR-4", "Rogers"],
    rigidité: ["Rigide", "Flexible"],
    via: ["Laser", "Buried"],
    couleursVernis: [
      "Green",
      "Matt green",
      "Black",
      "Matt black",
      "White",
      "Red",
      "Blue",
      "Without varnish",
    ],
    description: {
      fr:
        "Spécialisée dans la production de circuits imprimés, PCB-Prototype est en mesure de fournir rapidement un prototype et des cartes en volume faible. Pour profiter de nos services et de notre expertise, il vous suffit d’effectuer votre commande en ligne",
      en:
        "Specialized in the production of printed circuits, PCB-Prototype is able to quickly supply a prototype and low volume boards. To take advantage of our services and expertise, simply place your order online",
      it:
        "Specializzato nella produzione di circuiti stampati, PCB-Prototype è in grado di fornire rapidamente un prototipo e schede a basso volume. Per usufruire dei nostri servizi e competenze, è sufficiente effettuare l'ordine online",
      de:
        "PCB-Prototype ist auf die Herstellung von Leiterplatten spezialisiert und kann schnell einen Prototyp und Platinen mit geringem Volumen liefern. Um unsere Dienstleistungen und unser Fachwissen zu nutzen, geben Sie Ihre Bestellung einfach online auf",
      es:
        "Especializado en la producción de circuitos impresos, PCB-Prototype puede suministrar rápidamente un prototipo y placas de bajo volumen. Para aprovechar nuestros servicios y experiencia, simplemente haga su pedido en línea",
      nl:
        "Gespecialiseerd in de productie van printplaten, is PCB-Prototype in staat om snel een prototype en low volume boards te leveren. Om te profiteren van onze diensten en expertise, plaatst u gewoon uw bestelling online",
    },
    lienInfo: "",
    lienSite: "https://www.pcbprototype.com/?utm=pcb-market",
    projet: false,
    imageProjet: "",
    titreProjet: "Titre du projet",
    descriptionProjet: "Une description du projet",
    prixProjet: "14.78 €",
  },
  {
    id: "7",
    name: "Beta-Layout",
    logo: "../images/img/logo/logo-beta-layout.png",
    type: ["pcb", "pcba"],
    langue: ["FR", "EN", "IT", "ZA", "NL", "DE"],
    nombreCouche: "6",
    quantité: 50,
    delais: 14,
    finitions: ["HAL lead free", "Chemical gold", "Chemical nickel"],
    materiaux: ["FR-4"],
    rigidité: ["Rigide", "Semi flexible", "Flexible"],
    via: ["Buried"],
    couleursVernis: [
      "Green",
      "Matt green",
      "Black",
      "Matt black",
      "White",
      "Red",
      "Blue",
      "Without varnish",
    ],
    description: {
      fr:
        "Beta Layout propose un service complet pou la production de vos PCBs avec une haute qualité industrielle et une fiabilité maximale.",
      en:
        "Beta Layout offers a complete service for the production of your PCBs with high industrial quality and maximum reliability.",
      it:
        "Beta Layout offre un servizio completo per la produzione di PCB con alta qualità industriale e massima affidabilità.",
      de:
        "Beta Layout bietet einen kompletten Service für die Herstellung Ihrer Leiterplatten mit hoher Industriequalität und maximaler Zuverlässigkeit.",
      es:
        "Beta Layout ofrece un servicio completo para la producción de sus PCB con alta calidad industrial y máxima confiabilidad.",
      nl:
        "Beta Layout biedt een complete service voor de productie van uw printplaten met een hoge industriële kwaliteit en maximale betrouwbaarheid.",
    },
    lienInfo: "",
    lienSite: "https://fr.beta-layout.com/pcb/?utm=pcb-market",
    projet: false,
    imageProjet: "",
    titreProjet: "",
    descriptionProjet: "une description du projet",
    prixProjet: "14.99 €",
  },
  {
    id: "8",
    name: "Euro-circuits",
    logo: "../images/img/logo/logo-euro-circuits.png",
    type: ["pcb", "pcba"],
    langue: ["FR", "EN", "IT", "ZA", "NL", "DE", "PT", "ES"],
    nombreCouche: "16",
    quantité: 50,
    delais: 7,
    finitions: ["HAL lead free", "Chemical gold", "Chemical silver", " ENIG"],
    materiaux: ["FR-4"],
    rigidité: ["Rigide", "Semi flexible", "Flexible"],
    via: ["Buried"],
    couleursVernis: [
      "Green",
      "Black",
      "White",
      "Red",
      "Blue",
      "Without varnish",
      "Transparent",
    ],
    description: {
      fr:
        "Chez Eurocircuits, des fabricants et des assemBleurs spécialisés dans le prototypage de petites séries de PCB s'engagent à fournir un service électronique fiable",
      en:
        "At Eurocircuits, manufacturers and assemblers specialized in the prototyping of small series of PCBs undertake to provide a reliable electronic service",
      it:
        "In Eurocircuits, produttori e assemblatori specializzati nella prototipazione di piccole serie di PCB si impegnano a fornire un servizio elettronico affidabile",
      de:
        "Bei Eurocircuits verpflichten sich Hersteller und Monteure, die auf das Prototyping kleiner Leiterplatten spezialisiert sind, einen zuverlässigen elektronischen Service",
      es:
        "En Eurocircuits, los fabricantes y ensambladores especializados en la creación de prototipos de pequeñas series de PCB se comprometen a proporcionar un servicio electrónico confiable",
      nl:
        "Bij Eurocircuits verbinden fabrikanten en assembleurs die gespecialiseerd zijn in de prototyping van kleine series PCB's zich ertoe een betrouwbare elektronische service te bieden",
    },
    lienInfo: "",
    lienSite: "https://www.eurocircuits.com/?utm=pcb-market",
    projet: false,
    imageProjet: "",
    titreProjet: "Titre du projet",
    descriptionProjet: "Une description du projet",
    prixProjet: "17.30 €",
  },
  {
    id: "9",
    name: "My-proto",
    logo: "../images/img/logo/logo-my-proto.png",
    type: ["pcba"],
    quantité: 50,
    delais: 5,
    langue: ["FR", "EN", "NL", "DE"],
    nombreCouche: "16",
    finitions: [
      "HAL standard",
      "Chemical gold",
      "Chemical silver",
      "Chemical tin",
      "ENEPIG",
    ],
    materiaux: ["FR-4", "Rogers", "CEM-3", "Polyimide"],
    rigidité: ["Rigide"],
    via: [],
    couleursVernis: [
      "Green",
      "Matt green",
      "Black",
      "Matt black",
      "White",
      "Red",
      "Blue",
      "Without varnish",
    ],
    description: {
      fr:
        "MyProto est une plate-forme facile d’utilisation qui vous permet d’obtenir un devis, d’optimiser et de concevoir de façon compétitive votre prototype avec des délais de production rapides.",
      en:
        "MyProto is an easy-to-use platform that allows you to get a quote, optimize and competitively design your prototype with fast production times.",
      it:
        "MyProto è una piattaforma di facile utilizzo che ti consente di ottenere un preventivo, ottimizzare e progettare in modo competitivo il tuo prototipo con tempi di produzione rapidi.",
      de:
        "MyProto ist eine benutzerfreundliche Plattform, mit der Sie ein Angebot einholen, Ihren Prototyp mit schnellen Produktionszeiten optimieren und wettbewerbsfähig gestalten können.",
      es:
        "MyProto es una plataforma fácil de usar que le permite obtener un presupuesto, optimizar y diseñar de manera competitiva su prototipo con tiempos de producción rápidos.",
      nl:
        "MyProto is een gebruiksvriendelijk platform waarmee u een offerte kunt krijgen, uw prototype kunt optimaliseren en op competitieve wijze kunt ontwerpen met snelle productietijden.",
    },
    lienInfo: "",
    lienSite: "https://myproto.eu/?utm=pcb-market",
    projet: false,
    imageProjet: "",
    titreProjet: "Titre du projet",
    descriptionProjet: "Une description du projet",
    prixProjet: "18.39 €",
  },
  {
    id: "10",
    name: "ACB",
    logo: "../images/img/logo/logo-acb.png",
    type: ["pcb"],
    quantité: 50,
    delais: 10,
    langue: ["FR", "EN", "ES", "DE"],
    nombreCouche: "50",
    finitions: [
      "HAL standard",
      "Chemical gold",
      "Chemical silver",
      "Chemical tin",
      "ENEPIG",
    ],
    materiaux: ["FR-4", "Polyimide"],
    rigidité: ["Rigide", "Semi flexible"],
    via: ["Laser", "Buried"],
    couleursVernis: ["Green", "Black", "White"],
    description: {
      fr:
        "ACB est l’un des leader Européen de la fabrication de circuits imprimés, spécialisé dans les petites et moyennes séries pour les technologies multicouches",
      en:
        "ACB is one of the leading European manufacturers of printed circuits, specializing in small and medium series for multilayer technologies",
      it:
        "ACB è uno dei principali produttori europei di circuiti stampati, specializzato in piccole e medie serie per tecnologie multistrato",
      de:
        "ACB ist einer der führenden europäischen Hersteller von gedruckten Schaltungen, der sich auf kleine und mittlere Serien für Mehrschichttechnologien spezialisiert hat",
      es:
        "ACB es uno de los principales fabricantes europeos de circuitos impresos, especializado en series pequeñas y medianas para tecnologías multicapa.",
      nl:
        "ACB is een van de toonaangevende Europese fabrikanten van gedrukte schakelingen, gespecialiseerd in kleine en middelgrote series voor meerlagige technologieën",
    },
    lienInfo: "",
    lienSite: "https://www.acb.be/?utm=pcb-market",
    projet: false,
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },

  {
    id: "11",
    name: "Cimulec",
    logo: "../images/img/logo/logo-cimulec.png",
    type: ["pcb"],
    langue: ["FR", "EN", "DE"],
    nombreCouche: "30",
    quantité: "50",
    delais: 10,
    finitions: [
      " Chemical godl",
      "Chemical silver",
      "Chemical tin",
      " ENIG",
      " HASL",
      "Chemical nickel",
    ],
    materiaux: ["FR-4", "Polyimide"],
    rigidité: ["Rigide", "Semi flexible"],
    via: ["Buried", "Laser"],
    couleursVernis: ["Green", "Black", "White"],
    description: {
      fr:
        "Cimulec est qualifié pour tous vos projets aéronotiques, militaires et spaciaux, du prototype à la série. Le groupe est partenaire des principaux équipementiers Européens pour de solutions technologiques adaptées et innovantes",
      en:
        "Cimulec is qualified for all your aeronotic, military and space projects, from prototype to series. The group is a partner of the main European equipment manufacturers for adapted and innovative technological solutions",
      it:
        "Cimulec è qualificato per tutti i tuoi progetti aeronotici, militari e spaziali, dal prototipo alla serie. Il gruppo è partner dei principali produttori di apparecchiature europei per soluzioni tecnologiche adattate e innovative",
      de:
        "Cimulec ist für alle Ihre Luftfahrt-, Militär- und Weltraumprojekte qualifiziert, vom Prototyp bis zur Serie. Die Gruppe ist Partner der wichtigsten europäischen Gerätehersteller für angepasste und innovative technologische Lösungen",
      es:
        "Cimulec está calificado para todos sus proyectos aeronóticos, militares y espaciales, desde prototipos hasta series. El grupo es socio de los principales fabricantes de equipos europeos para soluciones tecnológicas innovadoras y adaptadas.",
      nl:
        "Cimulec is gekwalificeerd voor al uw aeronotische, militaire en ruimteprojecten, van prototype tot serie. De groep is partner van de belangrijkste Europese fabrikanten van apparatuur voor aangepaste en innovatieve technologische oplossingen",
    },
    lienInfo: "",
    lienSite: "http://www.cimulecgroup.com/?utm=pcb-market",
    projet: false,
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "12",
    name: "Cirly",
    logo: "../images/img/logo/logo-cirly.png",
    type: ["pcb"],
    langue: ["FR", "EN", "DE"],
    nombreCouche: "12",
    quantité: "50",
    delais: 10,
    finitions: [
      "HAL lead free",
      "Chemical gold",
      "Gold electro",
      "Chemical silver",
      "Chemical tin",
      "Chemical nickel",
    ],
    materiaux: ["FR-4"],
    rigidité: ["Rigide"],
    via: ["Buried"],
    couleursVernis: ["Green", "Black", "White"],
    description: {
      fr:
        "CIRLY est la référence pour la fabrication rapide de circuits imprimés prototypes et petites séries. Une équipe compétente, un outil industriel de pointe, une offre produit / service bien identifiée :CIRLY est à vos côtés pour la réussite de vos projets électroniques.",
      en:
        "CIRLY is the benchmark for the rapid production of prototype printed circuits and small series. A competent team, an advanced industrial tool, a well-identified product / service offer: CIRLY is at your side for the success of your electronic projects.",
      it:
        "CIRLY è il punto di riferimento per la produzione rapida di prototipi di circuiti stampati e piccole serie. Un team competente, uno strumento industriale avanzato, un'offerta di prodotto / servizio ben identificata: CIRLY è al tuo fianco per il successo dei tuoi progetti elettronici.",
      de:
        "CIRLY ist der Maßstab für die schnelle Produktion von Prototypen von gedruckten Schaltungen und Kleinserien. Ein kompetentes Team, ein fortschrittliches industrielles Werkzeug, ein gut identifiziertes Produkt- / Serviceangebot: CIRLY steht Ihnen für den Erfolg Ihrer elektronischen Projekte zur Seite.",
      es:
        "CIRLY es el punto de referencia para la producción rápida de prototipos de circuitos impresos y series pequeñas. Un equipo competente, una herramienta industrial avanzada, una oferta de producto / servicio bien identificada: CIRLY está a su lado para el éxito de sus proyectos electrónicos.",
      nl:
        "CIRLY is de maatstaf voor de snelle productie van prototype-gedrukte schakelingen en kleine series. Een bekwaam team, een geavanceerd industrieel hulpmiddel, een goed geïdentificeerd product / dienstenaanbod: CIRLY staat aan uw zijde voor het succes van uw elektronische projecten.",
    },
    lienInfo: "",
    lienSite: "https://www.cirly.com/?utm=pcb-market",
    projet: false,
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "13",
    name: "Zebra PCB",
    logo: "../images/img/logo/logo-zebra.png",
    type: ["pcb", "pcba"],
    langue: ["FR", "EN", "DE", "CZ"],
    nombreCouche: "48",
    quantité: 50,
    delais: 14,
    finitions: [
      "HAL lead free",
      "Chemical gold",
      "Gold electro",
      "Chemical silver",
      "Chemical tin",
      " ENIG",
    ],
    materiaux: ["FR-4", "Rogers", "PTFE", "Polyimide"],
    rigidité: ["Rigide", "Semi flexible"],
    via: ["Buried", "Laser"],
    couleursVernis: ["Green", "Black", "White", "Red", "Blue", "Matt black"],
    description: {
      fr:
        "Zebra PCB est un fournisseur unique de cartes de circuits imprimés et assemblés. Ils donnent vie à vos idées de conception en une seule étape.",
      en:
        "Zebra PCB is a sole supplier of printed and assembled circuit boards. They bring your design ideas to life in one step.",
      it:
        "Zebra PCB è un fornitore unico di circuiti stampati e assemblati. Danno vita alle tue idee di design in un solo passaggio.",
      de:
        "Zebra PCB ist ein einzigartiger Anbieter von Leiterplatten und montierten Leiterplatten. Sie erwecken Ihre Designideen in einem Schritt zum Leben.",
      es:
        "Zebra PCB es un proveedor exclusivo de placas de circuito impreso y ensamblado. Traen sus ideas de diseño a la vida en un solo paso.",
      nl:
        "Zebra PCB is de enige leverancier van gedrukte en geassembleerde printplaten. Ze brengen uw ontwerpideeën in één stap tot leven.",
    },
    lienInfo: "",
    lienSite: "https://www.zebrapcb.com/?utm=pcb-market",
    projet: false,
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "14",
    name: "Elvia PCB",
    logo: "../images/img/logo/logo-elvia.png",
    type: ["pcb"],
    langue: ["FR", "EN"],
    nombreCouche: "99",
    quantité: "50",
    delais: 10,
    finitions: [],
    materiaux: ["FR-4", "Polyimide"],
    rigidité: ["Rigide", "Semi flexible", "Flexible"],
    via: ["All"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "Le groupe Elvia PCB est le premier fabricant français de circuits imprimés et le cinquième au niveau européen. Le groupe, dispose d’outils industriels parmi les plus sophistiqués d’Europe.",
      en:
        "The Elvia PCB group is the leading French manufacturer of printed circuits and the fifth at European level. The group has some of the most sophisticated industrial tools in Europe.",
      it:
        "Il gruppo PCB Elvia è il principale produttore francese di circuiti stampati e il quinto a livello europeo. Il gruppo ha alcuni degli strumenti industriali più sofisticati in Europa.",
      de:
        "Die Elvia PCB-Gruppe ist der führende französische Hersteller von Leiterplatten und der fünfte auf europäischer Ebene. Die Gruppe verfügt über einige der modernsten Industriewerkzeuge in Europa.",
      es:
        "Elvia PCB group es el principal fabricante francés de circuitos impresos y el quinto a nivel europeo. El grupo tiene algunas de las herramientas industriales más sofisticadas de Europa.",
      nl:
        "De Elvia PCB-groep is de toonaangevende Franse fabrikant van gedrukte schakelingen en de vijfde op Europees niveau. De groep beschikt over enkele van de meest geavanceerde industriële gereedschappen in Europa.",
    },
    lienInfo: "",
    lienSite: "https://www.pcb-elvia.com/?utm=pcb-market",
    projet: false,
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "15",
    name: "Artemis cad",
    logo: "../images/img/logo/logo-artemis.png",
    type: ["pcb"],
    langue: ["FR", "EN"],
    nombreCouche: "16",
    quantité: "50",
    delais: 10,
    finitions: [],
    materiaux: ["FR-4", "Rogers"],
    rigidité: ["Rigide", "Semi flexible"],
    via: ["Buried", "Laser"],
    couleursVernis: ["Green", "Black", "White"],
    description: {
      fr:
        "Avec 30 ans d’expérience dans le domaine du numérique, Artemis s'inscrit comme un sous-traitant spécialiste du routage de cartes électroniques.",
      en:
        "With 30 years of experience in the digital field, Artemis is a subcontractor specializing in the routing of electronic cards.",
      it:
        "Con 30 anni di esperienza nel campo digitale, Artemis è un subappaltatore specializzato nell'instradamento di schede elettroniche.",
      de:
        "Mit 30 Jahren Erfahrung im digitalen Bereich ist Artemis ein Subunternehmer, der sich auf das Routing elektronischer Karten spezialisiert hat.",
      es:
        "Con 30 años de experiencia en el campo digital, Artemis es un subcontratista especializado en el enrutamiento de tarjetas electrónicas.",
      nl:
        "Met 30 jaar ervaring op digitaal gebied is Artemis een onderaannemer die gespecialiseerd is in het routeren van elektronische kaarten.",
    },
    lienInfo: "",
    lienSite: "https://www.artemis-cad.com/?utm=pcb-market",
    projet: false,
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "16",
    name: "Synergie CAD",
    logo: "../images/img/logo/logo-synergie.png",
    type: ["pcb", "pcba"],
    langue: ["FR", "EN", "IT", "DE", "ES"],
    nombreCouche: "60",
    quantité: 50,
    delais: 14,
    finitions: [],
    materiaux: [],
    rigidité: ["Rigide", "Semi flexible"],
    via: ["Laser"],
    couleursVernis: ["Green", "Black", "White"],
    description: {
      fr:
        "Synergie CAD propose des solutions clés en main complètes avec une assistance mondiale en tant que fabricant d'équipements et de services de test de fiabilité pour votre projet, de la R&D à la production et au-delà.",
      en:
        "Synergie CAD offers complete turnkey solutions with worldwide assistance as a manufacturer of equipment and reliability testing services for your project, from R&D to production and beyond.",
      it:
        "Synergie CAD offre soluzioni complete chiavi in ​​mano con assistenza in tutto il mondo come produttore di apparecchiature e servizi di test di affidabilità per il tuo progetto, dalla ricerca e sviluppo alla produzione e oltre.",
      de:
        "Synergie CAD bietet schlüsselfertige Komplettlösungen mit weltweiter Unterstützung als Hersteller von Zuverlässigkeitsprüfgeräten und -dienstleistungen für Ihr Projekt, von der Forschung und Entwicklung bis zur Produktion und darüber hinaus.",
      es:
        "Synergie CAD ofrece soluciones completas llave en mano con asistencia mundial como fabricante de equipos y servicios de prueba de confiabilidad para su proyecto, desde I + D hasta producción y más.",
      nl:
        "Synergie CAD biedt complete kant-en-klare oplossingen met wereldwijde hulp als fabrikant van apparatuur en diensten voor het testen van betrouwbaarheid voor uw project, van R&D tot productie en daarbuiten.",
    },
    lienInfo: "",
    lienSite: "http://www.synergie-cad.com/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "17",
    name: "techci Rhône Alpes",
    logo: "../images/img/logo/logo-techci.png",
    type: ["pcb"],
    langue: ["FR", "EN"],
    nombreCouche: "22",
    quantité: "50",
    delais: 10,
    finitions: [
      "HAL lead free",
      "Chemical gold",
      "Gold electro",
      "Chemical silver",
      "Chemical tin",
      "HAL lead free",
    ],
    materiaux: ["FR-4", "Teflon", "Polyimide"],
    rigidité: ["Rigide", "Semi flexible"],
    via: ["Laser", "Buried"],
    couleursVernis: ["Green", "Black", "White"],
    description: {
      fr:
        "Forte d'une parfaite maîtrise des process de fabrication et d'un savoir-faire reconnu, TECHCI est aujourd'hui un acteur majeur dans les filières de l'industrie, de l'aéronautique, du militaire et de l'embarqué.",
      en:
        "With a perfect mastery of the manufacturing processes and recognized know-how, TECHCI is today a major player in the industry, aeronautics, military and embedded sectors.",
      it:
        "Con una perfetta padronanza dei processi produttivi e un know-how riconosciuto, TECHCI è oggi uno dei principali attori del settore industriale, aeronautico, militare e embedded.",
      de:
        "Mit einer perfekten Beherrschung der Herstellungsprozesse und anerkanntem Know-how ist TECHCI heute ein wichtiger Akteur in den Bereichen Industrie, Luftfahrt, Militär und Embedded.",
      es:
        "Con un dominio perfecto de los procesos de fabricación y un know-how reconocido, TECHCI es hoy un actor importante en la industria, la aeronáutica, el sector militar y los sectores integrados.",
      nl:
        "Met een perfecte beheersing van de productieprocessen en erkende knowhow, is TECHCI vandaag de dag een belangrijke speler in de industrie, luchtvaart, militaire en embedded sectoren.",
    },
    lienInfo: "",
    lienSite: "http://www.techci.fr/index.cfm/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "18",
    name: "Formatronic",
    logo: "../images/img/logo/logo-formatronic.png",
    type: ["pcb"],
    langue: ["FR", "EN"],
    nombreCouche: "32",
    quantité: "50",
    delais: 10,
    finitions: [
      "HAL lead free",
      "Chemical gold",
      "Chemical silver",
      "Chemical tin",
      "OSP",
    ],
    materiaux: ["FR-4", "Polyimide"],
    rigidité: ["Rigide", "Semi flexible", "Flexible"],
    via: ["Buried"],
    couleursVernis: ["Green", "Black", "White"],
    description: {
      fr:
        "FORMATRONIC est spécialisée dans la production de circuits imprimés depuis plus de 25 ans, la société est en mesure de réaliser vos circuits imprimés en délai rapide selon les normes IPC-A600.",
      en:
        "FORMATRONIC is specialized in the production of printed circuits for more than 25 years, the company is able to realize your printed circuits in fast time according to standards IPC-A600.",
      it:
        "FORMATRONIC è specializzata nella produzione di circuiti stampati da oltre 25 anni, la società è in grado di realizzare i circuiti stampati in tempi rapidi secondo gli standard IPC-A600.",
      de:
        "FORMATRONIC ist seit mehr als 25 Jahren auf die Herstellung von gedruckten Schaltungen spezialisiert. Das Unternehmen ist in der Lage, Ihre gedruckten Schaltungen gemäß den Standards IPC-A600 in kurzer Zeit zu realisieren.",
      es:
        "FORMATRONIC está especializada en la producción de circuitos impresos durante más de 25 años, la empresa puede realizar sus circuitos impresos en tiempo rápido de acuerdo con las normas IPC-A600.",
      nl:
        "FORMATRONIC is al meer dan 25 jaar gespecialiseerd in de productie van printplaten, het bedrijf is in staat om uw printplaten snel te realiseren volgens de standaarden IPC-A600.",
    },
    lienInfo: "",
    lienSite: "https://www.formatronic.eu/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "19",
    name: "European Circuit LTD",
    logo: "../images/img/logo/logo-european-circuit.png",
    type: ["pcb", "pcba"],
    langue: ["FR", "EN", "NL", "DE"],
    nombreCouche: "12",
    quantité: 50,
    delais: 14,
    finitions: ["HAL lead free", "Chemical gold", " ENIG"],
    materiaux: ["FR-4", "Polyimide"],
    rigidité: ["Rigide", "Semi flexible"],
    via: ["Buried", "Laser"],
    couleursVernis: [
      "Green",
      "Black",
      "White",
      "Red",
      "Blue",
      "Orange",
      "Yellow",
      "Matt green",
      "Matt black",
      "Transparent",
    ],
    description: {
      fr:
        "European Circuits est l'une des rares entreprises au Royaume-Uni qui peut véritablement fournir la conception, la fabrication et l'assemblage de carte électronique à partir d'un seul emplacement.",
      en:
        "European Circuits is one of the few companies in the United Kingdom that can truly provide the design, manufacture and assembly of electronic cards from a single location.",
      it:
        "European Circuits è una delle poche aziende del Regno Unito in grado di fornire realmente progettazione, produzione e assemblaggio di schede elettroniche da un'unica sede.",
      de:
        "European Circuits ist eines der wenigen Unternehmen im Vereinigten Königreich, das die Entwicklung, Herstellung und Montage von elektronischen Karten von einem einzigen Standort aus anbieten kann.",
      es:
        "European Circuits es una de las pocas compañías en el Reino Unido que realmente puede proporcionar el diseño, la fabricación y el ensamblaje de tarjetas electrónicas desde una sola ubicación.",
      nl:
        "European Circuits is een van de weinige bedrijven in het Verenigd Koninkrijk die echt het ontwerp, de fabricage en assemblage van elektronische kaarten vanaf één locatie kan leveren.",
    },
    lienInfo: "",
    lienSite: "https://european-circuits.co.uk/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "20",
    name: "5pcb.de GmbH",
    logo: "../images/img/logo/logo-5pcb.jpg",
    type: ["pcb", "pcba"],
    langue: ["EN", "DE"],
    nombreCouche: 42,
    quantité: 50,
    delais: 8,
    materiaux: [
      "CEM-1",
      "CEM-2",
      "CEM-3",
      "CEM-4",
      "FR-1",
      "FR-2",
      "FR-3",
      "FR-4",
      "FR-5",
      "Polyester",
      "Kapton",
      "Polyimide",
      "Pyralux",
      "PTFE",
      "Teflon",
    ],
    finitions: [
      "HAL standard",
      "HAL lead free",
      "Chemical gold",
      "Gold electro",
      "OSP",
      "Chemical tin",
      "ENEPIG",
    ],
    rigidité: ["Rigide", "Semi flexible", "flexible"],
    via: ["Buried", "Laser"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "5pcb.de vous propose des cartes de circuits imprimés et des assemblages au plus haut niveau. Grâce à nos partenaires fiables en Asie et en Europe et aux nombreuses années d'expérience de notre personnel de l'industrie des PCB, nous pouvons offrir la meilleure qualité à un prix abordable.",
      en:
        "5pcb.de offers you printed circuit boards and assemblies at the highest level. Through our reliable partners in Asia and Europe and the many years of experience of our PCB industry staff, we can deliver the best quality at an affordable price.",
      it:
        "5pcb.de offre circuiti stampati e assemblaggi di altissimo livello. Tramite i nostri partner affidabili in Asia e in Europa e la pluriennale esperienza del nostro personale dell'industria PCB, siamo in grado di offrire la migliore qualità a un prezzo accessibile.",
      de:
        "5pcb.de bietet Ihnen Leiterplatten und Baugruppen auf höchstem Niveau. Durch unsere zuverlässigen Partner in Asien und Europa und die langjährige Erfahrung unserer Mitarbeiter in der Leiterplattenindustrie können wir die beste Qualität zu einem erschwinglichen Preis liefern.",
      es:
        "5pcb.de le ofrece placas de circuito impreso y conjuntos al más alto nivel. A través de nuestros socios confiables en Asia y Europa y los muchos años de experiencia de nuestro personal de la industria de PCB, podemos ofrecer la mejor calidad a un precio asequible.",
      nl:
        "5pcb.de biedt u printplaten en samenstellingen op het hoogste niveau. Door onze betrouwbare partners in Azië en Europa en de jarenlange ervaring van onze PCB-industrie medewerkers kunnen wij de beste kwaliteit leveren tegen een betaalbare prijs.",
    },
    lienInfo: "",
    lienSite: "https://5pcb.de/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "21",
    name: "Andus Electronic Gmbh",
    logo: "../images/img/logo/logo-andus.jpeg",
    type: ["pcb"],
    langue: ["EN", "DE"],
    nombreCouche: 30,
    quantité: 50,
    delais: 14,
    finitions: [
      "HAL standard",
      "HAL lead free",
      "Chemical gold",
      "Gold electro",
      "Chemical silver",
      "Chemical tin",
      "Chemical nickel",
      "OSP",
    ],
    materiaux: [
      "FR-4",
      "Polyester",
      "Kapton",
      "Polyimide",
      "Pyralux",
      "PTFE",
      "Teflon",
    ],
    rigidité: ["Rigide", "Semi flexible", "flexible"],
    via: ["Buried", "Laser"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "ANDUS est une entreprise familiale axée sur la technologie et fabrique d'excellents prototypes et petites séries pour des projets industriels sophistiqués ainsi que pour la science et la recherche.",
      en:
        "ANDUS is a technology-oriented family business and manufactures excellent prototypes and small-scale series for sophisticated industrial projects as well as science and research.",
      it:
        "ANDUS è un'azienda a conduzione familiare orientata alla tecnologia e produce eccellenti prototipi e serie su piccola scala per sofisticati progetti industriali, nonché scienza e ricerca.",
      de:
        "ANDUS ist ein technologieorientiertes Familienunternehmen und stellt hervorragende Prototypen und Kleinserien für anspruchsvolle Industrieprojekte sowie für Wissenschaft und Forschung her.",
      es:
        "ANDUS es una empresa familiar orientada a la tecnología y fabrica excelentes prototipos y series a pequeña escala para proyectos industriales sofisticados, así como para ciencia e investigación.",
      nl:
        "ANDUS is een technologiegericht familiebedrijf en produceert uitstekende prototypes en kleinschalige series voor geavanceerde industriële projecten, maar ook voor wetenschap en onderzoek.",
    },
    lienInfo: "",
    lienSite: "https://www.andus.de/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "22",
    name: "Atotech Deutschland GmbH",
    logo: "../images/img/logo/logo-atotec.png",
    type: ["pcb"],
    langue: ["EN", "DE"],
    nombreCouche: 8,
    quantité: 50,
    delais: 14,
    finitions: ["Gold electro", "Chemical tin", "ENEPIG", "OSP"],
    materiaux: ["FR-4", "Polyimide", "PTFE"],
    rigidité: ["Rigide", "Semi flexible", "flexible"],
    via: ["Buried"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "Nous voulons consolider notre position de leader en tant que fournisseur de solutions de finition de surface et devenir l’une des sociétés chimiques spécialisées les plus respectées au monde.",
      en:
        "We want to build on our leading position as a surface-finishing solutions provider and become one of the world’s most respected specialty chemicals companies.",
      it:
        "Vogliamo consolidare la nostra posizione di leader come fornitore di soluzioni per la finitura delle superfici e diventare una delle aziende di specialità chimiche più rispettate al mondo.",
      de:
        "Wir wollen unsere führende Position als Anbieter von Oberflächenveredelungslösungen weiter ausbauen und eines der weltweit angesehensten Unternehmen für Spezialchemikalien werden.",
      es:
        "Queremos desarrollar nuestra posición de liderazgo como proveedor de soluciones de acabado de superficies y convertirnos en una de las compañías de productos químicos especializados más respetadas del mundo.",
      nl:
        "We willen voortbouwen op onze leidende positie als leverancier van oplossingen voor oppervlakteafwerking en een van 's werelds meest gerespecteerde specialistische chemiebedrijven worden.",
    },
    lienInfo: "",
    lienSite: "https://www.atotech.com/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },

  {
    id: "23",
    name: "Kubatronik Leiterplatten GmbH",
    logo: "../images/img/logo/logo-kubatronik.png",
    type: ["pcb", "pcba"],
    langue: ["EN", "DE"],
    nombreCouche: 30,
    quantité: 50,
    delais: 14,
    finitions: [],
    materiaux: ["FR-4", "Polyimide"],
    rigidité: ["Rigide", "Semi flexible", "flexible"],
    via: ["Buried"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr: "Entreprise allemande récente d'assemblage de PCB",
      en: "Recent german PCB assembly company",
      it: "Recente società tedesca di assemblaggio di PCB",
      de: "Neuere deutsche Leiterplattenbestückungsfirma",
      es: "Empresa alemana de ensamblaje de PCB reciente",
      nl: "Recent Duits PCB-montagebedrijf",
    },
    lienInfo: "",
    lienSite: "http://www.kubatronik.de/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },

  {
    id: "24",
    name: "Schweizer Electronic",
    logo: "../images/img/logo/logo-schweizer.jpeg",
    type: ["pcb"],
    langue: ["EN", "DE"],
    nombreCouche: 18,
    quantité: 50,
    delais: 14,
    finitions: [],
    materiaux: ["CEM-1", "FR-4"],
    rigidité: ["Rigide"],
    via: ["Buried", "Laser"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "Nous sommes une entreprise technologique mondiale de premier ordre. Nos produits préservent les ressources pour l'avenir de nos enfants. Nous sommes un fabricant leader de PCB de qualité supérieure et un fabricant reconnu de produits et services écoénergétiques et respectueux de l'environnement.",
      en:
        "We are a global best-in-class technology company.Our products preserve resources for the future of our children.We are a leading manufacturer of premium PCBs and a recognised manufacturer of energy-efficient and environmentally-friendly products and services.",
      it:
        "Siamo un'azienda tecnologica di prim'ordine a livello mondiale. I nostri prodotti preservano le risorse per il futuro dei nostri figli. Siamo leader nella produzione di PCB di alta qualità e produttore riconosciuto di prodotti e servizi efficienti dal punto di vista energetico e rispettosi dell'ambiente.",
      de:
        "Wir sind ein weltweit führendes Technologieunternehmen. Unsere Produkte schonen Ressourcen für die Zukunft unserer Kinder. Wir sind ein führender Hersteller von Premium-Leiterplatten und ein anerkannter Hersteller von energieeffizienten und umweltfreundlichen Produkten und Dienstleistungen.",
      es:
        "Somos una compañía global de tecnología de primer nivel. Nuestros productos preservan los recursos para el futuro de nuestros hijos. Somos un fabricante líder de PCB de primera calidad y un reconocido fabricante de productos y servicios eficientes en energía y respetuosos con el medio ambiente.",
      nl:
        "Wij zijn een wereldwijd best-in-class technologiebedrijf. Onze producten sparen hulpbronnen voor de toekomst van onze kinderen. Wij zijn een toonaangevende fabrikant van hoogwaardige PCB's en een erkende fabrikant van energiezuinige en milieuvriendelijke producten en diensten.",
    },
    lienInfo: "",
    lienSite: "https://www.schweizer.ag/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "25",
    name: "TX Cube",
    logo: "../images/img/logo/logo-txcube.jpeg",
    type: ["pcb"],
    langue: ["EN", "F-4"],
    nombreCouche: 32,
    quantité: 50,
    delais: 14,
    finitions: [],
    materiaux: [],
    rigidité: ["Rigide", "Semi flexible", "flexible"],
    via: ["Buried", "Laser"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "Nous avons une équipe d'ingénierie électronique et mécanique avec une expertise et une expérience élevées pour gérer la conception complète de votre projet.",
      en:
        "We have electronic and mechanical engineering team with high expertise and experience for handling your complete project design.",
      it:
        "Abbiamo un team di ingegneria elettronica e meccanica con elevata competenza ed esperienza per la gestione della progettazione completa del progetto.",
      de:
        "Wir haben ein Team für Elektronik und Maschinenbau mit hohem Fachwissen und Erfahrung für die Abwicklung Ihres gesamten Projektdesigns.",
      es:
        "Contamos con un equipo de ingeniería electrónica y mecánica con gran experiencia y experiencia para manejar el diseño completo de su proyecto.",
      nl:
        "We hebben een elektronisch en werktuigbouwkundig team met een hoge expertise en ervaring voor het afhandelen van uw volledige projectontwerp.",
    },
    lienInfo: "",
    lienSite: "http://www.txcube.eu/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "26",
    name: "CIREX FRANCE",
    logo: "../images/img/logo/logo-cirex.jpeg",
    type: ["pcb"],
    langue: ["EN", "FR"],
    nombreCouche: 50,
    quantité: 50,
    delais: 10,
    finitions: [
      "HAL standard",
      "HAL lead free",
      "Chemical gold",
      "Gold electro",
      "Chemical silver",
      "Chemical tin",
      "ENEPIG",
      "HAL-SN100C",
      "Without finish",
      "Chemical nickel",
      "OSP",
    ],
    materiaux: ["FR-4", "POlyimide", "Teflon", "PTFE"],
    rigidité: ["Rigide", "Semi flexible", "flexible"],
    via: ["Buried", "Laser"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "Cirex France, division européenne de LAO-TAY Group, fabricant de circuits imprimés, a été créée par un groupe de personnes expérimentées pour fournir des produits de qualité et des livraisons ponctuelles à des prix compétitifs.",
      en:
        "Cirex France, European division of LAO-TAY Group, manufacturer of printed circuits, was created by a group of experienced people to provide quality products and punctual deliveries at competitive prices.",
      it:
        "Cirex France, divisione europea del gruppo LAO-TAY, produttore di circuiti stampati, è stata creata da un gruppo di persone esperte per fornire prodotti di qualità e consegne puntuali a prezzi competitivi.",
      de:
        "Cirex France, europäischer Geschäftsbereich der LAO-TAY Group, Hersteller von gedruckten Schaltungen, wurde von einer Gruppe erfahrener Mitarbeiter gegründet, um Qualitätsprodukte und pünktliche Lieferungen zu wettbewerbsfähigen Preisen anzubieten.",
      es:
        "Cirex France, división europea del Grupo LAO-TAY, fabricante de circuitos impresos, fue creada por un grupo de personas experimentadas para proporcionar productos de calidad y entregas puntuales a precios competitivos.",
      nl:
        "Cirex France, Europese divisie van LAO-TAY Group, fabrikant van printplaten, is opgericht door een groep ervaren mensen om kwaliteitsproducten en stipte leveringen te leveren tegen concurrerende prijzen.",
    },
    lienInfo: "",
    lienSite: "https://www.cirex-france.com/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "27",
    name: "Faraz Circuit Company",
    logo: "../images/img/logo/logo-faraz.jpeg",
    type: ["pcb"],
    langue: ["EN", "FR"],
    nombreCouche: 18,
    quantité: 50,
    delais: 14,
    materiaux: ["FR-4"],
    finitions: [],
    rigidité: ["Rigide", "Semi flexible", "flexible"],
    via: ["Buried", "Laser"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "il va sans dire que cette entreprise, avec plus de 20 ans d'expérience, est l'une des premières P.C.B. les producteurs en Iran, qui sont reconnaissants d'utiliser la plus haute technologie, des équipements modernes et des experts pour produire toutes sortes de PCB tels que simple face, double face, P.T.H. Multicouche, ...",
      en:
        "it goes with out saying that This company, with more than 20 years experience, is one of the first P.C.B. producers in Iran, which is grateful to use the highest technology, modern equipment and expert persons to produce all kind of PCBs such as single side, double side , P.T.H. Multilayer,...",
      it:
        "va da sé che questa azienda, con oltre 20 anni di esperienza, è una delle prime P.C.B. produttori in Iran, che è grato di utilizzare la più alta tecnologia, attrezzature moderne e persone esperte per produrre tutti i tipi di PCB come lato singolo, doppio lato, P.T.H. Multistrato, ...",
      de:
        "Es versteht sich von selbst, dass dieses Unternehmen mit mehr als 20 Jahren Erfahrung eines der ersten P.C.B. Hersteller im Iran, die dankbar sind, die höchste Technologie, moderne Ausrüstung und Fachleute für die Herstellung aller Arten von Leiterplatten wie einseitige, doppelseitige, P.T.H. Multilayer, ...",
      es:
        "Es evidente que esta empresa, con más de 20 años de experiencia, es una de las primeras empresas de P.C.B. productores en Irán, que está agradecido de utilizar la más alta tecnología, equipos modernos y personas expertas para producir todo tipo de PCB, como un solo lado, doble lado, P.T.H. Multicapa, ...",
      nl:
        "Het spreekt voor zich dat dit bedrijf, met meer dan 20 jaar ervaring, een van de eerste P.C.B. producenten in Iran, die dankbaar zijn dat ze de hoogste technologie, moderne apparatuur en deskundige personen gebruiken om allerlei soorten PCB's te produceren, zoals enkelzijdig, dubbelzijdig, P.T.H. Meerlagig, ...",
    },
    lienInfo: "",
    lienSite: "http://www.farazmadar.com/views/home.php?lang=en?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "28",
    name: "ICAPE GROUP",
    logo: "../images/img/logo/logo-icape.jpeg",
    type: ["pcb"],
    langue: ["EN", "FR", "DE", "PT", "IT"],
    nombreCouche: 20,
    quantité: 50,
    delais: 14,
    materiaux: ["FR-4"],
    finitions: [
      "HAL lead free",
      "Gold electro",
      "Chemical silver",
      "Chemical tin",
      "ENEPIG",
    ],
    rigidité: ["Rigide"],
    via: ["Buried"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "ICAPE Group offre les meilleurs services pour un devis optimal de vos circuits imprimés et de vos pièces techniques.",
      en:
        "ICAPE Group offers the best services for an optimal quote for your printed circuits and technical parts.",
      it:
        "Il gruppo ICAPE offre i migliori servizi per un preventivo ottimale per i circuiti stampati e le parti tecniche.",
      de:
        "Die ICAPE Group bietet den besten Service für ein optimales Angebot für Ihre gedruckten Schaltungen und technischen Teile.",
      es:
        "ICAPE Group ofrece los mejores servicios para un presupuesto óptimo para sus circuitos impresos y piezas técnicas.",
      nl:
        "ICAPE Group biedt de beste services voor een optimale offerte voor uw gedrukte schakelingen en technische onderdelen.",
    },
    lienInfo: "",
    lienSite: "https://www.icape-group.com/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "29",
    name: "Pcb Electronics",
    logo: "../images/img/logo/logopcb-electronics.png",
    type: ["pcb"],
    langue: ["EN", "FR", "DE"],
    nombreCouche: 42,
    quantité: 50,
    delais: 5,
    finitions: [
      "HAL standard",
      "HAL lead free",
      "Gold electro",
      "Chemical tin",
      "OSP",
    ],
    materiaux: ["FR-4", "Polyimide", "Teflon"],
    rigidité: ["Rigide", "Semi flexible", "flexible"],
    via: ["Buried", "Laser"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "Pcb-electronics propose une offre complète et novatrice qui vous permet de produire toutes vos cartes, de votre prototype de test, à votre série de commercialisation, toujours au meilleur tarif.",
      en:
        "Pcb-electronics offers a complete and innovative offer which allows you to produce all your cards, from your test prototype, to your marketing series, always at the best price.",
      it:
        "Pcb-electronics offre un'offerta completa e innovativa che ti consente di produrre tutte le tue carte, dal tuo prototipo di test alle tue serie di marketing, sempre al miglior prezzo.",
      de:
        "Pcb-electronic bietet ein umfassendes und innovatives Angebot, mit dem Sie alle Ihre Karten, vom Testprototyp bis zur Marketing-Serie, immer zum besten Preis produzieren können.",
      es:
        "Pcb-electronics ofrece una oferta completa e innovadora que le permite producir todas sus tarjetas, desde su prototipo de prueba hasta su serie de marketing, siempre al mejor precio.",
      nl:
        "Pcb-electronics biedt een compleet en innovatief aanbod waarmee u al uw kaarten, van uw testprototype tot uw marketingreeks, altijd voor de beste prijs kunt produceren.",
    },
    lienInfo: "",
    lienSite: "https://www.pcbelectronics.fr/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "30",
    name: "SOS Electronic Engineering",
    logo: "../images/img/logo/logo-soselectronics.png",
    type: ["pcb"],
    langue: ["EN"],
    nombreCouche: 40,
    quantité: 50,
    delais: 5,
    finitions: [
      "HAL standard",
      "HAL lead free",
      "Chemical gold",
      "Gold electro",
      "Chemical silver",
      "Chemical tin",
      "ENEPIG",
      "HAL-SN100C",
      "Without finish",
      "Chemical nickel",
      "OSP",
    ],
    materiaux: ["FR-4"],
    rigidité: ["Rigide"],
    via: ["Buried", "Laser"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "Nous sommes un distributeur transnational de composants électroniques pour la production industrielle. Nous nous spécialisons dans les marques mondiales vérifiées en mettant l'accent sur le design. Ainsi, nous sommes déjà avec vous lors de la conception et du développement des appareils.",
      en:
        "We are a transnational distributor of electronic components for industrial production. We specialize in verified world brands with design-in emphasis. Thus, we are with you already during the design and development of the devices.",
      it:
        "Wir sind ein transnationaler Distributor von elektronischen Bauteilen für die industrielle Produktion. Wir sind spezialisiert auf geprüfte Weltmarken mit Design-In-Schwerpunkt. Somit sind wir bereits bei der Entwicklung und Entwicklung der Geräte bei Ihnen.",
      de:
        "Wir sind ein transnationaler Distributor von elektronischen Bauteilen für die industrielle Produktion. Wir sind spezialisiert auf geprüfte Weltmarken mit Design-In-Schwerpunkt. Somit sind wir bereits bei der Entwicklung und Entwicklung der Geräte bei Ihnen.",
      es:
        "Somos un distribuidor transnacional de componentes electrónicos para producción industrial. Nos especializamos en marcas mundiales verificadas con énfasis en el diseño. Por lo tanto, ya estamos con usted durante el diseño y desarrollo de los dispositivos.",
      nl:
        "Wij zijn een transnationale distributeur van elektronische componenten voor industriële productie. Wij zijn gespecialiseerd in geverifieerde wereldmerken met nadruk op design. Zo zijn we al bij je tijdens het ontwerp en de ontwikkeling van de apparaten.",
    },
    lienInfo: "",
    lienSite: "https://www.soselectronic.com/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "31",
    name: "SYSTEM PLUS CONSULTING",
    logo: "../images/img/logo/logo-systemplus.jpeg",
    type: ["pcb"],
    langue: ["EN"],
    nombreCouche: 16,
    quantité: 50,
    delais: 14,
    finitions: [
      "HAL standard",
      "HAL lead free",
      "Gold electro",
      "Chemical silver",
      "Chemical tin",
      "OSP",
    ],
    materiaux: ["FR-4"],
    rigidité: ["Rigide"],
    via: ["Buried"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "System Plus Consulting utilise une approche technique des coûts. Les modèles de coûts et l'expertise technologique sont combinés pour fournir aux clients une estimation précise et objective des coûts de fabrication et des prix de vente.",
      en:
        "System Plus Consulting utilizes an engineering approach to cost. Cost models and technology expertise are combined to provide customers with an accurate and objective estimation of manufacturing costs and selling prices.",
      it:
        "System Plus Consulting utilizza un approccio ingegneristico ai costi. Modelli di costo ed esperienza tecnologica sono combinati per fornire ai clienti una stima accurata e obiettiva dei costi di produzione e dei prezzi di vendita.",
      de:
        "System Plus Consulting verwendet einen technischen Kostenansatz. Kostenmodelle und Technologiekompetenz werden kombiniert, um Kunden eine genaue und objektive Schätzung der Herstellungskosten und Verkaufspreise zu ermöglichen.",
      es:
        "System Plus Consulting utiliza un enfoque de ingeniería para el costo. Los modelos de costos y la experiencia tecnológica se combinan para proporcionar a los clientes una estimación precisa y objetiva de los costos de fabricación y los precios de venta.",
      nl:
        "System Plus Consulting maakt gebruik van een technische benadering van kosten. Kostenmodellen en technologische expertise worden gecombineerd om klanten een nauwkeurige en objectieve schatting van productiekosten en verkoopprijzen te bieden.",
    },
    lienInfo: "",
    lienSite: "https://www.systemplus.fr/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "32",
    name: "B&B Sachsenelektronik GmbH",
    logo: "../images/img/logo/logo-b&b.jpeg",
    type: ["pcb"],
    langue: ["EN", "DE"],
    nombreCouche: 20,
    quantité: 50,
    delais: 14,
    finitions: [
      "HAL standard",
      "HAL lead free",
      "Chemical gold",
      "Chemical silver",
      "Chemical tin",
      "ENEPIG",
      "HAL-SN100C",
      "OSP",
    ],
    materiaux: ["FR-4"],
    rigidité: ["Rigide"],
    via: ["Buried", "Laser"],
    couleursVernis: [
      "Green",
      "Matt Green",
      "Black",
      "Blue",
      "Red",
      "Matt Black",
      "White",
      "Yellow",
      "Transparent",
    ],
    description: {
      fr:
        "Depuis sa fondation à Heiligenhaus en 1996, le groupe B&B est synonyme de la meilleure qualité et du meilleur service dans la production de circuits imprimés. Notre site de production à Mittweida vous offre tout sous un même toit - conseil et commande, production, assurance qualité certifiée et logistique.",
      en:
        "Since its founding in Heiligenhaus in 1996, the B&B group has stood for the best quality and service in printed circuit board production. Our production site in Mittweida offers you everything under one roof - advice and ordering, production, certified quality assurance and logistics.",
      it:
        "Dalla sua fondazione nella Heiligenhaus nel 1996, il gruppo B&B è sinonimo di massima qualità e servizio nella produzione di circuiti stampati. Il nostro sito produttivo a Mittweida offre tutto sotto lo stesso tetto: consulenza e ordini, produzione, controllo qualità certificato e logistica.",
      de:
        "Seit ihrer Gründung in Heiligenhaus im Jahr 1996 steht die B & B-Gruppe für beste Qualität und besten Service bei der Herstellung von Leiterplatten. Unser Produktionsstandort in Mittweida bietet Ihnen alles unter einem Dach - Beratung und Bestellung, Produktion, zertifizierte Qualitätssicherung und Logistik.",
      es:
        "Desde su fundación en Heiligenhaus en 1996, el grupo B&B se ha destacado por la mejor calidad y servicio en la producción de placas de circuito impreso. Nuestro sitio de producción en Mittweida le ofrece todo bajo un mismo techo: asesoramiento y pedidos, producción, garantía de calidad certificada y logística.",
      nl:
        "Sinds de oprichting in Heiligenhaus in 1996 staat de B&B groep voor de beste kwaliteit en service in de productie van printplaten. Onze productielocatie in Mittweida biedt u alles onder één dak - advies en bestelling, productie, gecertificeerde kwaliteitsborging en logistiek.",
    },
    lienInfo: "",
    lienSite: "https://www.bb-gruppe.de/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "33",
    name: "Becker & Müller Schaltungsdruck GmbH",
    logo: "../images/img/logo/logo-becker.jpeg",
    type: ["pcb"],
    langue: ["EN", "DE"],
    nombreCouche: 20,
    quantité: 50,
    delais: 14,
    finitions: [
      "HAL lead free",
      "Chemical gold",
      "Chemical tin",
      "Chemical nickel",
    ],
    materiaux: ["FR-4", "CEM-1"],
    rigidité: ["Rigide", "Semi flexible", "flexible"],
    via: ["Buried"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "Production de prototypes et de petits volumes - PCB simple face et double face PCB multicouches jusqu'à 20 couches. Nous vous offrons un service express et garantissons une qualité supérieure et respectons pratiquement 100% des délais que nous nous fixons",
      en:
        "Prototype and small volume production - single-sided and double-sided PCBs multilayer PCBs up to 20 layers. We offer you express service and guarantee top quality and meet virtually 100% of the deadlines we are set",
      it:
        "Prototipo e produzione di piccoli volumi - PCB a un solo lato e a doppio lato PCB multistrato fino a 20 strati. Vi offriamo un servizio espresso e garantiamo la massima qualità e rispettiamo praticamente il 100% delle scadenze che ci siamo prefissati",
      de:
        "Prototyp- und Kleinserienfertigung - einseitige und doppelseitige Leiterplatten Mehrschichtige Leiterplatten mit bis zu 20 Schichten. Wir bieten Ihnen Express-Service und garantieren höchste Qualität und halten praktisch 100% der von uns festgelegten Fristen ein",
      es:
        "Producción de prototipos y pequeños volúmenes: PCB de una o dos caras PCB de varias capas de hasta 20 capas. Le ofrecemos un servicio expreso y garantizamos la mejor calidad y cumplimos prácticamente el 100% de los plazos establecidos.",
      nl:
        "Prototype en kleinschalige productie - enkelzijdige en dubbelzijdige PCB's meerlagige PCB's tot 20 lagen. Wij bieden u snelle service en garanderen topkwaliteit en halen nagenoeg 100% van de gestelde termijnen",
    },
    lienInfo: "",
    lienSite: "https://www.becker-mueller.de/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "34",
    name: "BERATRONIC GmbH",
    logo: "../images/img/logo/logo-beratronic.png",
    type: ["pcb", "pcba"],
    langue: ["EN", "DE"],
    nombreCouche: 58,
    quantité: 50,
    delais: 7,
    finitions: [
      "HAL standard",
      "HAL lead free",
      "Gold electro",
      "Chemical silver",
      "Chemical tin",
      "ENEPIG",
      "HAL-SN100C",
      "OSP",
    ],
    materiaux: ["CEM-1", "CEM-3", "FR-4", "Polyimide"],
    rigidité: ["Rigide"],
    via: ["Buried", "Laser"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "Beratronic GmbH est un fournisseur de services dans la branche électronique avec un accent sur les cartes de circuits imprimés, le service de fabrication électronique, la conception et le développement.",
      en:
        "Beratronic GmbH is a service provider within the electronic branch with a focus on printed circuit boards, electronic manufacturing service, design, and development.",
      it:
        "Beratronic GmbH è un fornitore di servizi all'interno della filiale elettronica con particolare attenzione ai circuiti stampati, al servizio di produzione elettronica, alla progettazione e allo sviluppo.",
      de:
        "Die Beratronic GmbH ist ein Dienstleister in der Elektronikbranche mit den Schwerpunkten Leiterplatten, Elektronikfertigung, Design und Entwicklung.",
      es:
        "Beratronic GmbH es un proveedor de servicios dentro de la rama electrónica con un enfoque en placas de circuitos impresos, servicio de fabricación electrónica, diseño y desarrollo.",
      nl:
        "Beratronic GmbH is een dienstverlener binnen de elektronische branche met een focus op printplaten, elektronische productieservice, ontwerp en ontwikkeling.",
    },
    lienInfo: "",
    lienSite: "https://www.beratronic.com/index.php/en/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "35",
    name: "China Circuit Technology (Europe)",
    logo: "../images/img/logo/logo-chinacircuit.jpg",
    type: ["pcb"],
    langue: ["EN", "DE"],
    nombreCouche: 28,
    quantité: 50,
    delais: 14,
    finitions: [
      "HAL lead free",
      "Chemical gold",
      "Gold electro",
      "Chemical silver",
      "Chemical tin",
      "HAL-SN100C",
    ],
    materiaux: ["FR-4", "Polyimide", "Teflon"],
    rigidité: ["Rigide"],
    via: ["Buried"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "CCTC Europe est une filiale à 100% du CCTC chinois à Shantou et a été fondée le 26 novembre 1999. Notre société conseille tous les clients européens et potentiels. Un conseil technologique complet jusque dans les moindres détails de toutes les technologies de circuits imprimés est offert sur place.",
      en:
        "CCTC Europe is a 100% subsidiary of the Chinese CCTC in Shantou and was founded on November 26th, 1999. Our company advises all European and potential customers. Extensive technological advice down to the last detail of all circuit board technologies is offered on site.",
      it:
        "CCTC Europe è una consociata al 100% della CCTC cinese a Shantou ed è stata fondata il 26 novembre 1999. La nostra azienda fornisce consulenza a tutti i clienti europei e potenziali. Sul posto viene offerta un'ampia consulenza tecnologica fino all'ultimo dettaglio di tutte le tecnologie dei circuiti stampati.",
      de:
        "CCTC Europe ist eine 100% ige Tochtergesellschaft des chinesischen CCTC in Shantou und wurde am 26. November 1999 gegründet. Unser Unternehmen berät alle europäischen und potenziellen Kunden. Vor Ort wird eine umfassende technologische Beratung bis ins kleinste Detail aller Leiterplattentechnologien angeboten.",
      es:
        "CCTC Europe es una subsidiaria al 100% del CCTC chino en Shantou y fue fundada el 26 de noviembre de 1999. Nuestra empresa asesora a todos los clientes europeos y potenciales. Se ofrece un amplio asesoramiento tecnológico hasta el último detalle de todas las tecnologías de placa de circuito en el sitio.",
      nl:
        "CCTC Europe is een 100% dochteronderneming van de Chinese CCTC in Shantou en werd opgericht op 26 november 1999. Ons bedrijf adviseert alle Europese en potentiële klanten. Uitgebreid technologisch advies tot in het kleinste detail van alle printplaattechnologieën wordt ter plaatse aangeboden.",
    },
    lienInfo: "",
    lienSite: "https://www.cctceurope.com/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "36",
    name: "CML",
    logo: "../images/img/logo/logo-cml.jpeg",
    type: ["pcb"],
    langue: ["EN", "DE", "ES", "FR"],
    nombreCouche: 12,
    quantité: 50,
    delais: 14,
    finitions: [
      "HAL standard",
      "HAL lead free",
      "Gold electro",
      "Chemical silver",
      "Chemical tin",
      "ENEPIG",
      "OSP",
    ],
    materiaux: ["CEM-1", "CEm-3", "FR-4", "FR-5", "PTFE"],
    rigidité: ["Rigide"],
    via: ["Buried"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "CML répond aux normes de qualité exigeantes des chaînes logistiques de l’industrie automobile depuis 2001. Aujourd’hui, les plus grandes marques du monde se fient à notre expertise et à nos connaissances techniques.",
      en:
        "CML has met the demanding quality standards of supply chains in the automotive industry since 2001. Today, the biggest brands in the world rely on our expertise and technical knowledge.",
      it:
        "CML soddisfa i severi standard di qualità delle catene di approvvigionamento nel settore automobilistico dal 2001. Oggi, i più grandi marchi del mondo si affidano alla nostra esperienza e conoscenza tecnica.",
      de:
        "CML erfüllt seit 2001 die anspruchsvollen Qualitätsstandards der Lieferketten in der Automobilindustrie. Heute verlassen sich die größten Marken der Welt auf unser Know-how und unser technisches Wissen.",
      es:
        "CML ha cumplido con los exigentes estándares de calidad de las cadenas de suministro en la industria automotriz desde 2001. Hoy, las marcas más grandes del mundo confían en nuestra experiencia y conocimiento técnico.",
      nl:
        "CML voldoet sinds 2001 aan de veeleisende kwaliteitsnormen van toeleveringsketens in de auto-industrie. Tegenwoordig vertrouwen de grootste merken ter wereld op onze expertise en technische kennis.",
    },
    lienInfo: "",
    lienSite: "https://cml-globalsolutions.com/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "37",
    name: "Contag Ag",
    logo: "../images/img/logo/logo-contag-ag.jpeg",
    type: ["pcb", "pcba"],
    langue: ["EN", "DE"],
    nombreCouche: 24,
    quantité: 50,
    delais: 14,
    finitions: [
      "HAL standard",
      "HAL lead free",
      "Chemical gold",
      "Gold electro",
      "Chemical tin",
      "ENEPIG",
      "OSP",
    ],
    materiaux: ["FR-4"],
    rigidité: ["Rigide"],
    via: ["Buried", "Laser"],
    couleursVernis: [
      "Green",
      "Black",
      "Blue",
      "Yellow",
      "White",
      "Without varnish",
    ],
    description: {
      fr:
        "Nous sommes différents des autres! Mais pas seulement en quelque sorte différent, mais différent dans le sens de meilleur, plus fiable, plus rapide. Tel est notre objectif, que nous poursuivons chaque jour dans notre travail. Et parce que nous nous en demandons beaucoup, vous pouvez également en attendre beaucoup de nous.",
      en:
        "We are different to the others! But not just somehow different, but different in the sense of better, more reliable, quicker. That is our goal, which we pursue every day in our work. And because we ask such a lot of ourselves, you can also expect quite a bit from us.",
      it:
        "Siamo diversi dagli altri! Ma non solo in qualche modo diverso, ma diverso nel senso di migliore, più affidabile, più veloce. Questo è il nostro obiettivo, che perseguiamo ogni giorno nel nostro lavoro. E poiché chiediamo così tanto a noi stessi, puoi anche aspettarti un bel po 'da noi.",
      de:
        "Wir sind anders als die anderen! Aber nicht nur irgendwie anders, sondern anders im Sinne von besser, zuverlässiger, schneller. Das ist unser Ziel, das wir jeden Tag in unserer Arbeit verfolgen. Und weil wir so viel von uns verlangen, können Sie auch einiges von uns erwarten.",
      es:
        "¡Somos diferentes a los demás! Pero no solo de alguna manera diferente, sino diferente en el sentido de mejor, más confiable, más rápido. Ese es nuestro objetivo, que perseguimos todos los días en nuestro trabajo. Y debido a que nos pedimos mucho, también puede esperar un poco de nosotros.",
      nl:
        "We zijn anders dan de anderen! Maar niet zomaar anders, maar anders in de zin van beter, betrouwbaarder, sneller. Dat is ons doel, dat we dagelijks nastreven in ons werk. En omdat we zo veel van ons vragen, mag je ook heel wat van ons verwachten.",
    },
    lienInfo: "",
    lienSite: "http://www.contag.eu/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "38",
    name: "E & K Leiterplatten",
    logo: "../images/img/logo/logo-e&k.jpeg",
    type: ["pcb"],
    langue: ["EN", "DE", "FR", "NL"],
    nombreCouche: 8,
    quantité: 50,
    delais: 14,
    finitions: ["HAL standard", "HAL lead free", "Chemical tin", "ENEPIG"],
    materiaux: ["FR-4"],
    rigidité: ["Rigide"],
    via: ["Buried", "Laser"],
    couleursVernis: ["Green", "Black", "Blue", "White", "Yellow", "Red"],
    description: {
      fr:
        "E&K Leiterplatten mise sur l’expérience, la recherche et sur les nouveautés techniques constantes. Outre la technique, les connaissances de notre personnel hautement qualifié et fortement motivé sont un signe distinctif de qualité. Nous avons eu recours à la technique CMS avec des vernis épargne photosensibles",
      en:
        "E&K Leiterplatten relies on experience, research and constant technical innovations. In addition to technique, the knowledge of our highly qualified and highly motivated staff is a distinctive sign of quality. We used the CMS technique with photosensitive savings varnishes",
      it:
        "E&K Leiterplatten si affida all'esperienza, alla ricerca e alle continue innovazioni tecniche. Oltre alla tecnica, la conoscenza del nostro personale altamente qualificato e motivato è un segno distintivo di qualità. Abbiamo usato la tecnica CMS con vernici fotosensibili a risparmio",
      de:
        "E & K Leiterplatten setzt auf Erfahrung, Forschung und ständige technische Innovationen. Neben der Technik ist das Wissen unserer hochqualifizierten und hochmotivierten Mitarbeiter ein unverwechselbares Qualitätsmerkmal. Wir haben die CMS-Technik mit lichtempfindlichen Einsparungslacken verwendet",
      es:
        "E&K Leiterplatten se basa en la experiencia, la investigación y las constantes innovaciones técnicas. Además de la técnica, el conocimiento de nuestro personal altamente calificado y altamente motivado es un signo distintivo de calidad. Utilizamos la técnica CMS con barnices de ahorro fotosensibles",
      nl:
        "E&K Leiterplatten vertrouwt op ervaring, onderzoek en constante technische innovaties. Naast techniek is de kennis van onze hooggekwalificeerde en zeer gemotiveerde medewerkers een onderscheidend teken van kwaliteit. We gebruikten de CMS-techniek met lichtgevoelige spaarvernis",
    },
    lienInfo: "",
    lienSite: "https://fr.ek-leiterplatten.de/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "39",
    name: "EES European Electronics Systems",
    logo: "../images/img/logo/logo-ees.png",
    type: ["pcb"],
    langue: ["EN"],
    nombreCouche: 46,
    quantité: 50,
    delais: 14,
    finitions: [
      "HAL standard",
      "Gold electro",
      "Chemical silver",
      "Chemical tin",
      "ENEPIG",
      "OSP",
    ],
    materiaux: ["FR-4", "Polyimide", "PTFE"],
    rigidité: ["Rigide", "Semi flexible", "flexible"],
    via: ["Buried"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "Fort de plus de 100 ans d'expérience collective, le groupe EES est composé de deux fabricants allemands bien implantés sur le marché des PCB. Ensemble, en tant qu'EES, nous couvrons la gamme complète de produits contenant des PCB, chaque usine apportant ses forces et son expérience.",
      en:
        "With more than 100 years collective experience the EES group consists of two German manufacturers well-established in the PCB-market. Together we as EES cover the complete product portfolio of PCBs, each plant bringing in its strengths and experience.",
      it:
        "Con oltre 100 anni di esperienza collettiva, il gruppo EES è composto da due produttori tedeschi ben affermati nel mercato dei PCB. Insieme, come EES, copriamo il portafoglio completo di prodotti di PCB, ogni impianto ha i suoi punti di forza ed esperienza.",
      de:
        "Mit mehr als 100 Jahren kollektiver Erfahrung besteht die EES-Gruppe aus zwei deutschen Herstellern, die auf dem Leiterplattenmarkt gut etabliert sind. Gemeinsam decken wir als EES das gesamte Produktportfolio von Leiterplatten ab, wobei jede Anlage ihre Stärken und Erfahrungen einbringt.",
      es:
        "Con más de 100 años de experiencia colectiva, el grupo EES está formado por dos fabricantes alemanes bien establecidos en el mercado de PCB. Juntos, como EES, cubrimos la cartera completa de productos de PCB, cada planta aportando sus fortalezas y experiencia.",
      nl:
        "Met meer dan 100 jaar collectieve ervaring bestaat de EES-groep uit twee Duitse fabrikanten die een gevestigde waarde hebben op de PCB-markt. Samen dekken we als EES het volledige productportfolio van PCB's, waarbij elke fabriek zijn sterke punten en ervaring inbrengt.",
    },
    lienInfo: "",
    lienSite: "https://ees-pcb.com/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "40",
    name: "Elekonta Marek",
    logo: "../images/img/logo/logo-elekonta.jpeg",
    type: ["pcb"],
    langue: ["EN", "DE"],
    nombreCouche: 42,
    quantité: 50,
    delais: 14,
    finitions: [
      "HAL standard",
      "HAL lead free",
      "Gold electro",
      "Chemical tin",
      "ENEPIG",
      "OSP",
    ],
    materiaux: ["FR-4"],
    rigidité: ["Rigide", "Semi flexible", "flexible"],
    via: ["Buried"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "Il n'y a rien, qui ne peut pas être amélioré davantage - cela vaut également pour la production de circuits imprimés de haute technologie. C'est pourquoi, chez Elekonta, en tant que partenaires de développement, nous travaillons à l'amélioration permanente de notre gamme de services pour l'industrie électronique depuis plus de trois décennies.",
      en:
        "There is nothing, that cannot be improved further – this also applies to the production of high-tech circuit boards. That’s why we at Elekonta as development partners work on improving our range of services for the electronic industry permanently for over three decades.",
      it:
        "Non c'è nulla che non possa essere ulteriormente migliorato: ciò vale anche per la produzione di circuiti stampati ad alta tecnologia. Ecco perché noi di Elekonta come partner di sviluppo lavoriamo per migliorare la nostra gamma di servizi per l'industria elettronica in modo permanente per oltre tre decenni.",
      de:
        "Es gibt nichts, was nicht weiter verbessert werden kann - dies gilt auch für die Herstellung von Hightech-Leiterplatten. Deshalb arbeiten wir bei Elekonta als Entwicklungspartner seit über drei Jahrzehnten daran, unser Leistungsspektrum für die Elektronikindustrie dauerhaft zu verbessern.",
      es:
        "No hay nada que no pueda mejorarse aún más; esto también se aplica a la producción de placas de circuitos de alta tecnología. Es por eso que en Elekonta como socios de desarrollo trabajamos para mejorar nuestra gama de servicios para la industria electrónica de forma permanente durante más de tres décadas.",
      nl:
        "Er is niets dat niet verder kan worden verbeterd - dit geldt ook voor de productie van hightech printplaten. Daarom werken wij bij Elekonta als ontwikkelingspartners aan het continu verbeteren van ons dienstenaanbod voor de elektronische industrie gedurende meer dan drie decennia.",
    },
    lienInfo: "",
    lienSite: "https://www.elekonta.de/en/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "41",
    name: "Englert Elektronik",
    logo: "../images/img/logo/logo-englert.jpeg",
    type: ["pcb", "pcba"],
    langue: ["EN"],
    nombreCouche: 16,
    quantité: 50,
    delais: 14,
    finitions: [
      "Chemical gold",
      "Chemical tin",
      "HAL-SN100C",
      "Chemical nickel",
    ],
    materiaux: ["FR-4", "FR-3", "PTFE", "Teflon"],
    rigidité: ["Rigide"],
    via: ["Buried"],
    couleursVernis: ["Green", "Black", "Blue", "Red", "White"],
    description: {
      fr:
        "Englert elektronik GmbH est votre prestataire de services pour la production de composants électroniques et mécatroniques. Après 70 ans d'histoire de l'entreprise et plus de 30 ans d'expérience dans le développement, l'électronique et la production mécanique, nous vous proposons une gamme complète de services. Nous mettons en œuvre vos idées.",
      en:
        "Englert elektronik GmbH is your service provider for the production of electronic and mechatronic components. After 70 years of company history and more than 30 years of experience in development, electronics and mechanical production, we offer you a comprehensive range of services. We implement your ideas.",
      it:
        "Englert elektronik GmbH è il vostro fornitore di servizi per la produzione di componenti elettronici e meccatronici. Dopo 70 anni di storia dell'azienda e oltre 30 anni di esperienza nello sviluppo, nell'elettronica e nella produzione meccanica, offriamo una gamma completa di servizi. Realizziamo le tue idee.",
      de:
        "Die englert elektronik GmbH ist Ihr Dienstleister für die Fertigung von elektronischen und mechatronischen Komponenten. Nach 70 jähriger Firmengeschichte und mehr als 30 Jahren Erfahrung in der Entwicklung, Elektronik und Mechanikfertigung bieten wir Ihnen ein umfassendes Leistungsspektrum. Wir setzen Ihre Ideen um.",
      es:
        "Englert elektronik GmbH es su proveedor de servicios para la producción de componentes electrónicos y mecatrónicos. Después de 70 años de historia de la compañía y más de 30 años de experiencia en desarrollo, electrónica y producción mecánica, le ofrecemos una amplia gama de servicios. Implementamos tus ideas.",
      nl:
        "Englert elektronik GmbH is uw dienstverlener voor de productie van elektronische en mechatronische componenten. Na 70 jaar bedrijfsgeschiedenis en meer dan 30 jaar ervaring in ontwikkeling, elektronica en mechanische productie, bieden wij u een uitgebreid dienstenpakket aan. We implementeren uw ideeën.",
    },
    lienInfo: "",
    lienSite: "https://www.englert-berlin.de/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "42",
    name: "Enzmann",
    logo: "../images/img/logo/logo-ensmann.jpeg",
    type: ["pcb", "pcba"],
    langue: ["EN", "DE"],
    nombreCouche: 24,
    quantité: 50,
    delais: 6,
    finitions: ["Chemical gold", "Gold electro"],
    materiaux: ["FR-4"],
    rigidité: ["Rigide"],
    via: ["Buried", "Laser"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "Nous fournissons des PCB de haute qualité pour toutes les industries et des applications sophistiquées telles que l'automobile, l'industrie, la médecine et l'aérospatiale.",
      en:
        "We deliver high quality PCBs for all industries and sophisticated applications such as automotive, Industrial, medical, as well as aerospace.",
      it:
        "Forniamo PCB di alta qualità per tutti i settori e applicazioni sofisticate come quelle automobilistiche, industriali, mediche e aerospaziali.",
      de:
        "Wir liefern hochwertige Leiterplatten für alle Branchen und anspruchsvollen Anwendungen wie Automobil, Industrie, Medizin sowie Luft- und Raumfahrt.",
      es:
        "Ofrecemos PCB de alta calidad para todas las industrias y aplicaciones sofisticadas, como automotriz, industrial, médica y aeroespacial.",
      nl:
        "Wij leveren PCB's van hoge kwaliteit voor alle industrieën en geavanceerde toepassingen zoals automotive, industrieel, medisch en ruimtevaart.",
    },
    lienInfo: "",
    lienSite: "http://kcegermany.de/en/home.html?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "43",
    name: "EPC ELREHA",
    logo: "../images/img/logo/logo-epc-elreha.jpg",
    type: ["pcb"],
    langue: ["EN", "DE", "FR"],
    nombreCouche: 36,
    quantité: 50,
    delais: 3,
    finitions: ["Chemical gold", "Chemical nickel"],
    materiaux: ["FR-4", "Polyimide", "PTFE", "Teflon"],
    rigidité: ["Rigide", "Semi flexible", "flexible"],
    via: ["Buried", "Laser"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "Riche de son savoir-faire, EPC ELREHA met à votre disposition ses usines de hautes technicités qui proposent une large gamme de produits. Toutes demandes peut être répondu grâce aux ingénieurs qualifiés qui vous accompagnent tout au long du processus de commande",
      en:
        "Rich in its know-how, EPC ELREHA places its high-tech factories at your disposal which offer a wide range of products. All requests can be answered thanks to qualified engineers who accompany you throughout the order process",
      it:
        "Ricco di know-how, EPC ELREHA mette a disposizione le sue fabbriche ad alta tecnologia che offrono una vasta gamma di prodotti. È possibile rispondere a tutte le richieste grazie a ingegneri qualificati che ti accompagnano durante l'intero processo di ordinazione",
      de:
        "EPC ELREHA ist reich an Know-how und stellt Ihnen seine Hightech-Fabriken zur Verfügung, die eine breite Produktpalette anbieten. Alle Anfragen können dank qualifizierter Ingenieure beantwortet werden, die Sie während des gesamten Bestellvorgangs begleiten",
      es:
        "Rico en su conocimiento, EPC ELREHA pone a su disposición sus fábricas de alta tecnología que ofrecen una amplia gama de productos. Todas las solicitudes pueden ser respondidas gracias a ingenieros calificados que lo acompañan durante todo el proceso de pedido.",
      nl:
        "Rijk aan knowhow stelt EPC ELREHA zijn hightech fabrieken tot uw beschikking die een breed scala aan producten aanbieden. Alle verzoeken kunnen worden beantwoord dankzij gekwalificeerde ingenieurs die u begeleiden tijdens het bestelproces",
    },
    lienInfo: "",
    lienSite: "https://www.epc-elreha.com/en/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "44",
    name: "ETS",
    logo: "../images/img/logo/logo-ets.jpeg",
    type: ["pcb"],
    langue: ["EN"],
    nombreCouche: 32,
    quantité: 50,
    delais: 14,
    finitions: [],
    materiaux: ["CEM-1", "CEM-3", "Polyimide", "FR-4"],
    rigidité: ["Rigide", "Semi flexible", "flexible"],
    via: ["Buried", "Laser"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "La prestation de services techniques de construction de la plus haute qualité est l'objectif principal d'ETS GmbH.",
      en:
        "Providing the highest quality technical building services is the core focus of ETS  GmbH.",
      it:
        "Fornire servizi tecnici di costruzione di altissima qualità è l'obiettivo principale di ETS GmbH.",
      de:
        "Die Bereitstellung technischer Gebäudetechnik von höchster Qualität steht im Mittelpunkt der ETS GmbH.",
      es:
        "Brindar servicios de construcción técnica de la más alta calidad es el enfoque principal de ETS GmbH.",
      nl:
        "Het leveren van technisch hoogwaardige bouwdiensten is de kern van ETS GmbH.",
    },
    lienInfo: "",
    lienSite: "http://www.ets-gmbh.com/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "45",
    name: "Fischer printed circuit board",
    logo: "../images/img/logo/logo-fisher.jpg",
    type: ["pcb"],
    langue: ["DE"],
    nombreCouche: 16,
    quantité: 50,
    delais: 14,
    finitions: [],
    materiaux: ["FR-4", "FR-2", "CEM-1"],
    rigidité: ["Rigide", "Semi flexible", "flexible"],
    via: ["Buried"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "Depuis un demi-siècle, nous fournissons aux clients de toute l'Europe des cartes de circuits imprimés - la base de vos idées. La qualité constante, la fiabilité et le rythme constant avec les dernières technologies sont les choses que nos clients apprécient à notre sujet.",
      en:
        "For half a century, we have been supplying customers from all over Europe with printed circuit boards - the basis of your ideas. Constant quality, reliability and constant pace with the latest technology are the things that our customers appreciate about us.",
      it:
        "Da mezzo secolo forniamo ai clienti di tutta Europa circuiti stampati: la base delle tue idee. Qualità costante, affidabilità e ritmo costante con le ultime tecnologie sono le cose che i nostri clienti apprezzano di noi.",
      de:
        "Seit einem halben Jahrhundert beliefern wir Kunden aus ganz Europa mit Leiterplatten - die Grundlage Ihrer Ideen. Ständige Qualität, Zuverlässigkeit und konstantes Tempo mit der neuesten Technologie sind die Dinge, die unsere Kunden an uns schätzen.",
      es:
        "Durante medio siglo, hemos estado suministrando a los clientes de toda Europa placas de circuito impreso, la base de sus ideas. Calidad constante, confiabilidad y ritmo constante con la última tecnología son las cosas que nuestros clientes aprecian de nosotros.",
      nl:
        "Al een halve eeuw voorzien wij klanten uit heel Europa van printplaten - de basis van uw ideeën. Constante kwaliteit, betrouwbaarheid en constant tempo met de nieuwste technologie zijn de dingen die onze klanten over ons waarderen.",
    },
    lienInfo: "",
    lienSite: "https://www.pcb-supermarkt.de/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "46",
    name: "HANS NEUKIRCHNER",
    logo: "../images/img/logo/logo-hans.png",
    type: ["pcb"],
    langue: ["DE"],
    nombreCouche: 16,
    quantité: 50,
    delais: 14,
    finitions: [
      "HAL standard",
      "HAL lead free",
      "Chemical gold",
      "Gold electro",
      "Chemical tin",
      "ENEPIG",
      "Chemical nickel",
      "OSP",
    ],
    materiaux: ["CEM-1", "CEM-3", "FR-4"],
    rigidité: ["Rigide"],
    via: ["Buried"],
    couleursVernis: [
      "Green",
      "Black",
      "White",
      "Blue",
      "Yellow",
      "Red",
      "Matt Green",
    ],
    description: {
      fr:
        "En tant qu'entreprise indépendante et gérée par son propriétaire, basée à Bischoffen, dans le centre de la Hesse, nous produisons des cartes de circuits imprimés de haute qualité avec une grande fiabilité fonctionnelle.",
      en:
        "As an independent, owner-managed company based in Bischoffen in central Hesse, we produce high-quality printed circuit boards with high functional reliability.",
      it:
        "Come società indipendente, gestita dal proprietario, con sede a Bischoffen, nell'Assia centrale, produciamo circuiti stampati di alta qualità con elevata affidabilità funzionale.",
      de:
        "Als unabhängiges, inhabergeführtes Unternehmen mit Sitz in Bischoffen im Zentrum von Hessen produzieren wir hochwertige Leiterplatten mit hoher Funktionssicherheit.",
      es:
        "Como empresa independiente y administrada por el propietario con sede en Bischoffen, en el centro de Hesse, producimos tarjetas de circuitos impresos de alta calidad con alta confiabilidad funcional.",
      nl:
        "Als onafhankelijk, door de eigenaar geleid bedrijf gevestigd in Bischoffen in het centrum van Hessen, produceren wij hoogwaardige printplaten met een hoge functionele betrouwbaarheid.",
    },
    lienInfo: "",
    lienSite: "https://www.neukirchner.de/#page?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "47",
    name: "Heidenhain Microprint",
    logo: "../images/img/logo/logo-heidenhain.jpg",
    type: ["pcb"],
    langue: ["DE"],
    nombreCouche: 16,
    quantité: 50,
    delais: 14,
    finitions: [
      "HAL standard",
      "Chemical gold",
      "Gold electro",
      "Chemical tin",
      "ENEPIG",
      "HAL-SN100C",
      "Chemical nickel",
    ],
    materiaux: ["FR-4", "PTFE", "Kapton", "Polyimide", "Teflon"],
    rigidité: ["Rigide", "Semi flexible", "flexible"],
    via: ["Buried"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "Plus de 60 ans d'expérience dans la production de cartes de circuits imprimés et une concentration constante sur le segment des clients industriels nous permettent de répondre aux exigences logistiques, qualitatives et technologiques élevées de nos clients.",
      en:
        "Over 60 years of experience in the production of printed circuit boards and a constant focus on the industrial customer segment allow us to meet the high logistical, qualitative and technological requirements of our customers.",
      it:
        "Oltre 60 anni di esperienza nella produzione di circuiti stampati e una costante attenzione al segmento dei clienti industriali ci consentono di soddisfare gli elevati requisiti logistici, qualitativi e tecnologici dei nostri clienti.",
      de:
        "Über 60 Jahre Erfahrung in der Herstellung von Leiterplatten und eine ständige Fokussierung auf das industrielle Kundensegment ermöglichen es uns, die hohen logistischen, qualitativen und technologischen Anforderungen unserer Kunden zu erfüllen.",
      es:
        "Más de 60 años de experiencia en la producción de placas de circuito impreso y un enfoque constante en el segmento de clientes industriales nos permiten cumplir con los altos requisitos logísticos, cualitativos y tecnológicos de nuestros clientes.",
      nl:
        "Dankzij meer dan 60 jaar ervaring in de productie van printplaten en een constante focus op het industriële klantensegment, kunnen we voldoen aan de hoge logistieke, kwalitatieve en technologische eisen van onze klanten.",
    },
    lienInfo: "",
    lienSite: "https://www.hmp-heidenhain.de/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "48",
    name: "Hofmann Leiterplatten",
    logo: "../images/img/logo/logo-hofman.jpeg",
    type: ["pcb", "pcba"],
    langue: ["EN", "DE"],
    nombreCouche: 6,
    quantité: 50,
    delais: 1,
    finitions: ["HAL standard", "HAL lead free", "HAL-SN100C"],
    materiaux: ["CEM-1", "CEM-3", "FR-1", "FR-2", "FR-4", "Duroid"],
    rigidité: ["Rigide", "Semi flexible", "flexible"],
    via: ["Buried", "Laser"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "Grâce à plus de 30 ans d'expérience et au savoir-faire associé, nos domaines d'expertise sont passés de la production de circuits imprimés purs au développement de composants électroniques, à l'assemblage de circuits imprimés, à l'assemblage de modules et à la fabrication de composants électroniques mécaniques et d'auxiliaires de soudage.  ",
      en:
        "Thanks to more than 30 years of experience and associated know-how, our areas of expertise have gone from the production of pure printed circuits to the development of electronic components, to the assembly of printed circuits, to the assembly of modules. and the manufacture of mechanical electronic components and welding aids.",
      it:
        "Grazie a oltre 30 anni di esperienza e know-how associato, le nostre aree di competenza sono passate dalla produzione di circuiti stampati puri allo sviluppo di componenti elettronici, all'assemblaggio di circuiti stampati, all'assemblaggio di moduli. e la fabbricazione di componenti elettronici meccanici e ausili per la saldatura.",
      de:
        "Dank mehr als 30 Jahren Erfahrung und dem damit verbundenen Know-how haben sich unsere Fachgebiete von der Herstellung reiner gedruckter Schaltungen über die Entwicklung elektronischer Komponenten, die Montage gedruckter Schaltungen bis hin zur Montage von Modulen erweitert. und die Herstellung von mechanischen elektronischen Bauteilen und Schweißhilfen.",
      es:
        "Gracias a más de 30 años de experiencia y los conocimientos asociados, nuestras áreas de especialización han pasado de la producción de circuitos impresos puros al desarrollo de componentes electrónicos, al ensamblaje de circuitos impresos, al ensamblaje de módulos. y la fabricación de componentes electrónicos mecánicos y ayudas de soldadura.",
      nl:
        "Dankzij meer dan 30 jaar ervaring en bijbehorende knowhow zijn onze expertisegebieden uitgegroeid van de productie van pure gedrukte schakelingen tot de ontwikkeling van elektronische componenten, de assemblage van gedrukte schakelingen en de assemblage van modules. en de vervaardiging van mechanische elektronische componenten en lashulpmiddelen.",
    },
    lienInfo: "",
    lienSite: "https://www.hofmannlp.de/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "49",
    name: "HOTOPRINT Elektronik",
    logo: "../images/img/logo/logo-horoprint.jpeg",
    type: ["pcb"],
    langue: ["EN", "DE"],
    nombreCouche: 16,
    quantité: 50,
    delais: 1,
    finitions: [
      "HAL standard",
      "HAL lead free",
      "Gold electro",
      "Chemical tin",
      "HAL-SN100C",
    ],
    materiaux: ["FR-4", "Polyimide"],
    rigidité: ["Rigide", "Semi flexible", "flexible"],
    via: ["Buried", "Laser"],
    couleursVernis: ["Green", "Black", "Red", "Blue", "Yellow"],
    description: {
      fr:
        "Depuis 46 ans maintenant, les produits Hotoprint sont connus pour leur qualité et leur valeur exceptionnelles. Nous sommes une entreprise familiale et fabriquons exclusivement en Allemagne.",
      en:
        "For 46 years now, Hotoprint’s products are known for its outstanding quality and value. We are a family-owned company and manufacture exclusively in Germany. ",
      it:
        "Da 46 anni, i prodotti Hotoprint sono noti per la loro eccezionale qualità e valore. Siamo un'azienda a conduzione familiare e produciamo esclusivamente in Germania.",
      de:
        "Die Produkte von Hotoprint sind seit 46 Jahren für ihre hervorragende Qualität und ihren Wert bekannt. Wir sind ein Familienunternehmen und produzieren ausschließlich in Deutschland.",
      es:
        "Desde hace 46 años, los productos de Hotoprint son conocidos por su excelente calidad y valor. Somos una empresa familiar y fabricamos exclusivamente en Alemania.",
      nl:
        "Al 46 jaar staan ​​de producten van Hotoprint bekend om hun uitstekende kwaliteit en waarde. Wij zijn een familiebedrijf en produceren uitsluitend in Duitsland.",
    },
    lienInfo: "",
    lienSite: "https://hotoprint-pcb.de/index.php/en/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "50",
    name: "ILFA",
    logo: "../images/img/logo/logo-ifla.jpeg",
    type: ["pcb"],
    langue: ["EN", "DE"],
    nombreCouche: 32,
    quantité: 50,
    delais: 10,
    finitions: [
      "Chemical gold",
      "Gold electro",
      "Chemical silver",
      "Chemical tin",
      "OSP",
    ],
    materiaux: [
      "Duroid",
      "Kapton",
      "Telfon",
      "Polyimide",
      "PTFE",
      "Pyralux",
      "Polyethylene",
      "FR-4",
    ],
    rigidité: ["Rigide", "Semi flexible", "flexible"],
    via: ["Buried"],
    couleursVernis: ["Green", "Black", "Blue", "Matt Black"],
    description: {
      fr:
        "PCB High-Tech d'ILFA - Nous connectons vos innovations. Le propriétaire et fondateur de la société ILFA a développé et fabriqué des PCB depuis plus de 40 ans. Avec l'équipe d'experts dans les domaines de la physique, de la chimie, de l'ingénierie et de la technique, l'entreprise développe une technologie de conducteurs fins et microfins.",
      en:
        "High-Tech-PCBs of ILFA – We connect your innovations. The owner and founder operated company ILFA has developed and manufactured PCB´s for over 40 years. With the team of experts in the fields of physics, chemistry, engineering and technique the company develops fine and microfine conductor technology.",
      it:
        "PCB high-tech di ILFA - Connettiamo le tue innovazioni. Il proprietario e fondatore della società ILFA ha sviluppato e prodotto PCB per oltre 40 anni. Con il team di esperti nei settori della fisica, della chimica, dell'ingegneria e della tecnica, l'azienda sviluppa la tecnologia dei conduttori per microfine e fini.",
      de:
        "High-Tech-Leiterplatten von ILFA - Wir verbinden Ihre Innovationen. Das vom Eigentümer und Gründer betriebene Unternehmen ILFA entwickelt und fertigt seit über 40 Jahren Leiterplatten. Mit dem Expertenteam aus den Bereichen Physik, Chemie, Ingenieurwesen und Technik entwickelt das Unternehmen die Fein- und Mikrofeinleitertechnologie.",
      es:
        "PCB de alta tecnología de ILFA: conectamos sus innovaciones. La empresa operadora propietaria y fundadora ILFA ha desarrollado y fabricado PCB durante más de 40 años. Con el equipo de expertos en los campos de física, química, ingeniería y técnica, la compañía desarrolla tecnología de conductores finos y microfinos.",
      nl:
        "High-Tech-PCB's van ILFA - We verbinden uw innovaties. De eigenaar en oprichter van het bedrijf ILFA ontwikkelt en produceert al meer dan 40 jaar PCB's. Met het team van experts op het gebied van natuurkunde, scheikunde, engineering en techniek ontwikkelt het bedrijf fijne en microfijne geleidertechnologie.",
    },
    lienInfo: "",
    lienSite: "https://www.ilfa.de/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "51",
    name: "I-R-P Computer & Prüftechnik",
    logo: "../images/img/logo/logo-irp.jpeg",
    type: ["pcb", "pcba"],
    langue: ["EN", "DE"],
    nombreCouche: 16,
    quantité: 50,
    delais: 14,
    finitions: [
      "HAL standard",
      "HAL lead free",
      "Gold electro",
      "Chemical silver",
      "Chemical tin",
      "OSP",
    ],
    materiaux: ["CEM-1", "CEM-5", "FR-4", "FR-3"],
    rigidité: ["Rigide"],
    via: ["Buried"],
    couleursVernis: ["White", "Yellow", "Black"],
    description: {
      fr:
        "I-R-P Computer & Prüftechnik Vertriebs-GmbH est une entreprise en pleine croissance dans le domaine des services de fabrication électronique (EMS) axée sur la production de circuits imprimés nus et l'achat de matériaux pour les composants électroniques",
      en:
        "I-R-P Computer & Prüftechnik Vertriebs-GmbH is a growing company in the field of Electronic Manufacturing Services (EMS) focused on Bare circuit board production and Material purchasing for electronic components",
      it:
        "I-R-P Computer & Prüftechnik Vertriebs-GmbH è una società in crescita nel campo dei servizi di produzione elettronica (EMS) focalizzata sulla produzione di circuiti stampati e sull'acquisto di materiali per componenti elettronici",
      de:
        "Die I-R-P Computer & Prüftechnik Vertriebs-GmbH ist ein wachsendes Unternehmen im Bereich Electronic Manufacturing Services (EMS), das sich auf die Herstellung von Leiterplatten und den Materialeinkauf für elektronische Komponenten konzentriert",
      es:
        "I-R-P Computer & Prüftechnik Vertriebs-GmbH es una empresa en crecimiento en el campo de los Servicios de fabricación electrónica (EMS) centrada en la producción de placas de circuito desnudo y la compra de materiales para componentes electrónicos",
      nl:
        "I-R-P Computer & Prüftechnik Vertriebs-GmbH is een groeiend bedrijf op het gebied van Electronic Manufacturing Services (EMS) dat zich richt op de productie van kale printplaten en materiaalinkoop voor elektronische componenten",
    },
    lienInfo: "",
    lienSite: "http://www.irp-net.de/home.html?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "52",
    name: "Jenaer Leiterplatten",
    logo: "../images/img/logo/logo-jenaer.jpeg",
    type: ["pcb"],
    langue: ["DE"],
    nombreCouche: 24,
    quantité: 50,
    delais: 14,
    finitions: [
      "HAL standard",
      "Gold electro",
      "Chemical silver",
      "HAL-SN100C",
      "Chemical nickel",
      "OSP",
    ],
    materiaux: [],
    rigidité: ["Rigide", "Semi flexible", "flexible"],
    via: ["Buried"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "Nous fabriquons vos circuits imprimés sur le site de haute technologie d'Iéna depuis plus de 25 ans. Une hiérarchie plate et des canaux d'information courts nous garantissent une réactivité flexible lors du traitement de vos commandes et le respect des délais de livraison.",
      en:
        "We have been manufacturing your printed circuits on the high-tech site of Iéna for more than 25 years. A flat hierarchy and short information channels guarantee us flexible responsiveness when processing your orders and meeting delivery deadlines.",
      it:
        "Da oltre 25 anni produciamo i vostri circuiti stampati sul sito high-tech di Iéna. Una gerarchia piatta e brevi canali informativi ci garantiscono una reattività flessibile durante l'elaborazione degli ordini e il rispetto delle scadenze di consegna.",
      de:
        "Wir stellen Ihre gedruckten Schaltungen seit mehr als 25 Jahren auf dem High-Tech-Gelände von Iéna her. Eine flache Hierarchie und kurze Informationskanäle garantieren uns eine flexible Reaktionsfähigkeit bei der Bearbeitung Ihrer Bestellungen und der Einhaltung der Lieferfristen.",
      es:
        "Llevamos más de 25 años fabricando sus circuitos impresos en el sitio de alta tecnología de Iéna. Una jerarquía plana y canales de información cortos nos garantizan una capacidad de respuesta flexible al procesar sus pedidos y cumplir con los plazos de entrega.",
      nl:
        "Wij vervaardigen uw printplaten al meer dan 25 jaar op de hoogtechnologische site van Iéna. Een vlakke hiërarchie en korte informatiekanalen garanderen ons een flexibel reactievermogen bij het verwerken van uw bestellingen en het respecteren van levertijden.",
    },
    lienInfo: "",
    lienSite: "https://www.jlp.de/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "53",
    name: "Jks Leiterplatten",
    logo: "../images/img/logo/logo-jks.jpg",
    type: ["pcb"],
    langue: ["DE"],
    nombreCouche: 20,
    quantité: 50,
    delais: 14,
    finitions: ["Gold electro", "Chemical silver", "HAL-SN100C"],
    materiaux: ["FR-4"],
    rigidité: ["Rigide"],
    via: ["Buried"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "Vos besoins individuels d'échantillons et de production en série ou vos besoins de remplacement en tenant dûment compte des délais de livraison les plus rapides - c'est notre profession.",
      en:
        "Your individual needs for samples and mass-production or your replacement requirements in due consideration of fastest deliverytimes - that is our profession.",
      it:
        "Le vostre esigenze individuali per i campioni e la produzione in serie o i requisiti di sostituzione nel dovuto rispetto dei tempi di consegna più rapidi - questa è la nostra professione.",
      de:
        "Ihre individuellen Bedürfnisse nach Mustern und Massenproduktion oder Ihre Ersatzanforderungen unter Berücksichtigung der schnellsten Lieferzeiten - das ist unser Beruf.",
      es:
        "Sus necesidades individuales de muestras y producción en masa o sus requisitos de reemplazo teniendo en cuenta los tiempos de entrega más rápidos, esa es nuestra profesión.",
      nl:
        "Uw individuele behoeften aan monsters en massaproductie of uw vervangingsvereisten rekening houdend met de snelste levertijden - dat is ons beroep.",
    },
    lienInfo: "",
    lienSite: "http://www.jks-leiterplatten.de/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "54",
    name: "KAWA",
    logo: "../images/img/logo/logo-kawa.jpeg",
    type: ["pcb"],
    langue: ["EN", "DE"],
    nombreCouche: 16,
    quantité: 50,
    delais: 14,
    materiaux: [],
    finitions: [],
    rigidité: ["Rigide"],
    via: ["Buried"],
    couleursVernis: [],
    description: {
      fr:
        "Depuis de nombreuses années, nous commercialisons des équipements d'occasion pour l'industrie des PCB que nous achetons individuellement ou en forfaits. Nous avons également repris des usines complètes de PCB ou de grandes parties d'entre elles. L'expérience acquise lors du démontage de cet équipement, de son emballage, de son chargement et de sa remise en place, y compris les tests d'eau pour les lignes de traitement par voie humide, nous proposons désormais un service aux clients intéressés.",
      en:
        "Since many years we have been trading in used equipment for the PCB industry which we purchase individually or in package deals. We have also taken over complete PCB plants or large parts of them. The experience gained through dismantling of this equipment, packing, loading and setting it up again, including water tests for wet process lines, we now offer as a service to interested customers.",
      it:
        "Da molti anni commerciamo in apparecchiature usate per l'industria dei PCB che acquistiamo singolarmente o in pacchetti. Abbiamo anche rilevato impianti PCB completi o parti di essi. L'esperienza acquisita attraverso lo smantellamento di questa apparecchiatura, l'imballaggio, il caricamento e la sua installazione, compresi i test dell'acqua per le linee di processo a umido, offriamo ora un servizio ai clienti interessati.",
      de:
        "Seit vielen Jahren handeln wir mit gebrauchten Geräten für die Leiterplattenindustrie, die wir einzeln oder in Pauschalangeboten kaufen. Wir haben auch komplette Leiterplattenanlagen oder große Teile davon übernommen. Die Erfahrungen, die wir durch Demontage, Verpackung, Verladung und erneutes Einrichten dieser Geräte gesammelt haben, einschließlich Wassertests für nasse Prozesslinien, bieten wir nun interessierten Kunden als Service an.",
      es:
        "Desde hace muchos años, comercializamos equipos usados ​​para la industria de PCB que compramos individualmente o en paquetes. También hemos asumido el control de plantas completas de PCB o grandes partes de ellas. La experiencia adquirida al desmontar este equipo, empaquetarlo, cargarlo y configurarlo nuevamente, incluidas las pruebas de agua para líneas de proceso húmedo, ahora lo ofrecemos como un servicio a los clientes interesados.",
      nl:
        "Wij handelen al jaren in gebruikte apparatuur voor de PCB-industrie die wij individueel of in pakketdeals inkopen. We hebben ook complete PCB-fabrieken of grote delen daarvan overgenomen. De ervaring die is opgedaan met het demonteren van deze apparatuur, het inpakken, laden en opnieuw opzetten, inclusief watertesten voor natte proceslijnen, bieden we nu als service aan geïnteresseerde klanten.",
    },
    lienInfo: "",
    lienSite: "https://www.kawa-gmbh.com/en/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "55",
    name: "KSG Leiterplatten",
    logo: "../images/img/logo/logo-ksg.png",
    type: ["pcb"],
    langue: ["EN", "DE"],
    nombreCouche: 16,
    quantité: 50,
    delais: 14,
    finitions: [
      "HAL standard",
      "HAL lead free",
      "Gold electro",
      "Chemical tin",
      "OSP",
    ],
    materiaux: ["FR-4", "FR-3", "FR-2", "FR-1"],
    rigidité: ["Rigide", "Semi flexible", "flexible"],
    via: ["Buried"],
    couleursVernis: ["Green", "Black", "Red", "Yellow", "Matt Black", "White"],
    description: {
      fr:
        "Le Groupe KSG développe et fabrique des PCB pour le marché international exclusivement en Europe. KSG produit des technologies pionnières à partir d'échantillons jusqu'aux grandes séries.",
      en:
        "The KSG Group develops and manufactures PCBs for the international market exclusively in Europe. KSG produces pioneering technologies from samples up to large series.",
      it:
        "Il gruppo KSG sviluppa e produce PCB per il mercato internazionale esclusivamente in Europa. KSG produce tecnologie pionieristiche dai campioni alle grandi serie.",
      de:
        "Die KSG Group entwickelt und fertigt Leiterplatten für den internationalen Markt ausschließlich in Europa. KSG produziert wegweisende Technologien von Mustern bis hin zu großen Serien.",
      es:
        "El Grupo KSG desarrolla y fabrica PCB para el mercado internacional exclusivamente en Europa. KSG produce tecnologías pioneras desde muestras hasta series grandes.",
      nl:
        "De KSG Groep ontwikkelt en produceert PCB's exclusief voor de internationale markt in Europa. KSG produceert baanbrekende technologieën van monsters tot grote series.",
    },
    lienInfo: "",
    lienSite: "https://www.ksg-pcb.com/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "56",
    name: "LeitOn GmbH",
    logo: "../images/img/logo/logo-leiton.jpeg",
    type: ["pcb"],
    langue: ["EN", "DE"],
    nombreCouche: 24,
    quantité: 50,
    delais: 14,
    finitions: [
      "HAL standard",
      "Gold electro",
      "Chemical silver",
      "ENEPIG",
      "HAL-SN100C",
      "OSP",
    ],
    materiaux: ["FR-4"],
    rigidité: ["Rigide"],
    via: ["Buried"],
    couleursVernis: ["Green", "Black", "White", "Blue", "Red", "Transparent"],
    description: {
      fr:
        "LeitOn est un fabricant de PCB de haute technologie basé à Berlin, en Allemagne. En plus de cela, nous possédons un réseau de différents fabricants internationaux de PCB de haute qualité afin que nous puissions également fournir des circuits imprimés de production de masse.",
      en:
        "LeitOn is a high-tech PCB-manufacturer based in Berlin, Germany. On top of that we possess a network of different high quality international PCB manufacturers so that we can also supply mass production printed circuits.",
      it:
        "LeitOn è un produttore di PCB ad alta tecnologia con sede a Berlino, in Germania. Inoltre, disponiamo di una rete di diversi produttori internazionali di PCB di alta qualità in modo da poter fornire anche circuiti stampati di produzione in serie.",
      de:
        "LeitOn ist ein Hightech-Leiterplattenhersteller mit Sitz in Berlin. Darüber hinaus verfügen wir über ein Netzwerk verschiedener hochwertiger internationaler Leiterplattenhersteller, so dass wir auch gedruckte Schaltungen für die Massenproduktion liefern können.",
      es:
        "LeitOn es un fabricante de PCB de alta tecnología con sede en Berlín, Alemania. Además de eso, poseemos una red de diferentes fabricantes internacionales de PCB de alta calidad para que también podamos suministrar circuitos impresos de producción en masa.",
      nl:
        "LeitOn is een hightech PCB-fabrikant gevestigd in Berlijn, Duitsland. Bovendien beschikken we over een netwerk van verschillende hoogwaardige internationale PCB-fabrikanten zodat we ook massaproductie printplaten kunnen leveren.",
    },
    lienInfo: "",
    lienSite: "https://www.leiton.de/pcb-manufacturer.html?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "57",
    name: "Lüberg Elektronik",
    logo: "../images/img/logo/logo-luberg.jpeg",
    type: ["pcb"],
    langue: ["EN", "DE"],
    nombreCouche: 16,
    quantité: 50,
    delais: 14,
    finitions: [],
    materiaux: ["Teflon"],
    rigidité: ["Rigide", "Semi flexible", "flexible"],
    via: ["Buried"],
    couleursVernis: ["Green"],
    description: {
      fr:
        "Au cours des 40 années qui ont vu le développement des circuits imprimés à un rythme effréné, nous, en tant que spécialiste du domaine, avons participé à ce développement et avons contribué à le façonner. Notre expertise très appréciée nous a aidés à atteindre notre position en tant que l'un des principaux fabricants de cartes de circuits imprimés.",
      en:
        "Over the 40 years which has seen the printed-circuit board develop at a tremendous pace, we as a specialist in the field have been part of that development and helped shape it. Our highly valued expertise has helped us to achieve our position as one of the leading manufacturers of printed-circuit boards.",
      it:
        "Nel corso dei 40 anni che hanno visto lo sviluppo del circuito stampato ad un ritmo eccezionale, noi come specialisti del settore abbiamo fatto parte di questo sviluppo e abbiamo contribuito a dargli forma. La nostra preziosa esperienza ci ha aiutato a raggiungere la nostra posizione come uno dei principali produttori di circuiti stampati.",
      de:
        "In den 40 Jahren, in denen sich die Leiterplatte rasant entwickelt hat, waren wir als Spezialist auf diesem Gebiet Teil dieser Entwicklung und haben sie mitgeprägt. Unsere hoch geschätzte Expertise hat uns geholfen, unsere Position als einer der führenden Hersteller von Leiterplatten zu erreichen.",
      es:
        "Durante los 40 años que han visto el desarrollo de la placa de circuito impreso a un ritmo tremendo, nosotros como especialistas en el campo hemos sido parte de ese desarrollo y hemos ayudado a darle forma. Nuestra experiencia altamente valorada nos ha ayudado a lograr nuestra posición como uno de los principales fabricantes de placas de circuito impreso.",
      nl:
        "In de 40 jaar dat de printplaat zich in een enorm tempo heeft ontwikkeld, hebben we als specialist in het veld deel uitgemaakt van die ontwikkeling en hebben we eraan meegewerkt. Onze zeer gewaardeerde expertise heeft ons geholpen om onze positie als een van de toonaangevende fabrikanten van printplaten te bereiken.",
    },
    lienInfo: "",
    lienSite: "http://www.lueberg.de/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "58",
    name: "Meco Elektronik",
    logo: "../images/img/logo/logo-meco.jpeg",
    type: ["pcb"],
    langue: ["EN"],
    nombreCouche: 40,
    quantité: 50,
    delais: 14,
    finitions: ["Chemical gold", "Gold electro", "Chemical nickel"],
    materiaux: ["CEM-3", "FR-4"],
    rigidité: ["Rigide", "Semi flexible", "flexible"],
    via: ["Buried"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "Vous ne pouvez survivre avec succès que pendant des décennies avec qualité et fiabilité. Notre objectif constant est donc de produire durablement des produits de haute qualité et de réagir avec souplesse aux besoins de nos clients.",
      en:
        "You can only successfully survive for decades with quality and reliability. Our constant objective is therefore to produce high quality products in the long term and to react flexibly to the needs of our customers.",
      it:
        "Puoi sopravvivere con successo per decenni solo con qualità e affidabilità. Il nostro obiettivo costante è quindi quello di produrre prodotti di alta qualità a lungo termine e di reagire in modo flessibile alle esigenze dei nostri clienti.",
      de:
        "Nur mit Qualität und Zuverlässigkeit können Sie jahrzehntelang erfolgreich überleben. Unser ständiges Ziel ist es daher, langfristig qualitativ hochwertige Produkte herzustellen und flexibel auf die Bedürfnisse unserer Kunden zu reagieren.",
      es:
        "Solo puede sobrevivir con éxito durante décadas con calidad y fiabilidad. Por lo tanto, nuestro objetivo constante es producir productos de alta calidad a largo plazo y reaccionar de manera flexible a las necesidades de nuestros clientes.",
      nl:
        "Alleen met kwaliteit en betrouwbaarheid kun je tientallen jaren succesvol overleven. Ons constante doel is dan ook om op lange termijn kwalitatief hoogwaardige producten te produceren en flexibel in te spelen op de wensen van onze klanten.",
    },
    lienInfo: "",
    lienSite: "https://www.meco-leiterplatten.de/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "59",
    name: "Mektec Europe",
    logo: "../images/img/logo/logo-mektec.png",
    type: ["pcb", "pcba"],
    langue: ["EN", "DE"],
    nombreCouche: 8,
    quantité: 50,
    delais: 14,
    finitions: [],
    materiaux: ["FR-4", "Polyimide", "Polyester", "Polyethylene"],
    rigidité: ["Rigide", "Semi flexible", "flexible"],
    via: ["Buried"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "Mektec Europe GmbH est une filiale de Nippon Mektron, le premier fabricant mondial de circuits imprimés flexibles avec des sites de production dans le monde entier.",
      en:
        "Mektec Europe GmbH is a subsidiary company of Nippon Mektron, the world‘s leading manufacturer of flexible printed circuits with production locations worldwide.",
      it:
        "Mektec Europe GmbH è una società sussidiaria di Nippon Mektron, leader mondiale nella produzione di circuiti stampati flessibili con sedi produttive in tutto il mondo.",
      de:
        "Die Mektec Europe GmbH ist eine Tochtergesellschaft von Nippon Mektron, dem weltweit führenden Hersteller flexibler Leiterplatten mit weltweiten Produktionsstandorten.",
      es:
        "Mektec Europe GmbH es una compañía subsidiaria de Nippon Mektron, el fabricante líder mundial de circuitos impresos flexibles con centros de producción en todo el mundo.",
      nl:
        "Mektec Europe GmbH is een dochteronderneming van Nippon Mektron, 's werelds toonaangevende fabrikant van flexibele printplaten met productielocaties wereldwijd.",
    },
    lienInfo: "",
    lienSite: "https://mektec.de/home.html/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "60",
    name: "META Leiterplatten",
    logo: "../images/img/logo/logo-meta.png",
    type: ["pcb"],
    langue: ["DE"],
    nombreCouche: 20,
    quantité: 50,
    delais: 6,
    finitions: ["Chemical tin", "HAL-SN100C", "OSP"],
    materiaux: ["CEM-1", "FR-2", "FR-4", "Polyimide", "Teflon"],
    rigidité: ["Rigide", "Semi flexible", "flexible"],
    via: ["Buried", "Laser"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "Fondée en 2000. Notre stratégie a été un service parfait et des normes de qualité sans compromis depuis le tout début. Nous avons réussi avec cette approche, comme le montre notre croissance régulière.",
      en:
        "Founded in 2000. Our strategy has been perfect service and uncompromising quality standards from the very start. We have succeeded with this approach, as our steady growth shows.",
      it:
        "Fondata nel 2000. La nostra strategia è stata sin dall'inizio un servizio perfetto e standard di qualità senza compromessi. Siamo riusciti con questo approccio, come dimostra la nostra costante crescita.",
      de:
        "Gegründet im Jahr 2000. Unsere Strategie war von Anfang an perfekter Service und kompromisslose Qualitätsstandards. Dieser Ansatz ist uns gelungen, wie unser stetiges Wachstum zeigt.",
      es:
        "Fundada en 2000. Nuestra estrategia ha sido un servicio perfecto y estándares de calidad intransigentes desde el principio. Hemos tenido éxito con este enfoque, como lo demuestra nuestro crecimiento constante.",
      nl:
        "Opgericht in 2000. Onze strategie was vanaf het begin een perfecte service en compromisloze kwaliteitsnormen. We zijn hierin geslaagd, zoals blijkt uit onze gestage groei.",
    },
    lienInfo: "",
    lienSite: "https://www.meta-leiterplatten.de/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "61",
    name: "MicroCirtec",
    logo: "../images/img/logo/logo-microcirtec.jpeg",
    type: ["pcb"],
    langue: ["EN", "DE"],
    nombreCouche: 24,
    quantité: 50,
    delais: 14,
    finitions: [
      "HAL standard",
      "Gold electro",
      "Chemical silver",
      "Chemical tin",
      "ENEPIG",
      "OSP",
    ],
    materiaux: ["FR-4"],
    rigidité: ["Rigide"],
    via: ["Buried", "Laser"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "Nos PCB sont fabriqués exclusivement en Allemagne - c'est notre conviction. C'est notre philosophie en tant qu'entreprise au service d'offrir à nos clients un triangle parfait de qualité, des prix compétitifs et un délai de livraison le plus court possible.",
      en:
        "Our PCB`s are exclusively manufactured in Germany – this is our conviction. It is our philosophy as a serving company to offer our customers a perfect triangle of quality, competitive pricing and shortest possible delivery time.",
      it:
        "I nostri PCB sono prodotti esclusivamente in Germania - questa è la nostra convinzione. La nostra filosofia come azienda al servizio di offrire ai nostri clienti un triangolo perfetto di qualità, prezzi competitivi e tempi di consegna più brevi possibili.",
      de:
        "Unsere Leiterplatten werden ausschließlich in Deutschland hergestellt - das ist unsere Überzeugung. Es ist unsere Philosophie als Dienstleistungsunternehmen, unseren Kunden ein perfektes Dreieck aus Qualität, wettbewerbsfähigen Preisen und kürzester Lieferzeit anzubieten.",
      es:
        "Nuestros PCB se fabrican exclusivamente en Alemania, esta es nuestra convicción. Nuestra filosofía como empresa de servicios es ofrecer a nuestros clientes un triángulo perfecto de calidad, precios competitivos y el menor tiempo de entrega posible.",
      nl:
        "Onze printplaten worden uitsluitend in Duitsland geproduceerd - dat is onze overtuiging. Het is onze filosofie als dienstverlenend bedrijf om onze klanten een perfecte driehoek van kwaliteit, concurrerende prijzen en zo kort mogelijke levertijd te bieden.",
    },
    lienInfo: "",
    lienSite: "https://www.microcirtec.de/en/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "62",
    name: "MOS Electronic",
    logo: "../images/img/logo/logo-mos.jpeg",
    type: ["pcb"],
    langue: ["EN", "DE"],
    nombreCouche: 50,
    quantité: 50,
    delais: 5,
    finitions: [],
    materiaux: ["FR-4"],
    rigidité: ["Rigide", "Semi flexible", "flexible"],
    via: ["Buried", "Laser"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "MOS Electronic peut s'appuyer sur près de trois décennies d'expérience dans la fabrication de circuits imprimés. Grâce à la coopération avec nos partenaires du réseau en Extrême-Orient, nous sommes en mesure de proposer tous les types de cartes de circuits imprimés en toute quantité.",
      en:
        "MOS Electronic can draw on almost three decades of experience in the manufacture of printed circuits. Thanks to the cooperation with our network partners in the Far East, we are able to offer all types of printed circuit boards in any quantity.",
      it:
        "MOS Electronic può contare su quasi trent'anni di esperienza nella produzione di circuiti stampati. Grazie alla collaborazione con i nostri partner di rete in Estremo Oriente, siamo in grado di offrire tutti i tipi di circuiti stampati in qualsiasi quantità.",
      de:
        "MOS Electronic kann auf fast drei Jahrzehnte Erfahrung in der Herstellung von gedruckten Schaltungen zurückgreifen. Dank der Zusammenarbeit mit unseren Netzwerkpartnern in Fernost können wir alle Arten von Leiterplatten in beliebiger Menge anbieten.",
      es:
        "MOS Electronic puede aprovechar casi tres décadas de experiencia en la fabricación de circuitos impresos. Gracias a la cooperación con nuestros socios de red en el Lejano Oriente, podemos ofrecer todo tipo de placas de circuito impreso en cualquier cantidad.",
      nl:
        "MOS Electronic kan bogen op bijna drie decennia ervaring in de productie van gedrukte schakelingen. Dankzij de samenwerking met onze netwerkpartners in het Verre Oosten zijn we in staat om alle soorten printplaten in elke hoeveelheid aan te bieden.",
    },
    lienInfo: "",
    lienSite: "http://www.mos-electronic.com/de/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "63",
    name: "P. & M. Services",
    logo: "../images/img/logo/logo-p&m.png",
    type: ["pcb"],
    langue: ["EN"],
    nombreCouche: 16,
    quantité: 50,
    delais: 14,
    finitions: ["HAL standard", "Chemical gold"],
    materiaux: [],
    rigidité: ["Rigide"],
    via: ["Buried"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "P&M Services (Rochdale) Limited, ou P&M comme nous sommes plus communément appelés, a été fondée en 1964 par l'équipe de mari et femme Peter et Margaret Unwin. Il a ensuite été enregistré sous son nom actuel en 1965.",
      en:
        "P&M Services (Rochdale) Limited, or P&M as we are more commonly named, was founded back in 1964 by Husband and wife team Peter and Margaret Unwin. It was later registered to it's present name in 1965.",
      it:
        "P&M Services (Rochdale) Limited, o P&M come siamo più comunemente chiamati, è stata fondata nel 1964 dal gruppo di marito e moglie Peter e Margaret Unwin. In seguito fu registrato con il nome attuale nel 1965.",
      de:
        "P & M Services (Rochdale) Limited, oder P & M, wie wir allgemein genannt werden, wurde 1964 von dem Ehepaar Peter und Margaret Unwin gegründet. Es wurde später 1965 unter seinem heutigen Namen registriert.",
      es:
        "P&M Services (Rochdale) Limited, o P&M como se nos llama más comúnmente, fue fundada en 1964 por el equipo de marido y mujer Peter y Margaret Unwin. Más tarde se registró con su nombre actual en 1965.",
      nl:
        "P&M Services (Rochdale) Limited, of P&M zoals we vaker worden genoemd, werd in 1964 opgericht door het echtpaar Peter en Margaret Unwin. Het werd later geregistreerd onder zijn huidige naam in 1965.",
    },
    lienInfo: "",
    lienSite: "http://www.p-m-services.co.uk/index.htm?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "64",
    name: "CEBISA France",
    logo: "../images/img/logo/logo-cebisa.jpeg",
    type: ["pcb"],
    langue: ["EN", "FR"],
    nombreCouche: 64,
    quantité: 50,
    delais: 14,
    finitions: [
      "Gold electro",
      "Chemical silver",
      "Chemical tin",
      "HAL-SN100C",
      "Chemical nickel",
      "OSP",
    ],
    materiaux: ["FR-4"],
    rigidité: ["Rigide", "Semi flexible"],
    via: ["Buried", "Laser"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "Nous sommes spécialisés dans l’achat et la revente de circuits imprimés et produits électroniques, composants électroniques spécifiques et l’analyse et le câblage de cartes électronique en Europe.",
      en:
        "We specialize in the purchase and resale of printed circuits and electronic products, specific electronic components and the analysis and wiring of electronic cards in Europe.",
      it:
        "Siamo specializzati nell'acquisto e nella rivendita di circuiti stampati e prodotti elettronici, componenti elettronici specifici e nell'analisi e nel cablaggio di schede elettroniche in Europa.",
      de:
        "Wir sind spezialisiert auf den Kauf und Weiterverkauf von gedruckten Schaltungen und elektronischen Produkten, spezifischen elektronischen Komponenten sowie die Analyse und Verkabelung von elektronischen Karten in Europa.",
      es:
        "Estamos especializados en la compra y reventa de circuitos impresos y productos electrónicos, componentes electrónicos específicos y el análisis y cableado de tarjetas electrónicas en Europa.",
      nl:
        "Wij zijn gespecialiseerd in de aankoop en wederverkoop van gedrukte schakelingen en elektronische producten, specifieke elektronische componenten en de analyse en bedrading van elektronische kaarten in Europa.",
    },
    lienInfo: "",
    lienSite: "https://cebisafrance.com/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "65",
    name: "Cipsa Circuits",
    logo: "../images/img/logo/logo-cipsa.jpg",
    type: ["pcb"],
    langue: ["EN", "FR", "ES", "DE"],
    nombreCouche: 32,
    quantité: 50,
    delais: 5,
    finitions: [
      "HAL lead free",
      "Chemical gold",
      "Gold electro",
      "Chemical silver",
      "Chemical tin",
      "HAL-SN100C",
      "OSP",
    ],
    materiaux: ["CEM-1", "CEM-3", "FR-4"],
    rigidité: ["Rigide", "flexible"],
    via: ["Buried", "Laser"],
    couleursVernis: ["Green", "Black", "White", "Red", "Blue"],
    description: {
      fr:
        "La principale activité du Groupe CIPSA est la production de cartes de circuits imprimés, fabriquées selon les spécifications et les exigences de nos clients. CIPSA produit toute la gamme de circuits imprimés, du prototype à la production de masse.",
      en:
        "In CIPSA Group’s main business is the production of printed circuit boards, manufactured according to the specifications and requirements of our customers. CIPSA produces the whole range of printed circuit, from prototype to mass production.",
      it:
        "Nella principale attività del Gruppo CIPSA è la produzione di circuiti stampati, realizzati secondo le specifiche e le esigenze dei nostri clienti. CIPSA produce l'intera gamma di circuiti stampati, dal prototipo alla produzione in serie.",
      de:
        "Das Hauptgeschäft der CIPSA Group ist die Herstellung von Leiterplatten, die nach den Spezifikationen und Anforderungen unserer Kunden hergestellt werden. CIPSA produziert die gesamte Palette der gedruckten Schaltungen, vom Prototyp bis zur Massenproduktion.",
      es:
        "El negocio principal del Grupo CIPSA es la producción de placas de circuito impreso, fabricadas de acuerdo con las especificaciones y requisitos de nuestros clientes. CIPSA produce toda la gama de circuitos impresos, desde prototipos hasta producción en masa.",
      nl:
        "De hoofdactiviteit van de CIPSA Group is de productie van printplaten, vervaardigd volgens de specificaties en eisen van onze klanten. CIPSA produceert het hele scala aan printplaten, van prototype tot massaproductie.",
    },
    lienInfo: "",
    lienSite: "http://www.cipsacircuits.com/en/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "66",
    name: "CIRLAN s.l.",
    logo: "../images/img/logo/logo-cirlan.jpeg",
    type: ["pcb", "pcba"],
    langue: ["EN", "FR", "ES"],
    nombreCouche: 8,
    quantité: 50,
    delais: 14,
    finitions: [
      "HAL standard",
      "Gold electro",
      "Chemical silver",
      "Chemical tin",
      "HAL-SN100C",
      "OSP",
    ],
    materiaux: ["CEM-1", "CEM-3", "FR-2", "FR-4"],
    rigidité: ["Rigide"],
    via: ["Buried", "Laser"],
    couleursVernis: ["Green", "Black", "Blue", "Red"],
    description: {
      fr:
        "Depuis 2009 Cirlan propose une large gamme de circuits imprimés, autant en simple face, double face sans métallisation qu’en circuits pour technologie LED sur base Aluminium. Stratégiquement, nous sommes une référence sur le marché des fabriquants de circuits imprimés",
      en:
        "Since 2009 Cirlan has offered a wide range of printed circuits, both single-sided, double-sided without metallization as well as circuits for LED technology based on Aluminum. Strategically, we are a benchmark in the market for printed circuit board manufacturers",
      it:
        "Dal 2009 Cirlan offre una vasta gamma di circuiti stampati, sia su un lato, su due lati senza metallizzazione, nonché circuiti per la tecnologia LED basata sull'alluminio. Strategicamente, siamo un punto di riferimento nel mercato per i produttori di circuiti stampati",
      de:
        "Seit 2009 bietet Cirlan eine breite Palette von gedruckten Schaltungen an, sowohl einseitig, doppelseitig ohne Metallisierung als auch Schaltungen für die LED-Technologie auf Aluminiumbasis. Strategisch sind wir eine Referenz auf dem Markt für Leiterplattenhersteller",
      es:
        "Desde 2009, Cirlan ha ofrecido una amplia gama de circuitos impresos, tanto de una cara como de doble cara sin metalización, así como circuitos para tecnología LED basada en aluminio. Estratégicamente, somos un punto de referencia en el mercado para los fabricantes de placas de circuito impreso.",
      nl:
        "Sinds 2009 biedt Cirlan een breed scala aan gedrukte schakelingen, zowel enkelzijdig, dubbelzijdig zonder metallisatie als circuits voor LED-technologie op basis van aluminium. Strategisch zijn we een referentie in de markt voor printplaatfabrikanten",
    },
    lienInfo: "",
    lienSite: "http://www.cirlan.com/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "67",
    name: "CISA, Circuitos Impresos SA",
    logo: "../images/img/logo/logo-cisa.jpeg",
    type: ["pcb"],
    langue: ["EN", "ES"],
    nombreCouche: 12,
    quantité: 50,
    delais: 14,
    finitions: [],
    materiaux: ["CEM-1", "CEM-3", "FR-2", "FR-4"],
    rigidité: ["Rigide"],
    via: ["Buried", "Laser"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "Chez CISA, notre personnel hautement qualifié est entièrement dédié à assurer la réussite de votre projet. Depuis 1965, nous avons réussi à produire des solutions aux nouvelles demandes et à surmonter les défis qui se posent en raison de l'évolution constante de notre secteur.",
      en:
        "At CISA, our highly qualified staff are fully dedicated to ensuring your project is a complete success. Since 1965, we have been successfully producing solutions to new demands and overcoming the challenges that arise due to the constant evolution of our sector.",
      it:
        "Alla CISA, il nostro personale altamente qualificato è interamente dedicato a garantire che il tuo progetto abbia un completo successo. Dal 1965, produciamo con successo soluzioni a nuove esigenze e superiamo le sfide che sorgono a causa della costante evoluzione del nostro settore.",
      de:
        "Bei CISA setzen sich unsere hochqualifizierten Mitarbeiter voll und ganz dafür ein, dass Ihr Projekt ein voller Erfolg wird. Seit 1965 produzieren wir erfolgreich Lösungen für neue Anforderungen und bewältigen die Herausforderungen, die sich aus der ständigen Weiterentwicklung unserer Branche ergeben.",
      es:
        "En CISA, nuestro personal altamente calificado está totalmente dedicado a garantizar que su proyecto sea un éxito total. Desde 1965, hemos estado produciendo con éxito soluciones a nuevas demandas y superando los desafíos que surgen debido a la evolución constante de nuestro sector.",
      nl:
        "Bij CISA zijn onze hooggekwalificeerde medewerkers volledig toegewijd om ervoor te zorgen dat uw project een volledig succes wordt. Sinds 1965 produceren we met succes oplossingen voor nieuwe eisen en overwinnen we de uitdagingen die ontstaan ​​door de constante evolutie van onze sector.",
    },
    lienInfo: "",
    lienSite: "https://www.circuitosimpresos.net/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "68",
    name: "ELATE, SA",
    logo: "../images/img/logo/logo-elate.jpeg",
    type: ["pcb", "pcba"],
    langue: ["EN", "FR", "ES"],
    nombreCouche: 16,
    quantité: 50,
    delais: 3,
    finitions: [],
    materiaux: ["FR-4", "FR-5", "Polyimide", "PTFE", "Teflon"],
    rigidité: ["Rigide", "Semi flexible", "flexible"],
    via: ["Buried", "Laser"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "Compte tenu de l'expérience de son équipe technique, il est possible de répondre à une large gamme de produits, des circuits à double face aux circuits multicouches complexes.",
      en:
        "Given the experience of its technical team, it is possible to respond to a wide range of products, from double-sided circuits to complex multilayer circuits.",
      it:
        "Data l'esperienza del suo team tecnico, è possibile rispondere a una vasta gamma di prodotti, dai circuiti a doppia faccia a complessi circuiti multistrato.",
      de:
        "Aufgrund der Erfahrung seines technischen Teams ist es möglich, auf eine breite Palette von Produkten zu reagieren, von doppelseitigen Schaltkreisen bis hin zu komplexen mehrschichtigen Schaltkreisen.",
      es:
        "Dada la experiencia de su equipo técnico, es posible responder a una amplia gama de productos, desde circuitos de doble cara hasta complejos circuitos multicapa.",
      nl:
        "Gezien de ervaring van zijn technisch team, is het mogelijk om te reageren op een breed scala aan producten, van dubbelzijdige circuits tot complexe meerlagige circuits.",
    },
    lienInfo: "",
    lienSite: "https://elatesa.com/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "69",
    name: "Elco Pcb",
    logo: "../images/img/logo/logo-elco.jpeg",
    type: ["pcb"],
    langue: ["EN"],
    nombreCouche: 40,
    quantité: 50,
    delais: 14,
    finitions: [
      "HAL standard",
      "Gold electro",
      "Chemical tin",
      "ENEPIG",
      "HAL-SN100C",
      "OSP",
    ],
    materiaux: ["FR-4", "Polyimide", "Teflon"],
    rigidité: ["Rigide", "Semi flexible", "flexible"],
    via: ["Buried", "Laser"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "Elco PCB est en mesure de satisfaire une grande variété de demandes du marché: du prototype à la production de masse, du simple côté jusqu'à 40 couches de cartes.",
      en:
        "Elco PCB is able to satisfy a large variety of market requests: from prototype to mass production, from single side up to 40 layers boards.",
      it:
        "Elco PCB è in grado di soddisfare una grande varietà di richieste del mercato: dal prototipo alla produzione in serie, dalle schede single side fino a 40 strati.",
      de:
        "Elco PCB ist in der Lage, eine Vielzahl von Marktanforderungen zu erfüllen: vom Prototyp bis zur Massenproduktion, von einseitigen bis zu 40-lagigen Platten.",
      es:
        "Elco PCB es capaz de satisfacer una gran variedad de solicitudes del mercado: desde prototipos hasta producción en masa, desde placas de un solo lado hasta 40 capas.",
      nl:
        "Elco PCB kan aan een grote verscheidenheid aan marktverzoeken voldoen: van prototype tot massaproductie, van enkelzijdige tot 40-laags platen.",
    },
    lienInfo: "",
    lienSite: "https://www.elcopcb.com/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "70",
    name: "Eurocir",
    logo: "../images/img/logo/logo-eurocir.jpeg",
    type: ["pcb"],
    langue: ["EN", "ES"],
    nombreCouche: 40,
    quantité: 50,
    delais: 14,
    finitions: [],
    materiaux: ["FR-4", "CEM-1", "CEM-2", "CEM-3"],
    rigidité: ["Rigide"],
    via: ["Buried", "Laser"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "Eurocir approvisionne le marché mondial à travers ses usines de fabrication en Europe et en Asie. Nous disposons de structures techniques, commerciales et logistiques situées dans les pays où nos clients stratégiques sont implantés et là où le marché exige un suivi et un support quotidien in situ.",
      en:
        "Eurocir supplies the global market through its manufacture facilities in Europe and Asia. We have technical, commercial and logistic structures located in the countries where our strategic customers are established and there where the market demands a daily in situ follow up and support.",
      it:
        "Eurocir fornisce al mercato globale attraverso i suoi impianti di produzione in Europa e in Asia. Abbiamo strutture tecniche, commerciali e logistiche situate nei paesi in cui sono stabiliti i nostri clienti strategici e là dove il mercato richiede un follow-up e un supporto quotidiani in situ.",
      de:
        "Eurocir beliefert den globalen Markt über seine Produktionsstätten in Europa und Asien. Wir haben technische, kommerzielle und logistische Strukturen in den Ländern, in denen unsere strategischen Kunden etabliert sind, und dort, wo der Markt eine tägliche In-situ-Nachverfolgung und Unterstützung erfordert.",
      es:
        "Eurocir abastece al mercado mundial a través de sus instalaciones de fabricación en Europa y Asia. Tenemos estructuras técnicas, comerciales y logísticas ubicadas en los países donde están establecidos nuestros clientes estratégicos y allí donde el mercado exige un seguimiento y soporte in situ diario.",
      nl:
        "Eurocir levert aan de wereldmarkt via haar productiefaciliteiten in Europa en Azië. We hebben technische, commerciële en logistieke structuren in de landen waar onze strategische klanten gevestigd zijn en daar waar de markt een dagelijkse in-situ opvolging en ondersteuning vereist.",
    },
    lienInfo: "",
    lienSite: "http://www.eurocir.com/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "71",
    name: "FAST PCB, SL",
    logo: "../images/img/logo/logo-fastpcb.gif",
    type: ["pcb"],
    langue: ["ES"],
    nombreCouche: 8,
    quantité: 50,
    delais: 2,
    finitions: [
      "Gold electro",
      "Chemical tin",
      "ENEPIG",
      "Chemical nickel",
      "OSP",
    ],
    materiaux: ["CEM-1", "CEM-3", "FR-2", "FR-4"],
    rigidité: ["Rigide", "Semi flexible", "flexible"],
    via: ["Buried"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "Nous avons deux lignes de production indépendantes: l'une dédiée à la fabrication de moyennes / grandes séries simple face et l'autre dédiée à la fabrication de circuits multicouches et double face.",
      en:
        "We have two independent production lines: one dedicated to the manufacture of medium / large single-sided series and the other dedicated to the manufacture of multilayer and double-sided circuits.",
      it:
        "Abbiamo due linee di produzione indipendenti: una dedicata alla produzione di serie monofaccia medio / grande e l'altra dedicata alla produzione di circuiti multistrato e bifacciali.",
      de:
        "Wir haben zwei unabhängige Produktionslinien: eine für die Herstellung von mittleren / großen einseitigen Serien und eine für die Herstellung von mehrschichtigen und doppelseitigen Schaltungen.",
      es:
        "Tenemos dos líneas de producción independientes: una dedicada a la fabricación de series medianas / grandes de una cara y la otra dedicada a la fabricación de circuitos multicapa y de doble cara.",
      nl:
        "Tenemos dos líneas de producción independientes: una dedicada a la fabricación de series medianas / grandes de una cara y la otra dedicada a la fabricación de circuitos multicapa y de doble cara.",
    },
    lienInfo: "",
    lienSite: "https://www.fastpcb.com/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "72",
    name: "GCE",
    logo: "../images/img/logo/logo-gce.jpg",
    type: ["pcb"],
    langue: ["EN", "ES", "PT", "DE"],
    nombreCouche: 20,
    quantité: 50,
    delais: 5,
    finitions: [
      "HAL standard",
      "Chemical gold",
      "Gold electro",
      "Chemical silver",
      "Chemical tin",
      "OSP",
    ],
    materiaux: [
      "CEM-1",
      "CEM-2",
      "CEM-3",
      "FR-1",
      "FR-2",
      "FR-3",
      "FR-4",
      "FR-5",
      "Duroid",
      "Kapton",
      "Polyester",
      "Polyethylene",
      "Polyflon",
      "PTFE",
      "Pyralux",
      "Teflon",
      "XPC",
    ],
    rigidité: ["Rigide", "Semi flexible", "flexible"],
    via: ["Buried", "Laser"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "Nous sommes une entreprise leader sur le marché grâce à notre expérience, luttant pour l'amélioration continue de nos processus de production et notre engagement envers les technologies de dernière génération mais, surtout, parce que nous croyons en ce que nous faisons et comment nous le faisons, avec le client la satisfaction toujours au premier plan.",
      en:
        "We are a leading company in the market thanks to our experience, striving for the continuous improvement of our production processes and our commitment to latest-generation technologies but, above all, because we believe in what we do and how we do it, with client satisfaction always at the forefront.",
      it:
        "Siamo un'azienda leader nel mercato grazie alla nostra esperienza, impegnandoci per il continuo miglioramento dei nostri processi produttivi e il nostro impegno per le tecnologie di ultima generazione ma, soprattutto, perché crediamo in ciò che facciamo e come lo facciamo, con il cliente soddisfazione sempre all'avanguardia.",
      de:
        "Wir sind ein führendes Unternehmen auf dem Markt, dank unserer Erfahrung, der kontinuierlichen Verbesserung unserer Produktionsprozesse und unseres Engagements für Technologien der neuesten Generation, vor allem aber, weil wir daran glauben, was wir tun und wie wir es mit unseren Kunden tun Zufriedenheit immer im Vordergrund.",
      es:
        "Somos una empresa líder en el mercado gracias a nuestra experiencia, luchando por la mejora continua de nuestros procesos de producción y nuestro compromiso con las tecnologías de última generación pero, sobre todo, porque creemos en lo que hacemos y cómo lo hacemos, con el cliente. satisfacción siempre a la vanguardia.",
      nl:
        "We zijn een toonaangevend bedrijf in de markt dankzij onze ervaring en streven naar continue verbetering van onze productieprocessen en onze toewijding aan de nieuwste generatie technologieën, maar vooral omdat we geloven in wat we doen en hoe we het doen, met de klant tevredenheid altijd voorop.",
    },
    lienInfo: "",
    lienSite: "https://gacem.com/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "73",
    name: "Inelmatic Electronics",
    logo: "../images/img/logo/logo-inelmatic.jpeg",
    type: ["pcb", "pcba"],
    langue: ["EN", "ES", "DE"],
    nombreCouche: 16,
    quantité: 50,
    delais: 14,
    finitions: ["HAL standard", "Chemical gold"],
    materiaux: [],
    rigidité: ["Rigide", "Semi flexible", "flexible"],
    via: ["Buried"],
    couleursVernis: ["Green", "Black", "Blue", "Red", "Yellow", "White"],
    description: {
      fr:
        "Inelmatic Electronics est un fournisseur leader de dispositifs électroniques et de services de fabrication sous contrat d'électronique pour les industries de consommation, automobile, médicale, militaire et autres hautes technologies.",
      en:
        "Inelmatic Electronics is a leading provider of electronic devices and electronics contract manufacturing services to consumer, automotive, medical, military and other high tech industries. ",
      it:
        "Inelmatic Electronics è un fornitore leader di dispositivi elettronici e servizi di produzione di contratti elettronici per le industrie di consumo, automobilistiche, mediche, militari e di altro tipo.",
      de:
        "Inelmatic Electronics ist ein führender Anbieter von Dienstleistungen für die Herstellung elektronischer Geräte und Elektronikaufträge für Verbraucher-, Automobil-, Medizin-, Militär- und andere High-Tech-Branchen.",
      es:
        "Inelmatic Electronics es un proveedor líder de dispositivos electrónicos y servicios de fabricación por contrato de electrónica para las industrias de alta tecnología de consumo, automotriz, médica, militar y otras.",
      nl:
        "Inelmatic Electronics is een toonaangevende leverancier van elektronische apparaten en elektronische contractproductiediensten voor consumenten, auto's, medische, militaire en andere hightechindustrieën.",
    },
    lienInfo: "",
    lienSite: "http://www.inelmatic.com/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "74",
    name: "Lab Circuits",
    logo: "../images/img/logo/logo-labcircuit.jpeg",
    type: ["pcb"],
    langue: ["EN", "ES", "FR", "IT", "DE"],
    nombreCouche: 30,
    quantité: 50,
    delais: 14,
    finitions: [],
    materiaux: ["FR-4", "Polyimide"],
    rigidité: ["Rigide"],
    via: ["Buried", "Laser"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "Depuis les débuts de Lab Circuits, la qualité a toujours été lun des piliers fondamentaux de lentreprise. Notre entreprise a obtenu la reconnaissance du marché international et est aujourdhui devenue une référence au niveau national et européen.",
      en:
        "Since the beginnings of Lab Circuits, quality has always been one of the fundamental pillars of the company. Our company has obtained recognition on the international market and has now become a benchmark at national and European level.",
      it:
        "Sin dagli inizi di Lab Circuits, la qualità è sempre stata uno dei pilastri fondamentali dell'azienda. La nostra azienda ha ottenuto il riconoscimento sul mercato internazionale e ora è diventata un punto di riferimento a livello nazionale ed europeo.",
      de:
        "Seit den Anfängen von Lab Circuits war Qualität immer eine der Grundpfeiler des Unternehmens. Unser Unternehmen hat auf dem internationalen Markt Anerkennung gefunden und ist mittlerweile zu einem Maßstab auf nationaler und europäischer Ebene geworden.",
      es:
        "Desde los inicios de Lab Circuits, la calidad siempre ha sido uno de los pilares fundamentales de la empresa. Nuestra empresa ha obtenido reconocimiento en el mercado internacional y ahora se ha convertido en un referente a nivel nacional y europeo.",
      nl:
        "Sinds het begin van Lab Circuits is kwaliteit altijd een van de fundamentele pijlers van het bedrijf geweest. Ons bedrijf heeft erkenning gekregen op de internationale markt en is nu een benchmark geworden op nationaal en Europees niveau.",
    },
    lienInfo: "",
    lienSite: "https://www.lab-circuits.com/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "75",
    name: "Novatek Circuitos Impresos",
    logo: "../images/img/logo/logo-novatek.jpeg",
    type: ["pcb"],
    langue: ["EN", "ES", "FR"],
    nombreCouche: 3,
    quantité: 50,
    delais: 14,
    finitions: ["Chemical silver", "Chemical tin", "Chemical nickel"],
    materiaux: ["CEM-1", "FR-4"],
    rigidité: ["Rigide"],
    via: ["Buried", "Laser"],
    couleursVernis: ["Green", "Black", "Blue", "White"],
    description: {
      fr:
        "Nous sommes une entreprise familiale avec plus de 25 ans d'expérience dans la fabrication de cartes de circuits imprimés pour le secteur industriel. Nous nous spécialisons dans la fabrication de prototypes, pré-séries et de séries avec des délais urgents.",
      en:
        "We are a family business with over 25 years of experience in manufacturing printed circuit boards for the industrial sector. We specialize in manufacturing prototypes, pre-series and series with urgent deadlines.",
      it:
        "Siamo un'azienda a conduzione familiare con oltre 25 anni di esperienza nella produzione di circuiti stampati per il settore industriale. Siamo specializzati nella produzione di prototipi, pre-serie e serie con scadenze urgenti.",
      de:
        "Wir sind ein Familienunternehmen mit über 25 Jahren Erfahrung in der Herstellung von Leiterplatten für die Industrie. Wir sind spezialisiert auf die Herstellung von Prototypen, Vorserien und Serien mit dringenden Fristen.",
      es:
        "Somos una empresa familiar con más de 25 años de experiencia en la fabricación de placas de circuito impreso para el sector industrial. Nos especializamos en la producción de prototipos, pre-series y series con plazos urgentes.",
      nl:
        "Wij zijn een familiebedrijf met meer dan 25 jaar ervaring in het vervaardigen van printplaten voor de industriële sector. Wij zijn gespecialiseerd in de productie van prototypes, pre-series en series met urgente deadlines.",
    },
    lienInfo: "",
    lienSite: "https://www.novatek.es/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "76",
    name: "Printed Circuits Paredes",
    logo: "../images/img/logo/logo-printedcirsuitsparedes.jpeg",
    type: ["pcb"],
    langue: ["EN", "ES"],
    nombreCouche: 2,
    quantité: 50,
    delais: 14,
    finitions: [
      "HAL standard",
      "Gold electro",
      "Chemical silver",
      "Chemical nickel",
      "OSP",
    ],
    materiaux: ["CEM-1", "FR-2", "FR-4", "Kapton", "Polyester"],
    rigidité: ["Rigide"],
    via: ["Buried"],
    couleursVernis: ["Green", "Black", "Blue", "White"],
    description: {
      fr:
        "Circuitos Impresos Paredes est une nouvelle société dirigée par des professionnels hautement qualifiés avec une expérience étendue dans le processus de fabrication de circuits imprimés.",
      en:
        "Circuitos Impresos Paredes is a new set up Company run by high skilled professionals with extended experience in Printed Circuit Board manufacturing process.",
      it:
        "Circuitos Impresos Paredes è una nuova società creata da professionisti altamente qualificati con una vasta esperienza nel processo di produzione di circuiti stampati.",
      de:
        "Circuitos Impresos Paredes ist ein neu gegründetes Unternehmen, das von hochqualifizierten Fachleuten mit langjähriger Erfahrung im Herstellungsprozess von Leiterplatten geführt wird.",
      es:
        "Circuitos Impresos Paredes es una nueva compañía creada por profesionales altamente calificados con amplia experiencia en el proceso de fabricación de placas de circuito impreso.",
      nl:
        "Circuitos Impresos Paredes is een nieuw opgericht bedrijf dat wordt gerund door hoogopgeleide professionals met uitgebreide ervaring in het productieproces van printplaten.",
    },
    lienInfo: "",
    lienSite: "http://www.circuitosparedes.com/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "77",
    name: "Tecci Circuitos Impresos",
    logo: "../images/img/logo/logo-tecci.jpeg",
    type: ["pcb"],
    langue: ["EN", "ES", "FR"],
    nombreCouche: 8,
    quantité: 50,
    delais: 14,
    finitions: [
      "HAL standard",
      "Chemical gold",
      "Gold electro",
      "Chemical tin",
      "HAL-SN100C",
      "OSP",
    ],
    materiaux: ["FR-4"],
    rigidité: ["Rigide"],
    via: ["Buried", "Laser"],
    couleursVernis: ["Green", "Black", "Blue", "Red"],
    description: {
      fr:
        "La qualité et la fiabilité de leurs produits font de TECCI une entreprise qui se démarque du marché actuel. Avec plus de 30 ans d’expériences TECCI est désormais un pilier de la fabrication de carte électronique.",
      en:
        "The quality and reliability of their products make TECCI a company that stands out from the current market. With more than 30 years of experience TECCI is now a pillar of electronic card manufacturing.",
      it:
        "La qualità e l'affidabilità dei loro prodotti rendono TECCI un'azienda che si distingue dal mercato attuale. Con oltre 30 anni di esperienza TECCI è ora un pilastro della produzione di schede elettroniche.",
      de:
        "Die Qualität und Zuverlässigkeit ihrer Produkte machen TECCI zu einem Unternehmen, das sich vom aktuellen Markt abhebt. Mit mehr als 30 Jahren Erfahrung ist TECCI heute eine Säule der Herstellung elektronischer Karten.",
      es:
        "La calidad y la fiabilidad de sus productos hacen de TECCI una empresa que se destaca del mercado actual. Con más de 30 años de experiencia, TECCI es ahora un pilar en la fabricación de tarjetas electrónicas.",
      nl:
        "De kwaliteit en betrouwbaarheid van hun producten maken TECCI tot een bedrijf dat zich onderscheidt van de huidige markt. Met meer dan 30 jaar ervaring is TECCI nu een pijler van de productie van elektronische kaarten.",
    },
    lienInfo: "",
    lienSite: "https://www.tecci.com/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "78",
    name: "ZUBELZU S.L.",
    logo: "../images/img/logo/logo-zubelzu.png",
    type: ["pcb"],
    langue: ["ES", "FR", "EN"],
    nombreCouche: 8,
    quantité: 50,
    delais: 14,
    finitions: [
      "Gold electro",
      "Chemical silver",
      "Chemical tin",
      "HAL-SN100C",
    ],
    materiaux: ["FR-4"],
    rigidité: ["Rigide"],
    via: ["Buried", "Laser"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "Offrir à nos clients un haut niveau de qualité et de service qui répond à leurs exigences est notre principal atout qui nous a permis d´être renommés et respectés dans le marché..",
      en:
        "Offering our customers a high level of quality and service that meets their requirements is our main asset that has allowed us to be renowned and respected in the market.",
      it:
        "Offrire ai nostri clienti un alto livello di qualità e servizio in grado di soddisfare le loro esigenze è la nostra principale risorsa che ci ha permesso di essere rinomati e rispettati sul mercato.",
      de:
        "Unseren Kunden ein hohes Maß an Qualität und Service zu bieten, das ihren Anforderungen entspricht, ist unser wichtigstes Kapital, das es uns ermöglicht hat, auf dem Markt bekannt und anerkannt zu sein.",
      es:
        "Ofrecer a nuestros clientes un alto nivel de calidad y servicio que cumpla con sus requisitos es nuestro principal activo que nos ha permitido ser reconocidos y respetados en el mercado.",
      nl:
        "Onze klanten een hoog kwaliteits- en serviceniveau bieden dat aan hun eisen voldoet, is onze belangrijkste troef waardoor we in de markt bekend en gerespecteerd kunnen worden.",
    },
    lienInfo: "",
    lienSite: "https://www.zubelzu.com/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "79",
    name: "Alba Eletronica",
    logo: "../images/img/logo/logo-alba.jpeg",
    type: ["pcb"],
    langue: ["EN", "IT", "DE"],
    nombreCouche: 12,
    quantité: 50,
    delais: 14,
    finitions: ["Gold electro", "Chemical silver", "ENEPIG", "HAL-SN100C"],
    materiaux: ["FR-4", "Teflon"],
    rigidité: ["Rigide", "Semi flexible", "flexible"],
    via: ["Buried", "Laser"],
    couleursVernis: [
      "Green",
      "Black",
      "Blue",
      "White",
      "Red",
      "Yellow",
      "Transparent",
    ],
    description: {
      fr:
        "Le haut niveau de compétence technique et la vaste offre de services PCB en font un partenaire idéal capable de prendre soin du client dans tous ses besoins à court ou long terme.",
      en:
        "The high level of technical competence and the ample offer of PCB services make it an ideal partner able to take care of the customer in all of his long- or short-term needs. ",
      it:
        "L'alto livello di competenza tecnica e l'ampia offerta di servizi PCB lo rendono un partner ideale in grado di prendersi cura del cliente in tutte le sue esigenze a lungo o breve termine.",
      de:
        "Das hohe Maß an technischer Kompetenz und das umfangreiche Angebot an PCB-Dienstleistungen machen es zu einem idealen Partner, der in der Lage ist, den Kunden bei all seinen langfristigen oder kurzfristigen Bedürfnissen zu betreuen.",
      es:
        "El alto nivel de competencia técnica y la amplia oferta de servicios de PCB lo convierten en un socio ideal capaz de atender al cliente en todas sus necesidades a largo o corto plazo.",
      nl:
        "Het hoge niveau van technische competentie en het ruime aanbod aan PCB-services maken het een ideale partner die in staat is om de klant te verzorgen in al zijn lange- of kortetermijnbehoeften.",
    },
    lienInfo: "",
    lienSite: "https://albapcb.com/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "80",
    name: "OMR ITALIA",
    logo: "../images/img/logo/logo-omr.png",
    type: ["pcb"],
    langue: ["EN", "IT", "DE"],
    nombreCouche: 8,
    quantité: 50,
    delais: 7,
    finitions: [
      "HAL standard",
      "Gold electro",
      "Chemical tin",
      "HAL-SN100C",
      "OSP",
    ],
    materiaux: ["FR-4"],
    rigidité: ["Rigide"],
    via: ["Buried"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "Ne vous contentez pas d'un fournisseur de circuits imprimés conventionnel: en choisissant OMR, vous décidez de bénéficier de tous les avantages d'un partenaire technologique international solide, à la fois professionnel et fiable.",
      en:
        "Don't settle for a conventional printed circuit supplier: by choosing OMR you decide to get all the advantages of a solid international technology partner, both professional and reliable.",
      it:
        "Non accontentarti di un fornitore convenzionale di circuiti stampati: scegliendo OMR decidi di ottenere tutti i vantaggi di un solido partner tecnologico internazionale, sia professionale che affidabile.",
      de:
        "Geben Sie sich nicht mit einem herkömmlichen Anbieter von Leiterplatten zufrieden: Wenn Sie sich für OMR entscheiden, profitieren Sie von allen Vorteilen eines soliden internationalen Technologiepartners, sowohl professionell als auch zuverlässig.",
      es:
        "No se conforme con un proveedor convencional de circuitos impresos: al elegir OMR, decide obtener todas las ventajas de un socio tecnológico internacional sólido, tanto profesional como confiable.",
      nl:
        "Neem geen genoegen met een conventionele leverancier van gedrukte schakelingen: door voor OMR te kiezen, besluit u alle voordelen van een solide internationale technologiepartner te krijgen, zowel professioneel als betrouwbaar.",
    },
    lienInfo: "",
    lienSite: "https://www.omritalia.it/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "81",
    name: "CSM Circuiti",
    logo: "../images/img/logo/logo-cms.png",
    type: ["pcb"],
    langue: ["EN", "IT"],
    nombreCouche: 4,
    quantité: 50,
    delais: 5,
    finitions: [
      "HAL standard",
      "HAL lead free",
      "Chemical gold",
      "Gold electro",
      "Chemical silver",
    ],
    materiaux: ["FR-4"],
    rigidité: ["Rigide", "Semi flexible", "flexible"],
    via: ["Buried"],
    couleursVernis: [
      "Green",
      "Black",
      "Red",
      "Blue",
      "Yellow",
      "Transparent",
      "White",
    ],
    description: {
      fr:
        "En aval de notre processus de production, d'innombrables technologies évoluent au quotidien et compte tenu du rythme de progression phénoménal, CSM est organisé de manière à répondre aux exigences les plus extrêmes des concepteurs et des OEM qui conçoivent, fabriquent et assemblent des PCB .",
      en:
        "Downstream of our production process, countless technologies are advancing on a daily basis, and in view of the phenomenal pace of progress, CSM is organised in such a way as to meet the most extreme requirements of designers and OEMs that design, manufacture and assemble PCBs.",
      it:
        "A valle del nostro processo di produzione, innumerevoli tecnologie avanzano quotidianamente e alla luce del fenomenale ritmo di progresso, CSM è organizzata in modo tale da soddisfare i requisiti più estremi di progettisti e OEM che progettano, producono e assemblano PCB .",
      de:
        "Nach unserem Produktionsprozess entwickeln sich täglich unzählige Technologien weiter. Angesichts des phänomenalen Fortschritts ist CSM so organisiert, dass es den extremsten Anforderungen von Designern und OEMs gerecht wird, die Leiterplatten entwerfen, herstellen und montieren .",
      es:
        "Después de nuestro proceso de producción, innumerables tecnologías avanzan a diario, y en vista del ritmo fenomenal del progreso, CSM está organizado de tal manera que cumple con los requisitos más extremos de los diseñadores y OEM que diseñan, fabrican y ensamblan PCB .",
      nl:
        "Stroomafwaarts van ons productieproces gaan talloze technologieën dagelijks vooruit en gezien het fenomenale tempo van de vooruitgang, is CSM zo georganiseerd dat het voldoet aan de meest extreme eisen van ontwerpers en OEM's die PCB's ontwerpen, produceren en assembleren .",
    },
    lienInfo: "",
    lienSite: "https://www.csm-circuiti.it/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "82",
    name: "Cistelaier SpA",
    logo: "../images/img/logo/logo-cistelaier.jpeg",
    type: ["pcb"],
    langue: ["EN", "IT"],
    nombreCouche: 40,
    quantité: 50,
    delais: 14,
    finitions: [
      "HAL standard",
      "Chemical gold",
      "Gold electro",
      "Chemical silver",
      "Chemical tin",
      "ENEPIG",
      "HAL-SN100C",
      "Chemical nickel",
    ],
    materiaux: ["FR-4", "Kapton", "Polyimide", "Teflon"],
    rigidité: ["Rigide", "Semi flexible", "flexible"],
    via: ["Buried", "Laser"],
    couleursVernis: ["Green", "Black", "Red", "Yellow", "White"],
    description: {
      fr:
        "Continuer à développer des capacités, des services et un savoir-faire pour garantir avec nos propres usines en Europe un ensemble de services et de PCB à guichet unique pour toutes sortes de technologies, des PCB à double face aux PCB rigides et flexibles de haute et très haute technologie niveau, à la fois, pour la production en série et pour les prototipations de rotation rapide.",
      en:
        "Continue developing capabilities, services and know-how to guarantee with our own plants in Europe a one stop shop package of services and PCBs for all kind of technologies, from double sided PCBs to rigid and rigid-flex PCBs of high and very-high technology level, both, for serial production and for fast turnaround prototipations.",
      it:
        "Continuare a sviluppare capacità, servizi e know-how per garantire con i nostri impianti in Europa un pacchetto completo di servizi e PCB per tutti i tipi di tecnologie, dai PCB a doppia faccia ai PCB rigidi e rigidi di tecnologia alta e altissima livello, sia per la produzione in serie che per le prototipazioni di inversione rapida.",
      de:
        "Entwickeln Sie weiterhin Fähigkeiten, Dienstleistungen und Know-how, um mit unseren eigenen Werken in Europa ein One-Stop-Shop-Paket von Dienstleistungen und Leiterplatten für alle Arten von Technologien zu garantieren, von doppelseitigen Leiterplatten bis hin zu starren und starrflexiblen Leiterplatten mit hoher und sehr hoher Technologie Level, sowohl für die Serienproduktion als auch für Prototipationen mit schneller Abwicklung.",
      es:
        "Continuar desarrollando capacidades, servicios y conocimientos para garantizar con nuestras propias plantas en Europa un paquete integral de servicios y PCB para todo tipo de tecnologías, desde PCB de doble cara hasta PCB rígidos y rígidos de alta y muy alta tecnología. nivel, tanto para producción en serie como para prototipaciones de respuesta rápida.",
      nl:
        "Doorgaan met het ontwikkelen van mogelijkheden, diensten en knowhow om met onze eigen fabrieken in Europa een one-stop-shoppakket van diensten en PCB's te garanderen voor alle soorten technologieën, van dubbelzijdige PCB's tot stijve en rigid-flex PCB's van hoge en zeer hoge technologie niveau, zowel voor serieproductie als voor snelle prototipaties.",
    },
    lienInfo: "",
    lienSite: "http://www.cistelaier.it/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "83",
    name: "TVR srl",
    logo: "../images/img/logo/logo-tvr.jpeg",
    type: ["pcb"],
    langue: ["EN", "IT", "DE"],
    nombreCouche: 48,
    quantité: 50,
    delais: 1,
    finitions: [
      "HAL standard",
      "Chemical gold",
      "Gold electro",
      "ENEPIG",
      "HAL-SN100C",
    ],
    materiaux: [],
    rigidité: ["Rigide"],
    via: ["Buried", "Laser"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "TVR est leader dans la production d'échantillons et de volumes moyens de PCB en mettant l'accent sur la qualité du produit et le respect des délais de livraison.",
      en:
        "TVR is leader in the production of samples ad medium volume of PCBs with a focus on product's quality and respect of delivery time.",
      it:
        "TVR è leader nella produzione di campioni e volumi medi di PCB, con particolare attenzione alla qualità del prodotto e al rispetto dei tempi di consegna.",
      de:
        "TVR ist führend in der Herstellung von Proben und mittelgroßen Leiterplatten mit Schwerpunkt auf Produktqualität und Einhaltung der Lieferzeit.",
      es:
        "TVR es líder en la producción de muestras y volumen medio de PCB con un enfoque en la calidad del producto y el respeto del tiempo de entrega.",
      nl:
        "TVR is marktleider in de productie van monsters met een gemiddeld volume PCB's, met de nadruk op de kwaliteit van het product en respect voor de levertijd.",
    },
    lienInfo: "",
    lienSite: "http://www.tvrsrl.com/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "84",
    name: "Piciesse Elettronica",
    logo: "../images/img/logo/logo-piciesse.png",
    type: ["pcb"],
    langue: ["EN", "IT", "DE", "FR"],
    nombreCouche: 50,
    quantité: 50,
    delais: 14,
    finitions: [
      "Chemical gold",
      "Gold electro",
      "Chemical silver",
      "ENEPIG",
      "HAL-SN100C",
      "OSP",
    ],
    materiaux: ["FR-4"],
    rigidité: ["flexible"],
    via: ["Buried"],
    couleursVernis: ["Green", "Black", "White", "Red", "Blue"],
    description: {
      fr:
        "La Mission de Piciesse consiste en donner à ses clients des solutions sur mesure, compétitives et à l’avant-garde, avec garantie de qualité maximum, performance et ponctualité dans les livraisons.",
      en:
        "Piciesse's mission is to provide its customers with tailor-made, competitive and cutting-edge solutions, with guaranteed maximum quality, performance and punctuality in deliveries.",
      it:
        "La missione di Piciesse è fornire ai propri clienti soluzioni su misura, competitive e all'avanguardia, con la massima qualità, prestazioni e puntualità garantite nelle consegne.",
      de:
        "Piciesse hat es sich zur Aufgabe gemacht, seinen Kunden maßgeschneiderte, wettbewerbsfähige und innovative Lösungen mit garantierter maximaler Qualität, Leistung und Pünktlichkeit bei Lieferungen anzubieten.",
      es:
        "La misión de Piciesse es proporcionar a sus clientes soluciones personalizadas, competitivas y de vanguardia, con la máxima calidad, rendimiento y puntualidad garantizados en las entregas.",
      nl:
        "De missie van Piciesse is om haar klanten op maat gemaakte, concurrerende en geavanceerde oplossingen te bieden, met gegarandeerde maximale kwaliteit, prestaties en punctualiteit bij leveringen.",
    },
    lienInfo: "",
    lienSite: "http://www.piciesse.it/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "85",
    name: "TEKNIT",
    logo: "../images/img/logo/logo-teknit.gif",
    type: ["pcb"],
    langue: ["EN", "IT"],
    nombreCouche: 12,
    quantité: 50,
    delais: 14,
    finitions: [],
    materiaux: ["FR-4", "Kapton", "Polyimide"],
    rigidité: ["Rigide", "Semi flexible", "flexible"],
    via: ["Buried"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "TEKNIT, avec sa production de PCB professionnels et multicouches, en dix-huit ans a consolidé sa présence au niveau national dans des domaines tels que: Télécommunications, information, technologie ou militaire",
      en:
        "TEKNIT, with its production of professional and multilayer PCB, in eighteen years'time has consolidated its presence in the national in fields such as:Telecommunications, information, technology or military",
      it:
        "TEKNIT, con la sua produzione di PCB professionale e multistrato, in diciotto anni ha consolidato la sua presenza a livello nazionale in settori quali: telecomunicazioni, informazione, tecnologia o militari",
      de:
        "TEKNIT hat mit seiner Produktion von professionellen und mehrschichtigen Leiterplatten in achtzehn Jahren seine Präsenz auf nationaler Ebene in Bereichen wie Telekommunikation, Information, Technologie oder Militär gefestigt",
      es:
        "TEKNIT, con su producción de PCB profesional y multicapa, en 18 años ha consolidado su presencia en el ámbito nacional en campos tales como: telecomunicaciones, información, tecnología o militar.",
      nl:
        "TEKNIT heeft met zijn productie van professionele en meerlaagse PCB's in achttien jaar tijd zijn aanwezigheid in de nationale sector versterkt op gebieden als: telecommunicatie, informatie, technologie of militair",
    },
    lienInfo: "",
    lienSite: "http://www.teknit.it/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "86",
    name: "EMZ - PRINTED CIRCUITS",
    logo: "../images/img/logo/logo-emz.jpg",
    type: ["pcb"],
    langue: ["IT"],
    nombreCouche: 16,
    quantité: 50,
    delais: 14,
    finitions: ["HAL lead free", "Chemical silver"],
    materiaux: ["FR-4"],
    rigidité: ["Rigide"],
    via: ["Buried"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "Emz est la seule entreprise ARTISAN dans le secteur des circuits imprimés entièrement et exclusivement composée de femmes.",
      en:
        "Emz is the only ARTISAN company in the printed circuit sector entirely and exclusively made up of women.",
      it:
        "Emz è l'unica azienda ARTISAN nel settore dei circuiti stampati interamente ed esclusivamente composta da donne.",
      de:
        "Emz ist das einzige ARTISAN-Unternehmen im Bereich der gedruckten Schaltungen, das ausschließlich aus Frauen besteht.",
      es:
        "Emz es la única empresa ARTESANA en el sector de circuitos impresos compuesta total y exclusivamente por mujeres.",
      nl:
        "Emz is het enige ARTISAN-bedrijf in de sector van de gedrukte schakelingen dat volledig en uitsluitend uit vrouwen bestaat.",
    },
    lienInfo: "",
    lienSite: "https://www.circuitistampati.net/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "87",
    name: "DM-R - by Ruffo Donato",
    logo: "../images/img/logo/logo-dmr.png",
    type: ["pcb"],
    langue: ["IT"],
    nombreCouche: 16,
    quantité: 50,
    delais: 14,
    finitions: [
      "HAL lead free",
      "Chemical gold",
      "Gold electro",
      "Chemical silver",
    ],
    materiaux: ["CEM-1", "FR-4"],
    rigidité: ["Rigide", "flexible"],
    via: ["Buried"],
    couleursVernis: ["Green", "Black", "Red", "Blue", "White"],
    description: {
      fr:
        "Nous sommes des fabricants de circuits imprimés à simple face, à double face, à revêtement thermique et flexibles. Nous créons également des pochoirs pour l'assemblage des composants smd.",
      en:
        "We are manufacturers of single sided, double sided, thermal coated and flexible PCBs. We also create stencils for the assembly of smd components.",
      it:
        "Siamo produttori di PCB unilaterali, bifacciali, con rivestimento termico e flessibili. Creiamo anche stencil per l'assemblaggio di componenti smd.",
      de:
        "Wir sind Hersteller von einseitigen, doppelseitigen, thermisch beschichteten und flexiblen Leiterplatten. Wir erstellen auch Schablonen für die Montage von SMD-Komponenten.",
      es:
        "Somos fabricantes de PCB de una cara, de doble cara, con recubrimiento térmico y flexibles. También creamos plantillas para el ensamblaje de componentes smd.",
      nl:
        "Wij zijn fabrikanten van enkelzijdige, dubbelzijdige, thermisch beklede en flexibele printplaten. We maken ook stencils voor de montage van smd-componenten.",
    },
    lienInfo: "",
    lienSite: "http://www.dmrcircuitistampati.it/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "88",
    name: "EFFEI",
    logo: "../images/img/logo/",
    type: ["pcb"],
    langue: ["EN", "IT"],
    nombreCouche: 16,
    quantité: 50,
    delais: 14,
    finitions: [],
    materiaux: ["CEM-3", "FR-4", "Kapton", "Rogers"],
    rigidité: ["Rigide"],
    via: ["Buried", "Laser"],
    couleursVernis: ["Green", "Black", "Red", "White", "Blue", "Yellow"],
    description: {
      fr:
        "Depuis 1971, nous fabriquons de temps en temps des cartes de circuits imprimés en nous adaptant aux besoins du marché. Nous pouvons fournir à la fois un prototype et une production de masse, le client choisissant la meilleure solution économique, qualitative et temporelle.",
      en:
        "Since 1971 we manufacture printed circuit boards, from time to time by adapting to the market needs. We can provide both prototype and mass production, with the customer choosing the best economic solution, qualitative and temporal.",
      it:
        "Dal 1971 produciamo circuiti stampati, di volta in volta adattandoci alle esigenze del mercato. Siamo in grado di fornire sia prototipi che produzioni in serie, con il cliente che sceglie la migliore soluzione economica, qualitativa e temporale.",
      de:
        "Seit 1971 stellen wir von Zeit zu Zeit Leiterplatten her, indem wir uns an die Marktanforderungen anpassen. Wir können sowohl Prototypen als auch Massenproduktionen anbieten, wobei der Kunde die beste wirtschaftliche Lösung wählt, qualitativ und zeitlich.",
      es:
        "Desde 1971 fabricamos placas de circuito impreso, de vez en cuando adaptándonos a las necesidades del mercado. Podemos proporcionar prototipos y producción en masa, con el cliente eligiendo la mejor solución económica, cualitativa y temporal.",
      nl:
        "Sinds 1971 vervaardigen we printplaten, van tijd tot tijd door ons aan te passen aan de marktbehoeften. We kunnen zowel prototypes als massaproductie leveren, waarbij de klant de beste economische oplossing kiest, kwalitatief en tijdelijk.",
    },
    lienInfo: "",
    lienSite: "http://www.effei.it/new/home_eng.htm?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "89",
    name: "Elemaster",
    logo: "../images/img/logo/logo-elemaster.jpeg",
    type: ["pcb", "pcba"],
    langue: ["EN", "IT"],
    nombreCouche: 24,
    quantité: 50,
    delais: 14,
    finitions: [],
    materiaux: ["FR-4", "PTFE"],
    rigidité: ["Rigide", "Semi flexible", "flexible"],
    via: ["Buried", "Laser"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "Elemaster veut être un leader dans la fourniture de services de conception et de fabrication pour ses clients, avec lesquels elle est engagée dans des relations à long terme respectant toutes les exigences établies par les contrats, les lois et les réglementations.",
      en:
        "Elemaster wants to be a leader in the supply of design and manufacturing services for its Customers, with whom it is engaged in long-term relationships respecting every requirement established by contracts, by laws and regulations.",
      it:
        "Elemaster vuole essere leader nella fornitura di servizi di progettazione e produzione per i propri clienti, con i quali è impegnato in relazioni a lungo termine nel rispetto di ogni requisito stabilito da contratti, da leggi e regolamenti.",
      de:
        "Elemaster möchte führend in der Bereitstellung von Design- und Fertigungsdienstleistungen für seine Kunden sein, mit denen es langfristige Beziehungen unter Einhaltung aller vertraglichen, gesetzlichen und behördlichen Anforderungen unterhält.",
      es:
        "Elemaster quiere ser un líder en el suministro de servicios de diseño y fabricación para sus Clientes, con quienes se compromete en relaciones a largo plazo respetando todos los requisitos establecidos por los contratos, las leyes y los reglamentos.",
      nl:
        "Elemaster wil een leider zijn in het leveren van ontwerp- en productiediensten voor zijn klanten, met wie het een langdurige relatie aangaat en alle vereisten respecteert die zijn vastgelegd in contracten, wet- en regelgeving.",
    },
    lienInfo: "",
    lienSite: "https://www.elemaster.com/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "90",
    name: "Eltos SpA",
    logo: "../images/img/logo/logo-eltos.png",
    type: ["pcb"],
    langue: ["EN", "IT"],
    nombreCouche: 32,
    quantité: 50,
    delais: 14,
    finitions: [],
    materiaux: ["FR-4", "Kapton", "Polyimide", "Teflon"],
    rigidité: ["Rigide", "Semi flexible", "flexible"],
    via: ["Buried"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "ELTOS S.p.A fabrique et fournit des cartes de circuits imprimés pour les clients des fabricants d'équipements d'origine et leurs prestataires de services de fabrication électronique. Ses principaux produits comprennent des cartes de circuits imprimés rigides multicouches, qui sont les plates-formes utilisées pour interconnecter les microprocesseurs, les circuits intégrés et d'autres composants pour le fonctionnement des produits et systèmes électroniques.",
      en:
        "ELTOS S.p.A. manufactures and supplies printed circuit boards for original equipment manufacturer customers and their electronic manufacturing service providers. Its principal products include multi-layer rigid printed circuit boards, which are the platforms used to interconnect microprocessors, integrated circuits, and other components for the operation of electronic products and systems.",
      it:
        "ELTOS S.p.A. produce e fornisce circuiti stampati per clienti produttori di apparecchiature originali e loro fornitori di servizi di produzione elettronica. I suoi prodotti principali includono circuiti stampati rigidi multistrato, che sono le piattaforme utilizzate per interconnettere microprocessori, circuiti integrati e altri componenti per il funzionamento di prodotti e sistemi elettronici.",
      de:
        "ELTOS S.p.A. produziert und liefert Leiterplatten für Kunden von Erstausrüstern und deren Dienstleister für die elektronische Fertigung. Zu den Hauptprodukten gehören mehrschichtige starre Leiterplatten, die als Plattformen für die Verbindung von Mikroprozessoren, integrierten Schaltkreisen und anderen Komponenten für den Betrieb elektronischer Produkte und Systeme dienen.",
      es:
        "ELTOS S.p.A.fabrica y suministra placas de circuito impreso para clientes fabricantes de equipos originales y sus proveedores de servicios de fabricación electrónica. Sus productos principales incluyen placas de circuitos impresos rígidos de múltiples capas, que son las plataformas utilizadas para interconectar microprocesadores, circuitos integrados y otros componentes para la operación de productos y sistemas electrónicos.",
      nl:
        "ELTOS S.p.A. produceert en levert printplaten voor klanten van de originele apparatuurfabrikant en hun leveranciers van elektronische productieservices. De belangrijkste producten zijn onder meer meerlagige stijve printplaten, de platforms die worden gebruikt om microprocessors, geïntegreerde schakelingen en andere componenten met elkaar te verbinden voor de werking van elektronische producten en systemen.",
    },
    lienInfo: "",
    lienSite: "http://www.eltos.com/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "91",
    name: "Esseti Circuiti Stampati Srl",
    logo: "../images/img/logo/logo-esseti.jpeg",
    type: ["pcb"],
    langue: ["EN", "IT", "ES"],
    nombreCouche: 60,
    quantité: 50,
    delais: 14,
    finitions: [
      "Chemical gold",
      "Gold electro",
      "Chemical silver",
      "Chemical tin",
      "ENEPIG",
      "HAL-SN100C",
      "OSP",
    ],
    materiaux: ["FR-4", "Teflon"],
    rigidité: ["Rigide", "Semi flexible", "flexible"],
    via: ["Buried", "Laser"],
    couleursVernis: ["Green", "Black", "Red", "Blue", "White"],
    description: {
      fr:
        "En 1979 à Bologne, grâce à la passion pour l'électronique et l'innovation, deux amis ont jeté les bases de ce qui allait devenir une grande entreprise: Esseti Srl, qui depuis plus de 35 ans produit et transforme des cartes de circuits imprimés et peut aujourd'hui garantir la qualité, la disponibilité , compétitivité, flexibilité de production et support technique",
      en:
        "In 1979 in Bologna , thanks to the passion for electronics and innovation , two friends laid the foundations for what would become a great company : Esseti Srl , which for over 35 years has been producing and processing printed circuit boards and today can assure quality , availability , competitiveness , production flexibility and technical support",
      it:
        "Nel 1979 a Bologna, grazie alla passione per l'elettronica e l'innovazione, due amici hanno gettato le basi per quella che sarebbe diventata una grande azienda: Esseti Srl, che da oltre 35 anni produce e lavora circuiti stampati e oggi può garantire qualità, disponibilità , competitività, flessibilità di produzione e supporto tecnico",
      de:
        "1979 legten in Bologna dank der Leidenschaft für Elektronik und Innovation zwei Freunde den Grundstein für ein großartiges Unternehmen: Esseti Srl, das seit über 35 Jahren Leiterplatten herstellt und verarbeitet und heute Qualität und Verfügbarkeit sicherstellen kann , Wettbewerbsfähigkeit, Produktionsflexibilität und technische Unterstützung",
      es:
        "En 1979 en Bolonia, gracias a la pasión por la electrónica y la innovación, dos amigos sentaron las bases de lo que se convertiría en una gran empresa: Esseti Srl, que durante más de 35 años ha estado produciendo y procesando placas de circuito impreso y hoy puede garantizar la calidad y la disponibilidad. , competitividad, flexibilidad de producción y soporte técnico",
      nl:
        "In 1979 legden twee vrienden dankzij de passie voor elektronica en innovatie in Bologna de basis voor wat een geweldig bedrijf zou worden: Esseti Srl, dat al meer dan 35 jaar printplaten produceert en verwerkt en tegenwoordig kwaliteit, beschikbaarheid kan garanderen , concurrentievermogen, productieflexibiliteit en technische ondersteuning",
    },
    lienInfo: "",
    lienSite: "http://www.circuiti-stampati.com/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "92",
    name: "DVC sa",
    logo: "../images/img/logo/logo-dvc.jpeg",
    type: ["pcb"],
    langue: ["EN", "FR", "NL"],
    nombreCouche: 30,
    quantité: 50,
    delais: 14,
    finitions: [],
    materiaux: ["CEM-1", "CEM-3", "FR-2", "FR-4", "PTFE"],
    rigidité: ["Rigide"],
    via: ["Buried"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "DVC s’appuie sur son équipe pluri-disciplinaire et met à votre disposition un ensemble de compétences qui permet de répondre de manière personnalisée à vos besoins. Ces compétences s’enrichissent sans cesse pour suivre l’évolution du marché et des technologies afin de s’appuyer sur les solutions les plus appropriées pour développer votre applicatif.",
      en:
        "DVC relies on its multidisciplinary team and puts at your disposal a set of skills which allow it to respond in a personalized manner to your needs. These skills are constantly enriched to follow the evolution of the market and technologies in order to rely on the most appropriate solutions to develop your application.",
      it:
        "DVC si affida al suo team multidisciplinare e mette a vostra disposizione una serie di competenze che gli consentono di rispondere in modo personalizzato alle vostre esigenze. Queste competenze sono costantemente arricchite per seguire l'evoluzione del mercato e delle tecnologie al fine di fare affidamento sulle soluzioni più appropriate per sviluppare la tua applicazione.",
      de:
        "DVC verlässt sich auf sein multidisziplinäres Team und stellt Ihnen eine Reihe von Fähigkeiten zur Verfügung, mit denen es auf Ihre Bedürfnisse individuell reagieren kann. Diese Fähigkeiten werden ständig erweitert, um die Entwicklung des Marktes und der Technologien zu verfolgen und sich auf die am besten geeigneten Lösungen für die Entwicklung Ihrer Anwendung zu verlassen.",
      es:
        "DVC confía en su equipo multidisciplinario y pone a su disposición un conjunto de habilidades que le permiten responder de manera personalizada a sus necesidades. Estas habilidades se enriquecen constantemente para seguir la evolución del mercado y las tecnologías con el fin de confiar en las soluciones más adecuadas para desarrollar su aplicación.",
      nl:
        "DVC vertrouwt op zijn multidisciplinair team en stelt u een reeks vaardigheden ter beschikking waarmee het op een persoonlijke manier op uw behoeften kan reageren. Deze vaardigheden worden voortdurend verrijkt om de evolutie van de markt en technologieën te volgen om te vertrouwen op de meest geschikte oplossingen om uw applicatie te ontwikkelen.",
    },
    lienInfo: "",
    lienSite: "http://dvc-co.com/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "93",
    name: "Elprinta NV",
    logo: "../images/img/logo/logo-elprinta.jpeg",
    type: ["pcb"],
    langue: ["EN", "FR", "DE", "NL"],
    nombreCouche: 22,
    quantité: 50,
    delais: 14,
    finitions: [
      "HAL standard",
      "Gold electro",
      "Chemical silver",
      "ENEPIG",
      "HAL-SN100C",
      "OSP",
    ],
    materiaux: ["CEM-1", "CEM-3", "FR-4", "Teflon"],
    rigidité: ["Rigide"],
    via: ["Buried", "laser"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "C’est depuis 1980 et en tant qu’entreprise dynamique qu’ Elprinta est un leader en ce qui concerne la production de circuits imprimés. Grâce à notre qualité, notre fiabilité de livraison et nos conseils techniques, nous avons gagné la confiance de clients issus de divers secteurs, dans le monde entier.",
      en:
        "As a dynamic company Elprinta has been a leader in the production of pcbs since 1980. Thanks to the quality, the reliability and support we are trusted by customers worldwide in a variety of sectors.",
      it:
        "In quanto azienda dinamica, Elprinta è leader nella produzione di pcb dal 1980. Grazie alla qualità, all'affidabilità e al supporto, ci affidiamo ai clienti di tutto il mondo in diversi settori.",
      de:
        "Als dynamisches Unternehmen ist Elprinta seit 1980 führend in der Herstellung von hochwertigen Leiterplatten. Durch Qualität, Zuverlässigkeit und Unterstützung genießen wir das Vertrauen eines weltweites Kundenkreises in einer Vielzahl von Branchen.",
      es:
        "Como empresa dinámica, Elprinta ha sido líder en la producción de pcbs desde 1980. Gracias a la calidad, la fiabilidad y el soporte en que confiamos los clientes de todo el mundo en una variedad de sectores.",
      nl:
        "Als dynamische onderneming is Elprinta reeds sinds 1980 toonaangevend in de productie van printplaten. Dankzij kwaliteit, leverbetrouwbaarheid en support genieten wij het vertrouwen van een wereldwijde klantenkring in de meest diverse sectoren.",
    },
    lienInfo: "",
    lienSite: "https://www.elprinta.com/?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },
  {
    id: "94",
    name: "Eurotronics",
    logo: "../images/img/logo/logo-eurotronic.jpeg",
    type: ["pcb"],
    langue: ["EN"],
    nombreCouche: 30,
    quantité: 50,
    delais: 14,
    finitions: [
      "HAL standard",
      "Chemical gold",
      "Gold electro",
      "Chemical silver",
      "Chemical tin",
      "ENEPIG",
      "HAL-SN100C",
      "OSP",
    ],
    materiaux: ["FR-4"],
    rigidité: ["Rigide", "Semi flexible", "flexible"],
    via: ["Buried", "laser"],
    couleursVernis: ["Green", "Black"],
    description: {
      fr:
        "Outre la technologie standard hautement fiable et les cartes multicouches, Eurotronics propose une large gamme de techniques de cartes de circuits imprimés hautement intégrées (HDI) avec des via aveugles et enterrés. Nos capacités de fabrication incluent des micro via remplis de cuivre ou de résine ainsi que des finitions de surface qui conviennent pour le collage de fils.",
      en:
        "Besides highly reliable standard technology and multilayer boards, Eurotronics offers a broad range of highly integrated (HDI) Printed Circuit Board techniques with blind and buried via's. Our manufacturing capabilities include copper or resin filled micro via's as well as surface finishes that are suitable for wire bonding.",
      it:
        "Oltre alla tecnologia standard altamente affidabile e alle schede multistrato, Eurotronics offre una vasta gamma di tecniche di circuiti stampati altamente integrate (HDI) con via cieca e interrata. Le nostre capacità produttive comprendono micro via riempite con rame o resina, nonché finiture superficiali adatte per l'incollaggio di fili.",
      de:
        "Neben hochzuverlässiger Standardtechnologie und Mehrschichtplatinen bietet Eurotronics eine breite Palette hochintegrierter (HDI) Leiterplattentechniken mit Blind- und Erdkontakten. Unsere Fertigungskapazitäten umfassen mit Kupfer oder Harz gefüllte Mikro-Via sowie Oberflächen, die für das Drahtbonden geeignet sind.",
      es:
        "Además de la tecnología estándar altamente confiable y las placas multicapa, Eurotronics ofrece una amplia gama de técnicas de placas de circuito impreso altamente integradas (HDI) con vías ciegas y enterradas. Nuestras capacidades de fabricación incluyen microvías rellenas de cobre o resina, así como acabados superficiales que son adecuados para la unión de cables.",
      nl:
        "Naast zeer betrouwbare standaardtechnologie en meerlagige kaarten, biedt Eurotronics een breed scala aan sterk geïntegreerde (HDI) printplaattechnieken met blinde en begraven via's. Onze productiemogelijkheden omvatten koper- of harsgevulde micro-via's en oppervlakteafwerkingen die geschikt zijn voor draadverlijming.",
    },
    lienInfo: "",
    lienSite: "http://www.eurotronics.be/index.html?utm=pcb-market",
    imageProjet: "",
    titreProjet: "Il n'y a pas de projet pour le moment",
    descriptionProjet: "",
    prixProjet: "",
  },

  //#################################################################################################""

  // {
  //   id: "",
  //   name: "",
  //   logo: "../images/img/logo/",
  //   type: ["pcb", "pcba"],
  //   langue: ["EN"],
  //   nombreCouche: 16,
  //   quantité: 50,
  //   delais: 14,
  //   materiaux: [],
  //   rigidité: ["Rigide", "Semi flexible", "flexible"],
  //   via: ["Buried", "laser"],
  //   couleursVernis: ["Green", "Black"],
  //   description: {
  //     fr: "",
  //     en: "",
  //     it: "",
  //     de: "",
  //     es: "",
  //     nl: "",
  //   },
  //   lienInfo: "",
  //   lienSite: "?utm=pcb-market",
  //   imageProjet: "",
  //   titreProjet: "Il n'y a pas de projet pour le moment",
  //   descriptionProjet: "",
  //   prixProjet: "",
  // },
];

//#################################################################
//       ||           Paterne vide          ||
//       ||      à copier coller en cas     ||
//       \/      de nouveau fournisseur     \/
//#################################################################

// {
//     id:"",
//     name:'',
//     logo:'../images/img/logo/',
//     type:[],
//     langue:[],
//     nombreCouche:'',
//     materiaux:[],
//     rigidité:[],
//     via:[],
//     couleursVernis:[],
//     quantité:,
//     delais:,
//     description:{
//      fr:"",
//      en:"",
//      it:"",
//      de:"",
//      es:"",
//      nl:"",
//     },
//     lienInfo:'',
//     lienSite:'',
//     projet:false,
//     imageProjet:'',
//     titreProjet:'',
//     descriptionProjet:'',
//     prixProjet:'',
// },

//#################################################################
//       ||                 Paterne pleins                ||
//       ||        Avec toute les valeurs possible        ||
//       ||      sauf pour les propriétés qui varient     ||
//       \/    (nom de l'entreprise, description, ect)    \/
//#################################################################

// {
//     id:"",
//     name:'',
//     logo:'../images/img/logo/',
//     type:['pcb', 'pcba'],
//     langue:['FR','EN','ES','IT','PT','NL','DE', 'ZA', 'CZ'],
//     nombreCouche:'',
//     materiaux:["Rogers",'CEM1','CEM2','CEM3','Duroid',"FR-1","FR-2","FR-3","FR-4","FR-5","Kapton","Polyester","Polyethylene","Polyflon","Polyimide","PTFE","Pyralux","Teflon","XPC"],

// finitions: [
//   "HAL standard",
//   "HAL lead free",
//   "Chemical gold",
//   "Gold electro",
//   "Chemical silver",
//   "Chemical tin",
//   "ENEPIG",
//   "HAL-SN100C",
//   "Without finish",
//   "Chemical nickel",
//   "OSP",
// ],
//     rigidité:['Rigide','Semi flexible','Flexible'],
//     via:['Laser','Buried'],
//     couleursVernis:['Black','Orange','Green','Blue','Red','White','Purple','Yellow','Matt green','Matt black','Transparent','Without varnish'],
//     quantité:,
//     delais:,
//     description:{
//      fr:"",
//      en:"",
//      it:"",
//      de:"",
//      es:"",
//      nl:"",
//     },
//     lienInfo:'',
//     lienSite:'',
//     projet:false,
//     imageProjet:'',
//     titreProjet:'',
//     descriptionProjet:'',
//     prixProjet:'',
// },

export { entreprises };
