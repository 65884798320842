import React from "react";
import { withRouter } from "react-router-dom";
import intlActions from "Intl/actions";
import { injectIntl } from "react-intl";

import { NavDropdown } from "Elements";

import languages from "languages";

class LanguageSelector extends React.Component {
  render() {
    const { props } = this;
    const pathname = props.location.pathname.split("/").slice(2).join("/");
    return (
      <NavDropdown
        className="lang"
        title={props.intl.locale}
        items={languages.map((language) => {
          return {
            title: language,
            onClick: (e) => {
              e.preventDefault();
              intlActions.setLanguage(language);
              // console.log("language: ", language)
            },
            href: "/" + language + "/" + pathname + props.location.search,
            lang: language,
          };
        })}
      />
    );
  }
}

export default injectIntl(withRouter(LanguageSelector));
