import React from "react";
// import NavLink from './NavLink'
import { Link, NavLink } from ".";

class NavDropdown extends React.Component {
  state = {};
  toggle() {
    this.setState({
      open: !this.state.open,
    });
  }
  render() {
    const { props, state } = this;
    const baseClassName = props.className || "nav-item";
    // console.log('navdropdown render', props)
    return (
      <li
        className={baseClassName}
        // onBlur={e => state.open ? this.toggle() : false}
      >
        <Link
          to="/"
          className={"dropdown-toggle " + (props.className || "")}
          onClick={(e) => {
            e.preventDefault();
            this.toggle();
          }}
        >
          {props.title}
        </Link>
        <div
          className={"dropdown-menu" + (state.open ? " show" : "")}
          style={{ top: "initial", left: "initial" }}
        >
          {(props.items || []).map((item, i) => (
            <NavLink
              exact={item.exact}
              activeClassName={props.activeClassName}
              lang={item.lang}
              className="dropdown-item"
              key={i}
              to={item.href}
              onClick={(e) => {
                this.toggle();
                if (item.onClick) {
                  item.onClick(e);
                }
              }}
            >
              {item.title}
            </NavLink>
          ))}
        </div>
      </li>
    );
  }
}

export default NavDropdown;
