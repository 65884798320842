import { createStore, combineReducers, compose } from "redux";

import requests from "./Requests/reducer";
import intl from "Intl/reducer";


export var store = { dispatch: e => e };

const initStore = (data = {}) => {
  // if (store.dispatch) {
  //   return store
  // }
  const composeEnhancers =
    (typeof window !== "undefined" &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;
  store = createStore(
    combineReducers({
      
      requests,
      intl,
      
    }),
    data,
    composeEnhancers()
  );

  if (typeof window !== "undefined") {
    window.store = store;
  }

  return store;
};

export default initStore;
